import { AES, enc } from "crypto-js";
import api from "../Service/Api";
import backend from "../Service/Backend";
import { FeatureFlags } from "../types";
import { FEATURE_NAME } from "./constants";
import { get } from "./helpers";
import { fetchAllShows, showActiveUsers } from "../Service/show";

// To-do: Not ideal to keep the key in frontend. Revisit. Can we store in server and make a call everytime ?
const { REACT_APP_FF_KEY } = process.env;

export const getAllFeatureFlags = async (token: string) => {
  try {
    const data = {
      api: api.featureFlags.listAllFlags,
    };
    const flags = await backend.fetch(data, token);

    if (flags) {
      let feature_flags = flags;
      //console.log(feature_flags)

      if (REACT_APP_FF_KEY) {
        const ffc = AES.encrypt(
          JSON.stringify(feature_flags),
          REACT_APP_FF_KEY
        ).toString();
        sessionStorage.setItem("ff", ffc);
        //console.log(isFeatureIncluded(FEATURE_NAME.TIME_CODE, 'hGXeZox7Fo7bXXLuQpVf'))
      }
    }
  } catch (err: any) {
    console.log("Couldn't get feature flag details");
  }
};

// Get App Flags
export const getAppFeatureFlags = async ({
  show_id,
  flag_name,
}: FeatureFlags, token: string) => {
  try {
    if (show_id) {
      const flagData = {
        api: api.featureFlags.listShowFlagsName,
        queryParam: {
          show_id: show_id,
          flag_name: flag_name,
        },
      };
      const flag: any = await backend.fetch(flagData, token);
      // if the ALLOW_NEW_SHOW_VIEWER flag key is not mapped with the show it will be TRUE value
      return get(
        flag,
        "0.feature_enable",
        flag_name === "ALLOW_NEW_SHOW_VIEWER" ? true : false
      );
    }
    return false;
  } catch (err: any) {
    return false;
  }
};

export const isFeatureIncluded = (
  feature_name: FEATURE_NAME,
  show_id: string
): boolean => {
  if (!feature_name || !show_id) {
    return false;
  }

  fetchAllShows();
  showActiveUsers(show_id);
  const ffc = sessionStorage.getItem("ff");
  if (!ffc) {
    return false;
  }

  if (!REACT_APP_FF_KEY) {
    return false;
  }

  const bytes = AES.decrypt(ffc, REACT_APP_FF_KEY);
  var feature_flags = JSON.parse(bytes.toString(enc.Utf8));

  // Show Specific
  const show_flags = feature_flags[show_id];

  if (show_flags) {
    //feature as key present in feature_flags
    if (feature_name in show_flags) {
      return show_flags[feature_name];
    }
  }

  // Application specific
  const app_flags = feature_flags[-1];

  if (app_flags) {
    if (feature_name in app_flags) {
      return app_flags[feature_name];
    }
  }

  return false;
};
export const isTrustedDeviceFeatureEnabled = async (token:string) => {
  const featureData = {
  api: api.featureFlags.listFeatureFlags,
 };
 const flags = await backend.fetch(featureData, token);
 return get(get(flags,'TRUSTED_DEVICE',''),'feature_enable',false);;
};

