import React, { useEffect, useState, useRef } from 'react';
import './styles.scss';
import { showActiveUsers } from '../../../../Service/show';
import { useLocationStore } from '../../../../store/useLocationStore';
import { get } from '../../Helpers/utils';
import { Select } from 'antd';
import { LoadingOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { TIME_ZONES } from './constants';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

interface IScheduleMeeting {
  createMeetings: any;
  onClose: () => void;
  isEditFlow: boolean;
  editMeetingData?: any;
  updateMeeting: any;
  setModalData: any;
}

const ScheduleMeeting = ({
  createMeetings,
  onClose,
  isEditFlow,
  editMeetingData,
  updateMeeting,
  setModalData
}: IScheduleMeeting) => {
  const [users, setUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [errors, setErrors] = useState<{
    title?: string;
    start_date?: string;
    start_time?: string;
    end_date?: string;
    end_time?: string;
    attendees?: string;
    password?: string | null;
  }>({});

  const titleRef = useRef<HTMLInputElement>(null);
  const startDateRef = useRef<HTMLInputElement>(null);
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const attendeesRef = useRef<any>(null);
  const timezoneRef = useRef<HTMLSelectElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const loaderIcon = <LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />;
  const locationState = useLocationStore((state) => state.locationState);
  const user_role_id = get(locationState, 'state.user_role_id', '');
  const show_id = get(locationState, 'state.show_id', '');
  const pmi = sessionStorage.getItem('meetingLink')?.split('id=')[1]?.split('&')[0];
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false);
  const [isEndTimeOpen, setIsEndTimeOpen] = useState(false);

  const getDefaultTimeZone = () => {
    return TIME_ZONES.find(tz => tz.key === 'EST');
  }

  const getTimeZone = (label:any) => {
    return TIME_ZONES.find(tz => tz.label === label)?.value || '';
  }

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    timezone: getDefaultTimeZone()?.label,
    isRecurring: false,
    use_pmi: false,
    security: {
      password: '',
      waitingRoomEnabled: true,
      passwordEnabled: false,
    },
    video_config: {
      hostVideo: true,
      participant_video: true,
    },
    audio_config: {
      type: 'Computer Audio',
    },
    attendees: [],
    host_show_user_role_id: user_role_id,
    show_id: show_id,
    recurrence: {
      type: 'daily', // default type
      interval: 1,   // default interval
      days: [] as any[]       // default days
    },
  });

  const [isFormChanged, setIsFormChanged] = useState(false);
  const handleInputChange = (field: string, value: any) => {
    setFormData(prevState => ({ ...prevState, [field]: value }));
    !isFormChanged && setIsFormChanged(true);
  };
  useEffect(()=> {
    if(editMeetingData && isEditFlow && users.length){
      const { data } = editMeetingData;
      setPasswordEnabled(data.security.passwordEnabled)

      if(data) {
        const recurrenceDaysLabels = (data?.recurrence?.recurrence_days || [])
        .map((day: string) => {
          const dayNumber = parseInt(day, 10);
          return daysOfWeek.find((d) => d.value === dayNumber)?.label;
        })
        .filter(Boolean);
            const newAttendees = data.participants
                .filter((participant: any) => !!user_role_id && participant.participant_show_user_role_id !== user_role_id)
                .map((participant: any) => {
                    const selected_user = users.find((user: any) => user.objectID === participant.participant_show_user_role_id);
                    return selected_user ? {
                        id: selected_user.user_id,
                        email: selected_user.user_email,
                        type: 'user',
                        participant_show_user_role_id: selected_user.objectID,
                        name: `${get(selected_user, 'user_name.user_name_first', '')} ${get(selected_user, 'user_name.user_name_last', '')}`,
                        role:'attendee',
                    } : null;
                }).filter(Boolean);

            setSelectedAttendees(newAttendees.map((attendee: any) => attendee.id));
            const timeZone:string = getTimeZone(get(data,'timezone',''))
        setFormData({
          title: data?.title,
          description: data?.description,
          start_date: moment(data?.start_time).format("YYYY-MM-DD"),
          start_time:moment(convertTimestampToAllTimeZones(data?.start_time,timeZone)).format('HH:mm'),
          end_date: moment(data?.end_time).format("YYYY-MM-DD"),
          end_time:  moment(convertTimestampToAllTimeZones(data?.end_time,timeZone)).format('HH:mm'),
          timezone: data?.timezone,
          isRecurring: data?.isRecurring,
          use_pmi: data?.use_pmi,
          security: {
            password: data?.security?.password,
            waitingRoomEnabled: data?.security?.waitingRoomEnabled,
            passwordEnabled: data?.security?.passwordEnabled,
          },
          video_config: {
            hostVideo: data?.video_config?.hostVideo,
            participant_video: data?.video_config?.participant_video,
          },
          audio_config: {
            type: data?.audio_config.audio_type,
          },
          attendees: newAttendees,
          host_show_user_role_id: user_role_id,
          show_id: show_id,
          recurrence: {
            type: data?.recurrence?.recurrence_type || 'daily',
            interval: data?.recurrence?.recurrence_interval || 1,
            days: recurrenceDaysLabels,
          },
        })
      }
    }
  }, [isEditFlow, editMeetingData, users]);

  const [selectedAttendees, setSelectedAttendees] = useState([] as string[]);
  const [passwordEnabled, setPasswordEnabled] = useState(formData.security.passwordEnabled);

  const recurrenceOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
  ];

  useEffect(() => {
    const fetchActiveUsers = async () => {
      const activeUsers = await showActiveUsers(show_id);
      if (activeUsers?.hits) {
        const requiredUsers = activeUsers.hits.filter((user:any)=> user.role_type === 'ShowViewer');
        setUsers(requiredUsers);
      }
    };

    fetchActiveUsers();

    return () => {
      resetForm()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateRecurrence = () => {
    if(formData.recurrence.type === "monthly" || formData.recurrence.type === "weekly"){
      if(formData.recurrence.days.length === 0){
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const validateForm = () => {
    const newErrors: any = {};
    let firstErrorField: HTMLInputElement | HTMLSelectElement | null = null;
    
    if (!formData.title.trim()) {
      newErrors.title = 'Title is required';
      firstErrorField = titleRef.current;
    }

    if (!formData.start_date) {
      newErrors.start_date = 'Start date is required';
      firstErrorField = startDateRef.current;
    }

    if (!formData.start_time) {
      newErrors.start_time = 'Start time is required';
      firstErrorField = startTimeRef.current;
    }

    if (!formData.end_date) {
      newErrors.end_date = 'End date is required';
      firstErrorField = endDateRef.current;
    }

    if (!formData.end_time) {
      newErrors.end_time = 'End time is required';
      firstErrorField = endTimeRef.current;
    }

    if (formData.start_date && formData.end_date && formData.start_time && formData.end_time) {
      const startDateTime = new Date(`${formData.start_date}T${formData.start_time}`);
      const endDateTime = new Date(`${formData.end_date}T${formData.end_time}`);
      
      if (endDateTime <= startDateTime) {
        newErrors.end_date = 'End date/time must be after start date/time';
        firstErrorField = endTimeRef.current;
      }

      const currentTime = new Date();
      if (!formData.isRecurring && endDateTime < currentTime) {
        newErrors.end_time = 'End time cannot be in the past';
        if (!firstErrorField) firstErrorField = endTimeRef.current;
      }
    }

    if ((!formData.attendees || formData.attendees.length === 0)) {

      newErrors.attendees = 'Please select at least one attendee';
      firstErrorField = attendeesRef.current;
    }

    if (passwordEnabled && !formData.security.password.trim()) {
      newErrors.password = 'Password is required';
      if (!firstErrorField) firstErrorField = passwordRef.current;
    }

    setErrors(newErrors);
    // Move focus to the first error field if there are errors (main conditon)

    if (Object.keys(newErrors).length > 0 && firstErrorField) {
      firstErrorField.focus();
  }
    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
      timezone: getDefaultTimeZone()?.label,
      isRecurring: false,
      use_pmi: false,
      security: {
        password: '',
        waitingRoomEnabled: true,
        passwordEnabled: true,
      },
      video_config: {
        hostVideo: true,
        participant_video: true,
      },
      audio_config: {
        type: 'Computer Audio',
      },
      attendees: [],
      host_show_user_role_id: user_role_id,
      show_id: show_id,
      recurrence: {
        type: 'daily', // default type
        interval: 1,   // default interval
        days: [] as any[]       // default days
      },
    });
    setPasswordEnabled(true);
    setSelectedAttendees([]);
    setErrors({});
  };

  const convertTimestampToAllTimeZones = (timestamp: string,timeZone:string) => {
    const date = momentTimezone.tz(timestamp, timeZone);

    return date;
  };

  const submitForm = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validateForm() || !validateRecurrence()) {
      return;
    }

    setIsLoading(true);
    try {
      const combinedStartDateTimeString = convertTimestampToAllTimeZones(
        `${formData.start_date}T${formData.start_time}:00`,getTimeZone(get(formData,'timezone',''))
      );
      const combinedEndDateTimeString = convertTimestampToAllTimeZones(
        `${formData.end_date}T${formData.end_time}:00`,getTimeZone(get(formData,'timezone',''))
      );

      const payload: any = {
        ...formData,
        start_time: combinedStartDateTimeString,
        end_time: combinedEndDateTimeString,
        security: {
          ...formData.security,
          password: passwordEnabled ? formData.security.password : "",
          passwordEnabled: passwordEnabled,
        },
      };
      delete payload.start_date;
      delete payload.end_date;

      if(!payload.isRecurring){
        delete payload.recurrence
      }

      // let editFlowPayload = {}

      // if(isEditFlow){
      //   editFlowPayload = {
      //     value: payload,
      //     meetingId: editMeetingData.data.dynamic_meeting_id,
      //     show_id: show_id,
      //     session_id: editMeetingData.data.session_id
      //   }
      // }

      if (isEditFlow) {
        const response= await updateMeeting(payload, editMeetingData);
        resetForm();
        setIsLoading(false);
        console.log(":::response:::",response)
        setMeetingTitle(response.data.title)
        setMeetingLink(response.data.meeting_link)
        setModalMessage("Meeting Updated Successfully!");

      } else {
        const response = await createMeetings(payload)
        setMeetingTitle(response.title)
        setMeetingLink(response.meeting_link)
        setModalMessage("Meeting Scheduled Successfully!");
      }
      setModalVisible(true);
      console.log("Modal visibility set to:", true);
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModalMessage("");
    setModalVisible(false);
    onClose();
  };

  const handleTimeChange = (field: 'start_time' | 'end_time', value: string) => {
    if(field === 'end_time'){
      const startDateTime = new Date(`${formData.start_date}T${formData.start_time}:00`);
      const endDateTime = new Date(`${formData.end_date}T${value}:00`);
     
      const currentTime = new Date();
      if (!formData.isRecurring && endDateTime < currentTime) {
        setErrors({ ...errors, [field]: 'End time cannot be in the past' });
        return;
      }

      if(startDateTime > endDateTime){
        setErrors({ ...errors, [field]: 'Start time cannot be greater than end time' });
        return
      }
    }
    handleInputChange(field, value);
  setErrors({ ...errors, [field]: '' });
  };

  const handleAttendeesChange = (selectedIds: string[]) => {

    const newAttendees:any = selectedIds.map((id) => {
      

      const selected_user = users.find((user: any) => user.user_id === id);
      
      if (selected_user) {
        return {
          id,
          email: selected_user.user_email,
          type: 'user',
          participant_show_user_role_id: selected_user.objectID,
          name: `${get(selected_user,'user_name.user_name_first','')} ${get(selected_user,'user_name.user_name_last','')}`,
          role: 'attendee',
        };
      }
      return null;
    }).filter((attendee) => !!attendee);
    setFormData(prevState => ({
      ...prevState,
      attendees: newAttendees
    }));
    !isFormChanged && setIsFormChanged(true);
    setSelectedAttendees(selectedIds);
    if (errors.attendees) {
      setErrors(prevErrors => ({ ...prevErrors, attendees: undefined }));
    }
  };
  
  const uniqueUsers = Array.from(
    new Set(users.map((user) => user.user_id))
  ).map((user_id) => users.find((user) => get(user,'user_id','') === user_id && get(user,'role_type','') === "ShowViewer")).filter(Boolean)
    .filter((user) => get(user, 'objectID', '') !== user_role_id);
  const selectOptions = uniqueUsers.map((user) => ({
    label: `${get(user,'user_name.user_name_first','')} ${get(user,'user_name.user_name_last','')} (${get(user,'user_email','')})`,
    value: get(user,'user_id',''),
  }));

  const daysOfWeek = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
  ];

  const handleClosePopup = () => {
    resetForm();
    onClose();
  };
  
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
};

const currentTime = new Date().toLocaleString('en-US', options);
  
  const minStartDate = new Date().toISOString().split("T")[0];
  const minEndDate = formData.start_date ? new Date(formData.start_date).toISOString().split("T")[0] : new Date().toISOString().split("T")[0];

  const generateTimeSlots = (interval = 15) => {
    const timeSlots = Array.from({ length: 24 * (60 / interval) }, (_, index) => {
      const hour = Math.floor(index * interval / 60);
      const minute = (index * interval) % 60;
      const time = new Date(0, 0, 0, hour, minute);
      return {
        value: time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase(),
        label: time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase(),
        key: index,
      };
    });

    return timeSlots;
  };

  const timeSlots = generateTimeSlots(15);

  const get24HoursFormat = (val: string) => {
    const timeArr = val.split(' ')
    const time = timeArr[0]
    const meridiem = timeArr[1]

    let [hours, minutes]: any = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (meridiem === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;

  }

  const get12HoursFormat = (val: string): string => {
    if(!val) return "";
    
    const [hourStr, minuteStr] = val.split(':');
  
    // Parse the hour part as a number
    const hours = parseInt(hourStr, 10);
    const minutes = minuteStr; // Minutes remain a string
  
    // Determine AM/PM
    const meridiem = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    const adjustedHours = hours % 12 || 12; // Convert 0 or 24 to 12
  
    // Return the formatted string
    const result = `${adjustedHours.toString().padStart(2, '0')}:${minutes} ${meridiem}`;
    return result;
  };

  const getEndTimeSlots = () => {
    const startDateTime = new Date(`${formData.start_date}T${formData.start_time}:00`);
    const availableSlots = timeSlots.filter((timeSlot: any) => {
      const endDateTime = new Date(`${formData.end_date}T${get24HoursFormat(timeSlot.value)}:00`);
      return startDateTime < endDateTime;
    });

    return availableSlots;
  }

  useEffect(() => {
    setModalData({
      open: modalVisible,
      data: modalMessage,
      // onOk: closeModal,
      meetingLink: meetingLink,
      meetingTitle: meetingTitle,
      createdTime: isEditFlow? currentTime: ""
    })
  }, [modalVisible, modalMessage, meetingLink, meetingTitle, isEditFlow, currentTime])
  
  return (
    <div className='schedule-meeting'>
      <h2>{isEditFlow? `Update Meeting`: `Schedule Meeting`}</h2>
      <form onSubmit={submitForm}>
        <label className='text-white' htmlFor='title'>
          Title
        </label>
        <input
          type='text'
          id='title'
          value={formData.title}
          ref={titleRef}
          onChange={(e) => {
            handleInputChange('title', e.target.value);
            if (errors.title) setErrors({ ...errors, title: undefined });
          }}
          className={errors.title ? 'error' : ''}
        />
        <span className="error-message" style={{visibility:errors.title  ? 'visible' : 'hidden'}}>{errors.title}</span>

        <div className='date-time'>
          <div className='wrapper'>
            <label className='text-white' htmlFor='start'>
              Start
            </label>
            <div className="fields-wrapper">
              <div className='fields'>
                {/* <input
                  max="9999-12-31"
                  min={minStartDate}
                  type='date'
                  id='start'
                  value={formData.start_date}
                  ref={startDateRef}
                  onChange={(e) => {
                    setFormData({ ...formData, start_date: e.target.value });
                    if (errors.start_date) setErrors({ ...errors, start_date: undefined });
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevents manual typing
                  className={errors.start_date ? 'error' : ''}
                /> */}
                <DatePicker
                  format={'DD/MM/YYYY'}
                  className="date-select"
                  suffixIcon={isStartDateOpen ? <UpOutlined className="date-arrow" /> : <DownOutlined className="date-arrow" />}
                  placeholder="DD/MM/YYYY"
                  allowClear={false}
                  value={formData.start_date ? dayjs(formData.start_date) : null}
                  inputReadOnly
                  disabledDate={(current) => {
                    return current && current.isBefore(dayjs(), 'day');
                  }}
                  onChange={(val:any) => {
                    if (val) {
                      const formattedDate = dayjs(val).format('YYYY-MM-DD');
                      handleInputChange('start_date', formattedDate);
                      if (errors.start_date) setErrors({ ...errors, start_date: undefined });
                    }
                  }}
                  onOpenChange={(open) => setIsStartDateOpen(open)}
                />
                 <span className="error-message" style={{visibility:errors.start_date  ? 'visible' : 'hidden'}}>{errors.start_date}</span>
                </div>
                <div className='fields'>
                <Select
                  className='time-select'
                  size='small'
                  value={get12HoursFormat(formData.start_time)}
                  // options={timeSlots}
                  onChange={(value) => {
                    const convertedTime = get24HoursFormat(value)
                    handleTimeChange('start_time', convertedTime)
                  }}
                  dropdownStyle={{backgroundColor: '#000000'}}
                  open={isStartTimeOpen} 
                  onDropdownVisibleChange={(open) => setIsStartTimeOpen(open)}
                  suffixIcon={
                    <span onClick={(e) => {
                      e.stopPropagation();
                      setIsStartTimeOpen(!isStartTimeOpen);
                    }}>
                      {isStartTimeOpen ? <UpOutlined className="time-arrow" /> : <DownOutlined className="time-arrow" />}
                    </span>
                  }
                >
                  {timeSlots.map(time => <Select.Option style={{color: '#FFFFFF'}} value={time.value} key={`${time.key}_${time.value}`}>{time.label}</Select.Option>)}
                </Select>
                  <span className="error-message" style={{visibility:errors.start_time  ? 'visible' : 'hidden'}}>{errors.start_time}</span>
                </div>
              </div>
          </div>
          <div className='wrapper'>
            <label className='text-white' htmlFor='end'>
              End
            </label>
            <div className="fields-wrapper">
              <div className='fields'>
                {/* <input
                  max="9999-12-31"
                  type='date'
                  id='end'
                  min={minEndDate}
                  value={formData.end_date}
                  ref={endDateRef}
                  onChange={(e) => {
                    setFormData({ ...formData, end_date: e.target.value });
                    if (errors.end_date) setErrors({ ...errors, end_date: undefined });
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevents manual typing
                  className={errors.start_date ? 'error' : ''}
                /> */}
                <DatePicker
                  format={'DD/MM/YYYY'}
                  className="date-select"
                  suffixIcon={isEndDateOpen ? <UpOutlined className="date-arrow" /> : <DownOutlined className="date-arrow" />}
                  placeholder="DD/MM/YYYY"
                  allowClear={false}
                  inputReadOnly
                  value={formData.end_date ? dayjs(formData.end_date): null}
                  disabledDate={(current) => {
                    return current && (!formData.start_date || current.isBefore(dayjs(formData.start_date), 'day'));
                  }}
                  onChange={(val:any) => {
                    if (val) {
                      const formattedDate = dayjs(val).format('YYYY-MM-DD');
                      const startDate = dayjs(formData.start_date);
                      if (startDate && startDate.isAfter(dayjs(formattedDate))) {
                        setErrors({ ...errors, end_date: 'End date cannot be before start date' });
                      } else {
                        setErrors({ ...errors, end_date: undefined });
                      }
                      handleInputChange('end_date', formattedDate);
                    }
                  }}
                  onOpenChange={(open) => setIsEndDateOpen(open)}
                />
                  <span className="error-message" style={{visibility:errors.end_date  ? 'visible' : 'hidden'}}>{errors.end_date}</span>
              </div>
              <div className='fields'>
                <Select
                  className='time-select'
                  size='small'
                  value={get12HoursFormat(formData.end_time)}
                  onChange={(value) => {
                    const convertedTime = get24HoursFormat(value)
                    handleTimeChange('end_time', convertedTime)  
                  }}
                  dropdownStyle={{backgroundColor: '#000000'}}
                  open={isEndTimeOpen} 
                  suffixIcon={
                    <span onClick={(e) => {
                      e.stopPropagation();
                      setIsEndTimeOpen(!isEndTimeOpen);
                    }}>
                      {isEndTimeOpen ? <UpOutlined className="time-arrow" /> : <DownOutlined className="time-arrow" />}
                    </span>
                  }
                  onDropdownVisibleChange={(open) => setIsEndTimeOpen(open)}
                >
                  {getEndTimeSlots().map(time => <Select.Option style={{color: '#FFFFFF'}} value={time.value} key={`${time.key}_${time.value}`}>{time.label}</Select.Option>)}
                </Select>
              <span className="error-message" style={{visibility:errors.end_time  ? 'visible' : 'hidden'}}>{errors.end_time}</span>
              </div>
            </div>
          </div>
        </div>

        <fieldset className='timezone'>
          <label htmlFor='time-zone'>Time Zone</label>
          <select id='time-zone' value={formData.timezone} onChange={(e) => {
            handleInputChange('timezone', e.target.value)
          }} ref={timezoneRef}>
          {TIME_ZONES.map((tz) => (<option key={`${tz.key}_${tz.value}`} value={tz.label}>{tz.label}</option>))}
          </select>
        </fieldset>

        <fieldset>
          <label>
            <input 
              type='checkbox' 
              checked={formData.isRecurring} 
              onChange={(e) => handleInputChange('isRecurring', e.target.checked)}
            /> Recurring Meeting
          </label>
        </fieldset>

        {formData.isRecurring && (
          <div className='recurring-fields'>
            <label htmlFor='recurrence-type'>Recurrence</label>
            <Select 
              placeholder={'Select'}
              value={formData.recurrence.type} 
              className='recurring-select'
              onChange={(value) => handleInputChange('recurrence', { 
                  ...formData.recurrence, 
                  type: value,
                  interval: value === 'monthly' ? 2 : 1, // Default interval based on type
                  // days: value === 'weekly' ? [0, 6] : [] // Default days for weekly
                } 
              )}
              dropdownStyle={{backgroundColor: "#000000"}}  
            >
              {recurrenceOptions.map(option => (
                <Select.Option key={option.value} className="recurring-select-option">
                  {option.label}
                </Select.Option>
              ))}
            </Select>

            <label>Repeat every</label>
            <div className='repeat-every'>
              <input
                className='interval-input'
                value={formData.recurrence.interval}
                type='number'
                onChange={(e) => handleInputChange('recurrence', {
                    ...formData.recurrence,
                  interval: Math.max(1, Number(e.target.value)) // Ensure minimum value is 1
                })}
                style={{
                  borderRadius: '8px',
                  backgroundColor: '#141414'
                }}
                min={1}
              />
             <span className='zoom-meeting-text-white'>
                {formData.recurrence.type === 'daily' && 
                  (formData.recurrence.interval > 1 ? 'days' : 'day')}
                {formData.recurrence.type === 'weekly' && 
                  (formData.recurrence.interval > 1 ? 'weeks' : 'week')}
                {formData.recurrence.type === 'monthly' && 
                  (formData.recurrence.interval > 1 ? 'months' : 'month')}
              </span>
            </div>
          
            {formData.recurrence.type === 'weekly' && (
              <>
                <label>Days</label>
                <Select
                  mode="multiple"
                  value={formData.recurrence.days}
                  className='recurring-select'
                  onChange={(selectedDays) => handleInputChange('recurrence', { 
                        ...formData.recurrence, 
                        days: selectedDays 
                    })}
                  placeholder="Select days"
                  dropdownStyle={{backgroundColor: "#000000"}}  
                >
                  {daysOfWeek.map(day => (
                    <Select.Option key={day.value} value={day.value} className="recurring-select-multiple-option">
                      {day.label}
                    </Select.Option>
                  ))}
                </Select>
                {formData.recurrence.days.length === 0 && (
                <div className="error-message">Please select any day.</div>
                )}
              </>
            )}

            {formData.recurrence.type === 'monthly' && (
              <>
                <label>Days of the Month</label>
                <Select
                  mode="multiple"
                  value={formData.recurrence.days}
                  className='recurring-select'
                  onChange={(selectedDays) => handleInputChange('recurrence', { 
                        ...formData.recurrence, 
                        days: selectedDays 
                    })
                      } 
                  placeholder="Select days of the month"
                  dropdownStyle={{backgroundColor: "#000000"}}
                >
                  {Array.from({ length: 31 }, (_, i) => (
                    <Select.Option key={i + 1} value={i + 1} className="recurring-select-multiple-option">
                      {i + 1}
                    </Select.Option>
                  ))}
                </Select>
                {formData.recurrence.days.length === 0 && (
                <div className="error-message">Please select at least a month.</div>
                )}
              </>
            )}
        
          </div>
        )}

        <label className='text-white' htmlFor='attendees'>
          Attendees
        </label>
        <div className='attendees-container'>
          <Select
            mode="multiple"
            allowClear
            showSearch
            options={selectOptions}
            ref={attendeesRef}
            value={selectedAttendees}
            // disabled={isEditFlow}
            onChange={handleAttendeesChange}
            rootClassName="ant-select-customize-input"
            className={`schedule-meeting-attendees ${errors.attendees ? 'error' : ''}`}
            placeholder= "Email or name"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            popupClassName='black-theme-dropdown'
            tagRender={(props) => {
              const { label, closable, onClose } = props;
              return (
                <div className='custom-tag'>
                  <span>{label}</span>
                  {closable && (
                    <span onClick={onClose} className="custom-tag-close">✕</span>
                  )}
                </div>
              );
            }}
          />
         <span className="error-message" style={{visibility:errors.attendees  ? 'visible' : 'hidden'}}>{errors.attendees}</span>
        </div>
        {!isEditFlow && <fieldset>
          <legend>Meeting ID</legend>
          <label>
            <input 
              type='radio' 
              name='meeting-id' 
              checked={!formData.use_pmi} 
              onChange={() => setFormData({ ...formData, use_pmi: false })} 
            /> 
            Generate Automatically
          </label>
          <label>
            <input 
              type='radio' 
              name='meeting-id' 
              checked={formData.use_pmi} 
              onChange={() => setFormData({ ...formData, use_pmi: true })} 
            /> 
            Personal Meeting ID {pmi}
          </label>
        </fieldset>}

        <fieldset className="security-fieldset">
  <legend>Security</legend>
  <label>
    <input 
      type="checkbox" 
      checked={passwordEnabled} 
      onChange={(e) => {
        setPasswordEnabled(e.target.checked);
        setFormData((prev) => ({
          ...prev,
          security: {
            ...prev.security,
            password: '', // Reset password when enabling/disabling
          },
        }));
        !isFormChanged && setIsFormChanged(true);
      }} 
    /> Password
    {passwordEnabled && (
      <input
        type="text"
        ref={passwordRef}
        value={formData.security.password}
        onChange={(e) => {
          handleInputChange('security', {
              ...formData.security,
              password: e.target.value,
          });
          // Clear error only if a password is entered
          if (e.target.value) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              password: null,
            }));
          }
        }}
        className={errors.password ? 'error' : ''}
        placeholder="password"
      />
    )}
    {errors.password && <span className="error-message">{errors.password}</span>}
  </label>
          <span className='info-text'>Only users who have the invite link or password can join the meeting</span>
          <label>
            <input
              type='checkbox'
              checked={formData.security.waitingRoomEnabled}
              onChange={() =>
                handleInputChange('security', {
                    ...formData.security,
                    waitingRoomEnabled: !formData.security.waitingRoomEnabled,
                })
              }
            />{' '}
            Waiting Room<br></br>
          </label>
          <span className='info-text'>Only users admitted by the host can join the meeting</span>
        </fieldset>

        <fieldset>
          <legend>Video</legend>
          <label>
            Host:
            <input 
              type='radio' 
              name='host-video' 
              checked={formData.video_config.hostVideo} 
              onChange={() => handleInputChange('video_config', { 
                  ...formData.video_config, 
                  hostVideo: true 
              })} 
            />
            On
            <input 
              type='radio' 
              name='host-video' 
              checked={!formData.video_config.hostVideo} 
              onChange={() => handleInputChange('video_config', { 
                  ...formData.video_config, 
                  hostVideo: false 
              })} 
            />
            Off
          </label>
          <label>
            Participant:
            <input 
              type='radio' 
              name='participant-video' 
              checked={formData.video_config.participant_video} 
              onChange={() => handleInputChange('video_config', { 
                  ...formData.video_config, 
                  participant_video: true 
              })} 
            />
            On
            <input 
              type='radio' 
              name='participant-video' 
              checked={!formData.video_config.participant_video} 
              onChange={() => handleInputChange('video_config', { 
                  ...formData.video_config, 
                  participant_video: false 
              })} 
            />
            Off
          </label>
        </fieldset>

        <fieldset>
          <legend>Audio</legend>
          <label>
            <input type='radio' name='audio' defaultChecked /> Computer Audio
          </label>
          {/* <label>
            <input type='radio' name='audio' /> Telephone
          </label>
          <label>
            <input type='radio' name='audio' /> Telephone and Computer Audio
          </label> */}
        </fieldset>

        {/* <label className='text-white' htmlFor='invite-link'>
          Guest Invite Link
        </label>
        <div className='link-container'>
          <div>
            <a href='http://pacpost.live/rebrandly.com'>http://pacpost.live/rebrandly.com</a>
            <span>Dial In</span>
            <span>+1 386 347 5053</span>
            <span>+1 360 209 5623</span>
            <span>+1 346 248 7799</span>
          </div>

          <svg
            width='38'
            height='38'
            viewBox='0 0 38 38'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2.5 11C2.5 6.58172 6.08172 3 10.5 3H26.5C30.9183 3 34.5 6.58172 34.5 11V27C34.5 31.4183 30.9183 35 26.5 35H10.5C6.08172 35 2.5 31.4183 2.5 27V11Z'
              fill='#6F2DDA'
            />
            <path
              d='M23.75 25.75H15.5V15.25H23.75M23.75 13.75H15.5C15.1022 13.75 14.7206 13.908 14.4393 14.1893C14.158 14.4706 14 14.8522 14 15.25V25.75C14 26.1478 14.158 26.5294 14.4393 26.8107C14.7206 27.092 15.1022 27.25 15.5 27.25H23.75C24.1478 27.25 24.5294 27.092 24.8107 26.8107C25.092 26.5294 25.25 26.1478 25.25 25.75V15.25C25.25 14.8522 25.092 14.4706 24.8107 14.1893C24.5294 13.908 24.1478 13.75 23.75 13.75ZM21.5 10.75H12.5C12.1022 10.75 11.7206 10.908 11.4393 11.1893C11.158 11.4706 11 11.8522 11 12.25V22.75H12.5V12.25H21.5V10.75Z'
              fill='white'
            />
          </svg>
        </div> */}

        <div className='actions'>
          <button type='button' className='cancel' onClick={handleClosePopup}>
            Cancel
          </button>
          <button type='submit' className={`save ${isEditFlow ? 'update' : ''} ${
            isEditFlow && (isLoading || !isFormChanged) ? 'disabled-update' : ''
            }`}
            disabled={isLoading || !isFormChanged}>
            {isLoading ? <div className ="loader"> {loaderIcon} </div> : isEditFlow ? "Update" : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ScheduleMeeting;