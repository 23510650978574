import { useEffect, useState, useReducer, useRef } from 'react';
import { Button, Select } from 'antd';
import ZoomVideo from "@zoom/videosdk";
import SoundLevelBars from '../SoundLevelBars/SoundLevel';
import './styles.css';

const { Option } = Select;

type MicState = 'start' | 'recording' | 'playing' | 'stop';

interface State {
  micState: MicState;
}

// Define the reducer function
const micReducer = (state: State, action: { type: MicState }): State => {
  switch (action.type) {
    case 'recording':
    case 'playing':
    case 'stop':
    case 'start':
      return { ...state, micState: action.type };
    default:
      return state;
  }
};

const ReplyCheck: React.FC<{ onNext: () => void, microPhoneSelect: any }> = ({ onNext, microPhoneSelect }) => {
  const [state, dispatch] = useReducer(micReducer, { micState: 'start' });
  const [microphoneDevices, setMicrophoneDevices] = useState<any[]>([]);
  const [selectedMic, setSelectedMic] = useState<string | undefined>();
  const [audioLevel, setAudioLevel] = useState<number>(0);
  const micTesterRef = useRef<any | null>(null);
  const [isYesEnabled, setYesEnabled] = useState(false);


  let localAudioTrack: any;
  let microphoneTester: ReturnType<typeof localAudioTrack.testMicrophone> | undefined;

  const handleMicrophoneTestClick = (event: any) => {
    const target = event.target as HTMLElement;
    localAudioTrack = ZoomVideo.createLocalAudioTrack(
      selectedMic
    );
    if (target.classList.contains("test-microphone")) {
      const value = target.dataset["start"];
      /**
       * 0 - undefined - init
       * 1 - start
       * 2 - recording
       * 3 - playing recording
       */
      if (state.micState === "playing") {
        if (micTesterRef.current) {
          micTesterRef.current.stop();
          target.dataset["start"] = "0";
          target.textContent = "Test Microphone";
          dispatch({ type: 'stop' }); // Update mic state to stop
        }
      } else if (!value || state.micState === "stop") {
        microphoneTester = localAudioTrack.testMicrophone({
          microphoneId: selectedMic,
          onAnalyseFrequency: (v: number) => {
            // inputLevelElm.value = v.toString();
            setAudioLevel(v)
          },
          recordAndPlay: true,
          onStartRecording: () => {
            target.textContent = "Recording";
            target.dataset["start"] = "2";
            dispatch({ type: 'recording' }); // Update mic state to recording
          },
          onStartPlayRecording: () => {
            target.textContent = "Playing";
            target.dataset["start"] = "3";
            dispatch({ type: 'playing' }); // Update mic state to playing
          },
          onStopPlayRecording: () => {
            target.textContent = "Stop test";
            target.dataset["start"] = "1";
            dispatch({ type: 'playing' });
            setYesEnabled(true);
          },
        });
        micTesterRef.current = microphoneTester
        target.dataset["start"] = "1";
        target.textContent = "Stop test";
      } else if (state.micState === "recording") {
        micTesterRef.current.stopRecording();
      }
    }
  };

  useEffect(() => {
    // Fetch microphone devices on component mount
    ZoomVideo.getDevices().then((devices) => {
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      
      setMicrophoneDevices(audioDevices);
      if (audioDevices.length > 0) {
        setSelectedMic(audioDevices[0].deviceId); // Set default microphone
        
        sessionStorage.setItem('mic',`${audioDevices[0].deviceId}`)
        microPhoneSelect(audioDevices[0].label)
      }
    });

    return () => {
      if (localAudioTrack) {
        localAudioTrack.stop();
      }
    };
  }, []);


  const handleMicChange = (value: any, option: any) => {
    const newMicId = value;
    setSelectedMic(value);
    sessionStorage.setItem('mic',`${value}`)
    microPhoneSelect(option.children)
    if (localAudioTrack) {
      localAudioTrack.stop().then(() => {
        localAudioTrack = ZoomVideo.createLocalAudioTrack(newMicId);
        localAudioTrack.start().then(() => {
          localAudioTrack.unmute();
        });
      });
    }
  };

  const handleNextMic = () => {
    if (microphoneDevices.length > 1) {
      const currentIndex = microphoneDevices.findIndex(
        (device) => device.deviceId === selectedMic
      );
      const nextIndex = (currentIndex + 1) % microphoneDevices.length; // Cycle to the next microphone
      const nextMic = microphoneDevices[nextIndex];
      setSelectedMic(nextMic.deviceId);
      sessionStorage.setItem('mic',`${nextMic.deviceId}`)
      microPhoneSelect(nextMic.label);
      if (localAudioTrack) {
        localAudioTrack.stop().then(() => {
          localAudioTrack = ZoomVideo.createLocalAudioTrack(nextMic.deviceId);
          localAudioTrack.start().then(() => {
            localAudioTrack.unmute();
          });
        });
      }
    }
  };

  const isButtonDisabled = (isYesEnabled: boolean, micState: string): boolean => {
    return !isYesEnabled || micState !== "stop";
  };
  
  return (
    <div className="zoom-meeting microphone-select-modal reply-check-container ">
      <div className='zoom-meeting-microphone-wrapper'>
      <h3 className="head ">Speak and Pause, do you hear a Reply?</h3>
      <br /><br />
      <p className="speaker-heading">Select Microphone</p>
      <Select 
        onChange={handleMicChange}
        className="options"
        value={selectedMic}
        popupClassName='black-theme-dropdown'
      >
        {microphoneDevices.map((device) => (
          <Option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </Option>
        ))}
      </Select>
      <p className="speaker-heading input-level ">Input Level:</p>
      <SoundLevelBars audioLevel={audioLevel} />
      </div>
      <div className="button-group">
      <Button onClick={handleNextMic} className="try-another" disabled={!isYesEnabled}>
            No, Try Another Mic
          </Button>
        <button onClick={handleMicrophoneTestClick} className="try-another test-microphone">
          Test your Microphone
        </button>
        <Button
        onClick={onNext}
        className="yes"
        disabled={isButtonDisabled(isYesEnabled, state.micState)}
        >Yes</Button>
      </div>
    </div>
  )
};

export default ReplyCheck;