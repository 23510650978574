import { useEffect, useState } from 'react';
import { get, isTimestampRecent } from '../Helpers/utils';

const useFilteredParticipants = (participants: any) => {
  const [filteredParticipants, setFilteredParticipants] = useState([]);

  useEffect(() => {
    const filterParticipants = () => {
      const filtered = participants.filter((participant: any) => {
        return (
          !!get(participant, 'updated_at', null) &&
          isTimestampRecent(get(participant, 'updated_at', {}))
        );
      });
      setFilteredParticipants(filtered);
    };

    // Initial filtering
    filterParticipants();

    // Set up interval for re-filtering
    const interval = setInterval(() => {
      filterParticipants();
    }, 10000); // 20 seconds

    // // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [participants]);

  return filteredParticipants;
};

export default useFilteredParticipants;
