import { useContext, useEffect } from 'react';
import { useVideo } from '../../Hooks/useVideo';
import { ReactComponent as VideoOff } from '../images/video-off.svg';
import { ReactComponent as VideoOn } from '../images/video-filled.svg';
import ZoomContext from '../../context/zoom-context';
import { get } from '../../Helpers/utils';
import { useLocationStore } from '../../../../store/useLocationStore';
import { useNavigationType } from 'react-router';

const VideoOption = () => {
  const zoomClient = useContext(ZoomContext);
  const locationState = useLocationStore((state) => state.locationState);
  const { isVideoOn, onCamClick } = useVideo(zoomClient);
  const meeting_data = get(locationState, 'state.meeting_data', '');
  const isVideoON = get(meeting_data, 'video_preference', false);
  const isUserInConference = zoomClient?.getSessionInfo()?.isInMeeting;
  const currentUserInfo = zoomClient?.getCurrentUserInfo();
  const isVideoEnabled = get(currentUserInfo, 'bVideoOn', false);
  const navigationType = useNavigationType();

  useEffect(() => {
    if (isVideoON && isUserInConference && !isVideoEnabled && navigationType !== 'POP') {
      onCamClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserInConference]);
  return (
    <div className='header-buttons videoOff-icons' onClick={onCamClick}>
      {isVideoOn ? <VideoOn /> : <VideoOff />}
    </div>
  );
};
export default VideoOption;
