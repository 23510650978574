import React from 'react';
import { Menu } from 'antd';
import '../styles.scss';

interface LeaveAndEndOptionsProps {
  isHost: boolean;
  onMenuClick: ({ key }: { key: string }) => Promise<void>;
  loading: boolean;
}

const LeaveAndEndOptions: React.FC<LeaveAndEndOptionsProps> = ({ isHost, onMenuClick }) => {
  return (
    <>
      <Menu.Item
        key="leave"
        className="custom-dropdown-leave"
        onClick={() => onMenuClick({ key: 'leave' })}
      >
        Leave meeting
      </Menu.Item>
      {isHost && (
        <Menu.Item
          key="endForEveryone"
          className="custom-dropdown-endForEveryone"
          onClick={() => onMenuClick({ key: 'endForEveryone' })}
        >
          End meeting for everyone
        </Menu.Item>
      )}
    </>
  );
};

export default LeaveAndEndOptions;
