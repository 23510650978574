import { auth } from './firebase';
import { getAuth, updatePassword } from 'firebase/auth';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
  UserCredential,
  IdTokenResult,
} from 'firebase/auth';
import backend from '../Service/Backend';
import api from '../Service/Api';

// Sign Up
export const doCreateUserWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<UserCredential> => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

// Sign In
export const doSignInWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<UserCredential> => {
  return await signInWithEmailAndPassword(auth, email, password);
};


// Clear user claim
export const clear = async () => {
  try {
    const data = {
      api: api.otp.disableOTPAuthStatus,
    };
    const token = await getToken();
    await backend.save(data, token);
    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
};

// Sign out
export const doSignOut = async () => {
  try {
    await clear();
    await auth.signOut();
    return true;
  } catch (error) {
    console.log('error', error);
  }
};

// Password Reset
export const doPasswordReset = (email: string): Promise<void> => {
  return sendPasswordResetEmail(auth, email);
};

// Password Change
export const doPasswordUpdate = async (password: string): Promise<void> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    return updatePassword(user, password);
  }
  return Promise.reject('No auth.currentUser!');
};

// Get Id token
export const getToken = async (): Promise<string> => {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken(true);
  }
  return Promise.reject('No auth.currentUser!');
};

// Get Id token result
export const getTokenResult = async (): Promise<IdTokenResult> => {
  if (auth.currentUser) {
    return auth.currentUser.getIdTokenResult();
  }
  return Promise.reject('No auth.currentUser!');
};

// Get UID
export const getUserId = (): string => {
  return auth.currentUser ? auth.currentUser.uid : '';
};

// Get User Email
export const getUserEmail = (): string => {
  return auth.currentUser && auth.currentUser.email ? auth.currentUser.email : '';
};

// Check if user is logged in
export const getUser = (): any => {
  return auth.currentUser ? auth.currentUser : null;
};

// get User Role
export const getUserRole = async () => {
  try {
    const idTokenResult: IdTokenResult = await getTokenResult();
    if (idTokenResult && idTokenResult.claims) {
      const { role } = idTokenResult.claims;
      if (!role) {
        return false;
      }
      return role;
    }
  } catch (err: any) {
    throw err;
  }
};

// IsloggedIn
export const isAuthenticated = (): boolean => {
  const user: any = getUser();
  if (user) {
    return true;
  }
  return false;
};

export const isInitialised = async () => {
  return new Promise((resolve) => {
    auth.onAuthStateChanged(resolve);
  });
};

// Verify password reset code sent by firebase reset password link
export const verifyPasswordResetCodeHandler = async (actionCode: string): Promise<string> => {
  return verifyPasswordResetCode(auth, actionCode);
};

// Confirm reset password using firebase
export const confirmPasswordResetHandler = async (
  actionCode: string,
  newPassword: string,
): Promise<void> => {
  return confirmPasswordReset(auth, actionCode, newPassword);
};
