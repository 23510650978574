export const TIME_ZONES: { key: string; value: string; label: string }[] = [
  { key: 'PST', value: 'America/Los_Angeles', label: 'Pacific Time (US & Canada) PST/PDT' },
  { key: 'MST', value: 'America/Denver', label: 'Mountain Time (US & Canada) MST/MDT' },
  { key: 'CST', value: 'America/Chicago', label: 'Central Time (US & Canada) CST/CDT' },
  { key: 'EST', value: 'America/New_York', label: 'Eastern Time (US & Canada) EST/EDT' },
  { key: 'AST', value: 'America/Halifax', label: 'Atlantic Time (Canada) AST/ADT' },
  { key: 'NST', value: 'America/St_Johns', label: 'Newfoundland Time (Canada) NST/NDT' },
  { key: 'HAST', value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time HAST/HADT' },
  { key: 'AKST', value: 'America/Anchorage', label: 'Alaska Time (US) AKST/AKDT' },
  { key: 'SST', value: 'Pacific/Pago_Pago', label: 'Samoa Time SST' },
  { key: 'IST', value: 'Asia/Kolkata', label: 'Indian Standard Time IST' },
  { key: 'CST', value: 'Asia/Shanghai', label: 'China Standard Time CST' },
  { key: 'JST', value: 'Asia/Tokyo', label: 'Japan Standard Time JST' },
  { key: 'KST', value: 'Asia/Seoul', label: 'Korea Standard Time KST' },
  { key: 'AEST', value: 'Australia/Sydney', label: 'Australian Eastern Time (AET) AEST/AEDT' },
  { key: 'ACST', value: 'Australia/Adelaide', label: 'Australian Central Time (ACST) ACST/ACDT' },
  { key: 'AWST', value: 'Australia/Perth', label: 'Australian Western Time (AWST) AWST' },
  { key: 'GMT', value: 'Etc/GMT', label: 'Greenwich Mean Time GMT' },
  { key: 'CET', value: 'Europe/Berlin', label: 'Central European Time (CET) CET/CEST' },
  { key: 'EET', value: 'Europe/Athens', label: 'Eastern European Time (EET) EET/EEST' },
  { key: 'BRT', value: 'America/Sao_Paulo', label: 'Brasilia Time (Brazil) BRT' },
];
