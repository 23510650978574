import React, { useContext, useState } from 'react';
import { Popover } from 'antd';
import { ReactComponent as ThreeDotIcon } from '../../Images/threedot-icon.svg';
import { ReactComponent as OptionIcon } from '../../Images/option-icon.svg';
import { ReactComponent as BackgroundBlurIcon } from '../../Images/background-blur-icon.svg';
import zoomContext from '../../context/zoom-context';

// Extracted constants for session storage keys
const SESSION_KEYS = {
  PINNED_USER: 'pinned-user',
  ACTIVE_BG: 'active-bg',
};

const VideoOptions = ({ activeSpeaker, closePopover }: { activeSpeaker: any; closePopover: () => void }) => {
  const zmClient = useContext(zoomContext);
  const mediaStream = zmClient.getMediaStream();
  const currentUser = zmClient.getCurrentUserInfo()?.userId;
  const [refreshUI, setRefreshUI] = useState(false);
  const isCurrentUserActiveSpeaker = currentUser === activeSpeaker;
  const pinnedUserExists = Boolean(sessionStorage.getItem(SESSION_KEYS.PINNED_USER) || '');
  const activeBlur = Boolean(sessionStorage.getItem(SESSION_KEYS.ACTIVE_BG) || '');

  const handlePinUser = () => {
    if (pinnedUserExists) {
      sessionStorage.removeItem('pinned-user');
    } else {
      sessionStorage.setItem('pinned-user', activeSpeaker);
    }
    setRefreshUI(!refreshUI);
    closePopover();
  };

  const handleBlurBg = async () => {
    if (!isCurrentUserActiveSpeaker) return;

    try {
      const newActiveBlur = !activeBlur;
      sessionStorage.setItem(SESSION_KEYS.ACTIVE_BG, newActiveBlur ? 'true' : '');
      await mediaStream?.updateVirtualBackgroundImage(newActiveBlur ? 'blur' : undefined);
    } catch (error) {
      console.error('Error updating virtual background:', error);
    } finally {
      setRefreshUI(!refreshUI);
      closePopover();
    }
  };

  return (
    <div className='video-option-wrapper'>
      <div className='option-item' onClick={handlePinUser}>
        <OptionIcon />
        <span>{pinnedUserExists ? 'Unpin user' : 'Pin User'}</span>
      </div>
      {isCurrentUserActiveSpeaker && (<>
        <hr />
        <div className='option-item' onClick={handleBlurBg}>
          <BackgroundBlurIcon />
          <span>Blur background</span>
        </div>
        </>
      )}
    </div>
  );
};

const UserOptions = ({ activeSpeaker }: { activeSpeaker: any }) => {
  const [visible, setVisible] = useState(false);

  const handlePopoverClick = () => {
    setVisible(!visible);
  };

  return (
    <div className='zoom-meeting-video-label'>
      <div className='zoom-meeting-video-label-text' id='active-user-display-name'></div>
      {/* <Popover
        rootClassName='video-option-container'
        placement='topRight'
        content={<VideoOptions activeSpeaker={activeSpeaker} closePopover={() => setVisible(false)} />}
        trigger='click'
        open={visible}
      >
        <ThreeDotIcon onClick={handlePopoverClick} />
      </Popover> */}
    </div>
  );
};

export default UserOptions;
