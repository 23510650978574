import React from 'react';

const GetStarted = ({ handleNext }: { handleNext: (step: number) => void }) => (
  <div className='getstarted-container'>
    <h1>Let's get started.</h1>
    <p>
      We'll help you solve any video troubles you're experiencing but first, let's check your setup.
    </p>
    <button className='theme-button' onClick={() => handleNext(2)}>
      Get started
    </button>
  </div>
);

export default GetStarted;
