import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react';
import { get } from '../Utils/helpers';
import { auth } from '../Firebase/firebase';

const AuthContext = createContext(null as any);

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const interval = useRef<NodeJS.Timeout | null>();
  const [currentUser, setCurrentUser] = useState({
    user: null as any,
    is2FASuccess: false as boolean,
    loading: true,
  });

  const authListener = useCallback(async () => {
    const authHandler = async (user: any) => {
      if (user) {
        const tokenResult = await user.getIdTokenResult(true);
        console.log('user', user,tokenResult);
        if(get(tokenResult, 'claims.linkLogin', false) && get(tokenResult, 'claims.guestViewer', false)){
          Object.assign(user, { isMeetingGuest: true });
          Object.assign(user, { displayName: get(tokenResult, 'claims.displayName', '') });
        }
        setCurrentUser({
          user,
          is2FASuccess: get(tokenResult, 'claims.is2FACompleted', false)  ,
          loading: false,
        });
      } else {
        console.log('not a user');
        setCurrentUser({
          user: null,
          is2FASuccess: false,
          loading: false,
        });
      }
    };
    const tokenSubscribe = auth.onIdTokenChanged(authHandler);
    const authSubscribe = auth.onAuthStateChanged(authHandler);
    interval.current = setInterval(() => {
      auth.currentUser?.getIdToken(true);
    }, 59 * 60 * 1000); // Refresh token every hour
    
    return () => {
      if (interval.current) clearInterval(interval.current); // Clean up interval on component unmount
      tokenSubscribe(); // Clean up onIdTokenChanged on component unmount
      authSubscribe(); // Clean up onAuthStateChanged on component unmount
    };
  }, [currentUser]);

  useEffect(() => {
    authListener();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
