import { create } from 'zustand';

interface TVOSNotificationState {
  notifiedParticipants: Set<string>;
  addNotifiedParticipant: (id: string) => void;
  removeNotifiedParticipant: (id: string) => void;
}

export const useTVOSNotificationStore = create<TVOSNotificationState>((set) => ({
  notifiedParticipants: new Set(),

  addNotifiedParticipant: (id) =>
    set((state) => {
      const updatedSet = new Set(state.notifiedParticipants);
      updatedSet.add(id);
      return { notifiedParticipants: updatedSet };
    }),

  removeNotifiedParticipant: (id) =>
    set((state) => {
      const updatedSet = new Set(state.notifiedParticipants);
      updatedSet.delete(id);
      return { notifiedParticipants: updatedSet };
    }),
}));
