import chevronLeft from '../Images/chevron-left.svg';
import chevronRight from '../Images/chevron-right.svg';
import './styles.css';
import VideoTiles from './VideoTiles';

const moveLeft = async (event: React.MouseEvent<HTMLElement>) => {
  document.getElementById("vtray")?.scrollBy(-250, 0);
};
const moveRight = async (event: React.MouseEvent<HTMLElement>) => {
  document.getElementById("vtray")?.scrollBy(250, 0);
};

const VideoTray = (props:any) => {
  const {isSidebarOpen} =props;
  return (
    <div className='video-tray'>
      <div className={`video-tray-wrapper`} style={{'width': isSidebarOpen ? 'calc(100% - 357px)' : '100%'}}>
        <div className='tray-btns'>
          <img src={chevronLeft} onClick={moveLeft} alt='left-btn' />
        </div>
        <VideoTiles />
        <div className='tray-btns'>
          <img src={chevronRight} onClick={moveRight} alt='right-btn' />
        </div>
      </div>
    </div>
  );
};
export default VideoTray;
