import React, { useEffect, useRef } from 'react';
import ZoomVideo from '@zoom/videosdk';

interface CameraPreviewProps {
  isVideoOn: boolean;
}

const CameraPreview: React.FC<CameraPreviewProps> = ({ isVideoOn }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const localVideoTrackRef = useRef<any>(null);

  useEffect(() => {
    let client = ZoomVideo.createClient();

    const initVideo = async () => {
      await client.init('en-US', 'Global', { patchJsMedia: true });
      const devices = await ZoomVideo.getDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      if (videoDevices.length > 0) {
        const localVideoTrack = ZoomVideo.createLocalVideoTrack(videoDevices[0].deviceId);
        localVideoTrackRef.current = localVideoTrack;

        // Start the video if the prop is true and the video element is ready
        if (isVideoOn && videoRef.current) {
          localVideoTrack.start(videoRef.current);
        }
      }
    };

    initVideo();

    return () => {
      // Stop and clean up the local video track
      if (localVideoTrackRef.current) {
        localVideoTrackRef.current?.stop();
        // localVideoTrackRef.current?.destroy();
        localVideoTrackRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // Start or stop video based on the isVideoOn prop
    if (localVideoTrackRef.current && videoRef.current) {
      if (isVideoOn) {
        localVideoTrackRef.current.start(videoRef.current);
      } else {
        localVideoTrackRef.current.stop();
      }
    }
  }, [isVideoOn]);

  return <video ref={videoRef} style={{ width: '100%', height:'100%' }} autoPlay muted playsInline />;
};

export default CameraPreview;
