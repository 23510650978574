import { useEffect, useCallback, useState } from 'react';
import { Participant } from '@zoom/videosdk';
import { notification } from 'antd';

export function useScreenShare(zmClient: any, setActiveComponent: any, toggleHeaderIcons?: any) {
  const [sharingUser, setSharingUser] = useState<Participant | null>(null);
  const participants = zmClient?.getAllUser();
  const currentUser = zmClient?.getCurrentUserInfo();

  const handleScreenShareChange = useCallback(async (payload: any) => {
    const participant = participants.find((p: Participant) => p.userId === payload.userId);

    if (payload.state === 'Active') {
      setSharingUser(participant);
    } else if (payload.state === 'Inactive') {
      setActiveComponent('');
      setSharingUser(null);
    }
  }, [participants, setActiveComponent]);

  const onScreenShareClick = async () => {
    if (participants.some((p: Participant) => p.sharerOn)) {
      notification.warning({
        message: 'Only one person can share screen at a time',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }

    const stream: any = zmClient.getMediaStream();
    try {
      const videoElement = stream?.isStartShareScreenWithVideoElement()
        ? document.querySelector(`#zoom-meeting-screen-share-video`)
        : document.querySelector(`#zoom-meeting-screen-share-canvas`);

      await stream.startShareScreen(videoElement);
      toggleHeaderIcons('ScreenShare');
    } catch (error) {
      console.error('Error starting screen share:', error);
      if (toggleHeaderIcons) {
        toggleHeaderIcons('');
      }
      setActiveComponent('');
      setSharingUser(null);
    }
  };

  useEffect(() => {
    zmClient.on('active-share-change', handleScreenShareChange);
    return () => {
      zmClient.off('active-share-change', handleScreenShareChange);
    };
  }, [zmClient, handleScreenShareChange]);

  useEffect(() => {
    const isCurrentUserSharing = currentUser?.sharerOn;
    const isSharingUserCurrentUser = currentUser?.userId === sharingUser?.userId;

    if (currentUser && sharingUser && !isCurrentUserSharing && isSharingUserCurrentUser) {
      const stream: any = zmClient.getMediaStream();
      stream.stopShareView();
      if(toggleHeaderIcons){
        toggleHeaderIcons('');
      }
      setActiveComponent('');
      setSharingUser(null);
    }
  }, [currentUser, sharingUser, zmClient, toggleHeaderIcons, setActiveComponent]);

  useEffect(() => {
    const screenSharingRemoteParticipant = participants.find((p: Participant) => p.sharerOn);
    if (screenSharingRemoteParticipant && !sharingUser) {
      setSharingUser(screenSharingRemoteParticipant);
    }
  }, [participants, sharingUser]);

  return { sharingUser, onScreenShareClick };
}
