import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { useLocationStore } from '../../../../store/useLocationStore';
import { get, getQueryParam } from '../../Helpers/utils';
import { AuthContext } from '../../../../Context/authContext';
import { LoadingOutlined } from '@ant-design/icons';
import useNavigateToPage from '../../../../hooks/useNavigateToPage';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import { message, notification } from 'antd';
const CreatMeeting = (props: any) => {
  const { meetingLink, createMeetings, setShowPopup } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [completeMeetingLink, setCompleteMeetingLink] = useState(meetingLink);
  const loaderIcon = <LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />;
  const navigateToPage = useNavigateToPage();

  const locationState = useLocationStore((state) => state.locationState);
  const user_role_id = get(locationState, 'state.user_role_id', '');
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const currentDate = new Date();
  const currentIsoTimestamp = currentDate.toISOString().slice(0, 19) + '.000Z';
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1);
  const tomorrowIsoTimestamp = tomorrowDate.toISOString().slice(0, 19);
  const userId = get(authValue, 'uid', '');
  const userEmail = get(authValue, 'email', '');
  const displayName = get(authValue, 'email', '');
  const show_id = get(locationState, 'state.show_id', '');
  const meetingId = getQueryParam(meetingLink,"id")

  const [formData, setFormData] = useState({
    title: '',
    // description: '',
    // start_time: currentIsoTimestamp,
    // end_time: tomorrowIsoTimestamp,
    // timezone: 'Eastern Time (US and Canada)',
    // isRecurring: false,
    // use_pmi: true,
    // security: {
    //   password: '3kGxTK',
    //   waitingRoomEnabled: true,
    // },
    // video_config: {
    //   hostVideo: true,
    //   participant_video: true,
    // },
    // audio_config: {
    //   type: 'Computer Audio',
    // },
    // attendees: [
    //   {
    //     id: userId,
    //     email: userEmail,
    //     type: 'user',
    //     participant_show_user_role_id: user_role_id,
    //     name: displayName,
    //     role: 'attendee',
    //   },
    // ],
    // host_show_user_role_id: user_role_id,
    // show_id: show_id,
  });

  // useEffect(() => {
  //   const fetchConfiguration = async () => {
  //     setIsLoading(true);
  //     try {
  //       const config = await fetchHostMeetingConfiguration();
  //       const userPassword = config?.data?.default_pin;
  //       if (userPassword) {
  //         const updatedMeetingLink = `${meetingLink}?p=${encodeURIComponent(userPassword)}`;
  //         setCompleteMeetingLink(updatedMeetingLink);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching configuration:', error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchConfiguration();
  // }, [meetingId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  // const fetchHostMeetingConfiguration = async () => {
  //   setIsLoading(true);
  //   try {
  //     const data = {
  //       api: api.meetings.getHostMeetingConfiguration,
  //       queryParam: { meetingId },
  //     };

  //     const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
  //     return result;
  //   } catch (err: any) {
  //     console.error('Error fetching host meeting configuration:', err);
  //     return null;
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleStartMeeting = async () => {
    setIsLoading(true);
    try {
      //  await createMeetings(formData, true);
      // Pass true for unscheduled meeting

      let input: any = {
        meeting_title: formData.title,
      };

      const data = {
        api: api.users.edit,
        payLoad: JSON.stringify(input),
      };

      const result = await backend.save(data, authValue.accessToken || '');
      if(result){
        navigateToPage('/zoom-meeting', {
          state: {
            meetingId: meetingId,
            meetingLink: completeMeetingLink,
            show_id: show_id,
            user_role_id: user_role_id,
          },
        });
      }
      

      // setFormData((prevData) => ({
      //   ...prevData,
      //   meetingLink: meetingLink,
      // }));
    } catch (error) {
      console.error('Error starting meeting:', error);
      notification.error({
        message:'Error starting meeting'
      });
      
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(completeMeetingLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <div className='create-meeting-container'>
        <h3 className='create-meeting-header'>Start A New Meeting</h3>
        <input
          name='title'
          placeholder='Meeting Name'
          value={formData.title}
          onChange={handleInputChange}
        />
        <div className='create-meeting-link-container'>
          <div className='create-meeting-link'>
            <p>{completeMeetingLink}</p>
            <div onClick={handleCopy} style={{ cursor: 'pointer', display: 'inline-flex' }}>
              {copied && (
                <div className='create-meeting-copy-notification visible'>Copied to Clipboard</div>
              )}
              <svg
                width='39'
                height='38'
                viewBox='0 0 39 38'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3 11C3 6.58172 6.58172 3 11 3H27C31.4183 3 35 6.58172 35 11V27C35 31.4183 31.4183 35 27 35H11C6.58172 35 3 31.4183 3 27V11Z'
                  fill='#6F2DDA'
                />
                <path
                  d='M24.25 25.75H16V15.25H24.25M24.25 13.75H16C15.6022 13.75 15.2206 13.908 14.9393 14.1893C14.658 14.4706 14.5 14.8522 14.5 15.25V25.75C14.5 26.1478 14.658 26.5294 14.9393 26.8107C15.2206 27.092 15.6022 27.25 16 27.25H24.25C24.6478 27.25 25.0294 27.092 25.3107 26.8107C25.592 26.5294 25.75 26.1478 25.75 25.75V15.25C25.75 14.8522 25.592 14.4706 25.3107 14.1893C25.0294 13.908 24.6478 13.75 24.25 13.75ZM22 10.75H13C12.6022 10.75 12.2206 10.908 11.9393 11.1893C11.658 11.4706 11.5 11.8522 11.5 12.25V22.75H13V12.25H22V10.75Z'
                  fill='white'
                />
              </svg>
            </div>
          </div>
          <div className='create-meeting-dialin'>
            {/* <h4>Dial In</h4>
            <div>+123 456 7890</div>
            <div>+123 456 7890</div>
            <div>+123 456 7890</div> */}
          </div>
        </div>
        <div className='button-container'>
          <button className='create-meeting-cancel-button' onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="create-meeting-start-button"
            onClick={handleStartMeeting}
            disabled={isLoading || !formData.title}
          >
            {isLoading ? loaderIcon : 'Create Meeting'}
          </button>
        </div>
      </div>
    </>
  );
};
export default CreatMeeting;
