import { useContext, useEffect, useRef } from 'react';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { get } from '../Helpers/utils';
import { AuthContext } from '../../../Context/authContext';

const useApiPolling = (wid: string) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the interval ID
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  const startPolling = () => {
    if (!intervalRef.current) {
      const fetchApi = async () => {
        try {
          if(!wid){
            return
          }
          const data = {
            api: api.meetings.updateDocumentTimestamp,
            payLoad: {
              waiting_room_id: wid,
            },
          };
          const result = await backend.save(data, get(authValue, 'accessToken', ''));
          return result;
        } catch (error) {
          console.error('Error updating waiting status:', error);
        }
      };

      intervalRef.current = setInterval(fetchApi, 10000); // Call API every 10 seconds
    }
  };

  const stopPolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the interval
      intervalRef.current = null; // Reset the ref
    }
  };

  useEffect(() => {
    startPolling(); // Start polling when the hook is used

    return () => stopPolling(); // Cleanup on component unmount
  }, [wid]);

  return stopPolling; // Return the stopPolling function
};

export default useApiPolling;
