import { useContext } from 'react';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { get } from '../Helpers/utils';
import { AuthContext } from '../../../Context/authContext';

export const useConferenceCommands = (meetingId: string, show_id: string, id:string, session_id:string) => {
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const leaveConference = async (endFlag: boolean) => {
    try {
      const data = {
        api: api.meetings.leaveMeeting,
        payLoad: {
          meetingId,
          show_id,
          endFlag,
          participant_role_id:id,
          session_id:session_id
        },
      };

      const token = get(authValue, 'accessToken', {});
      await backend.save(data, token);
    } catch (error) {
      console.error('Error leaving conference:', error);
      // Handle error appropriately (e.g., show a notification to the user)
    }
  };

  return { leaveConference };
};
