import React, { useState, useEffect, useContext, MouseEvent } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { ROLES, PAGES } from '../../Utils/constants';
import { ActiveShowUserRole } from './structs';
import ProgressLoader from '../Common/ProgressLoader';
import { auth } from '../../Firebase';
import { Layout } from '../PageLayout/Layout';
import { Mixpanel } from '../../Service/Mixpanel';
import { firstBy } from 'thenby';
import UserShows from './UserShows';
import { fetchActiveUserShows, fetchFeatures, searchClient, fetchMailboxShows } from '../../Service/show';
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import useNavigateToPage from '../../hooks/useNavigateToPage';
import { useLocationStore } from '../../store/useLocationStore';

const ActiveShowsSelection = () => {
  const currentUser = get(useContext(AuthContext), 'currentUser', {});
  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  const navigationType = useNavigationType();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [activeShowUserRoles, setActiveShowUserRoles] = useState([] as any);
  const location: any = useLocation();
  const userId = auth.getUser()?.uid || '';

  useEffect(() => {
    sessionStorage.removeItem('stream_id');
    sessionStorage.removeItem('allowedGuest');
    sessionStorage.removeItem('stream_label');
    sessionStorage.removeItem('vc_id');
    sessionStorage.removeItem('auto_allow');
    sessionStorage.removeItem('urlId');
    sessionStorage.removeItem('publisher');
    sessionStorage.removeItem('localIdentity');
    sessionStorage.removeItem('pubname');
    sessionStorage.removeItem('previousStreamName');
    return () => {
      const signout = async () => {
        try {
          setLoading(true);
          await auth.doSignOut();
          Mixpanel.track('Logout', { Platform: 'Web' });
          setLoading(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      if (navigationType === 'POP' && location && location.pathname === '/login') {
        signout();
      }
    };
  }, [navigationType, location.pathname]);

  useEffect(() => {
     searchClient.clearCache();
    // Mixpanel
    Mixpanel.track('Visit', {
      Platform: 'Web',
      'Page Name': PAGES.INTERMEDIATE_PAGE,
    });

    setSuccessMessage('');
    setErrorMessage('');

    const addTeamAdminFlagToOtherRoles = (showUserRoles: any[]) => {
      const teamAdminData: any = {};
      for (let showUserRole of showUserRoles) {
        if (
          showUserRole &&
          showUserRole.role_type === ROLES.SHOW_PUBLISHER &&
          showUserRole.team_admin
        ) {
          teamAdminData[showUserRole.show_id] = true;
        }
      }
      for (let showUserRole of showUserRoles) {
        if (
          showUserRole &&
          (showUserRole.role_type === ROLES.SHOW_ADMIN ||
            showUserRole.role_type === ROLES.SHOW_VIEWER)
        ) {
          if (teamAdminData[showUserRole.show_id]) {
            showUserRole.team_admin = true;
          }
        }
      }
    };

    const fetchData = async () => {
      try {
        setSuccessMessage('');
        setErrorMessage('');

        setLoading(true);
        // //Pull All Feature Flags
        // await getAllFeatureFlags();
        let response = await fetchActiveUserShows(userId);

        if (response?.hits) {
          const showUserRoles = response.hits;
          showUserRoles.sort(
            firstBy(function (v: any) {
              return v.show_name.toLowerCase();
            }).thenBy(function (v: any) {
              return v.role_type.toLowerCase();
            }),
          );
          const showIds = [
            ...new Set(
              showUserRoles
               .filter((shows) => get(shows, 'show_id', '') !== -1)
               .map((shows) => `objectID:${get(shows, 'show_id', '')}`) 
            ),
          ];
          const mailboxShow = await fetchMailboxShows(showIds)
          const mailBox_show_id = mailboxShow.map((item:any)=> item.objectID)
          const newUserRoles = showUserRoles.filter((roles:any)=> !(roles.role_type === ROLES.MAILBOX && !mailBox_show_id.includes(roles.show_id))) 
          addTeamAdminFlagToOtherRoles(showUserRoles);
          setActiveShowUserRoles(newUserRoles);
          setSuccessMessage(`Successfully pulled active showUserRoles list`);
        }
      } catch (err: any) {
        const msg = 'Error while pulling shows !';
        console.error(msg);
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    if (!userId) {
      navigateToPage('/login',{});
    } else {
      fetchData();
    }
  }, [userId]);

  const navigateToUserHome = async (showUserRole: any) => {
    try {
      // Navigation
      if (showUserRole && showUserRole.show_id) {
        const role = showUserRole.role_type;
        const show_id = showUserRole.show_id;
        const team_admin = !!showUserRole.team_admin;
        const show_name = showUserRole.show_name;
        const show_code = showUserRole.show_code;
        const user_role_id = showUserRole.objectID;
        console.log('role', role);
        console.log('team_admin', team_admin);

        let usersGroupsFlag = false;
        const flagResult = await fetchFeatures(show_id);
        console.log('flagResult', flagResult);
        if (role && (role === ROLES.SHOW_ADMIN || role === ROLES.SHOW_PUBLISHER)) {
          usersGroupsFlag = get(flagResult, 'ALLOW_USERS_AND_GROUPS', false);
        }

        if (!role || !show_id) {
          return;
        } else if (role && role === ROLES.SUPER_ADMIN) {
          console.log('NavigateToPage to super admin dashboard');
          navigateToPage(`/superadmin`,{});
        } else if (role && role === ROLES.SHOW_FINANCE) {
          console.log('NavigateToPage to show finance dashboard');
          navigateToPage(`/showfinance`,{});
        } else if (role && role === ROLES.SHOW_ADMIN && !!team_admin) {
          console.log('NavigateToPage to team admin dashboard');
          navigateToPage('/teamadmin', { state: { show_id, team_admin, usersGroupsFlag } });
        } else if (role && role === ROLES.SHOW_ADMIN) {
          console.log('NavigateToPage to show admin dashboard');
          navigateToPage('/showadmin', { state: { show_id, team_admin, usersGroupsFlag } });
        } else if (role && role === ROLES.SHOW_PUBLISHER) {
          console.log('NavigateToPage to show publisher dashboard');
          navigateToPage('/showpublisher', { state: { show_id, usersGroupsFlag } });
        } else if (role && role === ROLES.MAILBOX) {
          console.log('NavigateToPage to mailbox');
          navigateToPage('/mailbox', { state: { show_id } });
        } else if (
          role &&
          role === ROLES.SHOW_VIEWER &&
          get(flagResult, 'ALLOW_ZOOM_MEETING', false)
        ) {
          navigateToPage('/intermediate', {
            state: {
              show_id,
              show_name,
              show_code,
              team_admin,
              zoomFlag: get(flagResult, 'ALLOW_ZOOM_VIEWER', true),
              user_role_id: user_role_id,
              viewerFlag: get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true),
            },
          });
        } else if (
          role &&
          role === ROLES.SHOW_VIEWER &&
          get(flagResult, 'ALLOW_ZOOM_VIEWER', false)
        ) {
          navigateToPage('/viewer', {
            state: {
              show_id,
              show_name,
              show_code,
              team_admin,
              viewerFlag: get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true),
            },
          });
        } else if (
          role &&
          role === ROLES.SHOW_VIEWER &&
          !get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true)
        ) {
          console.log('NavigateToPage to show viewer dashboard');
          navigateToPage('/oldshowviewer', {
            state: {
              show_id,
              show_name,
              show_code,
              team_admin,
              viewerFlag: get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true),
            },
          });
        } else if (
          role &&
          role === ROLES.GUEST_VIEWER &&
          !get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true)
        ) {
          console.log('NavigateToPage to guest viewer dashboard');
          navigateToPage('/oldshowviewer', {
            state: {
              show_id,
              show_name,
              show_code,
              viewerFlag: get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true),
            },
          });
        } else if (
          role &&
          role === ROLES.SHOW_VIEWER &&
          get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true)
        ) {
          console.log('NavigateToPage to show viewer dashboard');
          sessionStorage.setItem('show_id', show_id);
          navigateToPage('/showviewer', {
            state: {
              show_id,
              show_name,
              show_code,
              team_admin,
              viewerFlag: get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true),
            },
          });
        } else if (
          role &&
          role === ROLES.GUEST_VIEWER &&
          get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true)
        ) {
          console.log('NavigateToPage to guest viewer dashboard');
          navigateToPage('/showviewer', {
            state: {
              show_id,
              show_name,
              show_code,
              viewerFlag: get(flagResult, 'ALLOW_NEW_SHOW_VIEWER', true),
            },
          });
        }
      } else {
        const msg = 'Show information not avilable';
        throw new Error(msg);
      }
    } catch (err: any) {
      console.error(err);
      setErrorMessage(err.message);
    }
  };

  const selectShow = async (
    event: MouseEvent<HTMLTableRowElement>,
    showUserRole: ActiveShowUserRole,
  ) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      console.log(showUserRole);
      setLoading(true);
      navigateToUserHome(showUserRole);
    } catch (err: any) {
      console.error(err);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <Layout page={PAGES.INTERMEDIATE_PAGE}>
      <ProgressLoader loading={loading} />
      <div className='left-content ipad-view-show-list '>
        <div className='intermediate_image'></div>
      </div>

      <div className='right-content-intermediate ipad-view'>
        <div className='right-list-container show-selection'>
          <div className='intermediate-list-heading'>
            <p className='heading-text'>Select Show</p>
          </div>
          <div className='inetermediate-show-list'>
            <UserShows activeShowUserRoles={activeShowUserRoles} selectShow={selectShow} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ActiveShowsSelection;
