import { Dropdown, Modal } from 'semantic-ui-react';
import ImageCanvas from '../../../UserSettings/ImageCanvas';
import { useContext, useEffect, useState, useRef } from 'react';
import { auth } from '../../../../Firebase';
import zoomContext from '../../context/zoom-context';
import useNavigateToPage from '../../../../hooks/useNavigateToPage';
import { get } from '../../../../Utils/helpers';
import { useLocationStore } from '../../../../store/useLocationStore';
import { useLocation } from 'react-router-dom';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import { AuthContext } from '../../../../Context/authContext';
import { Spin } from 'antd';
import CopyIcon from '../../SidePannel/images/copy.png';

const ProfileIcon = () => {
  const [user_imageurl, setProfileImageUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullLinkVisible, setIsFullLinkVisible] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [shortLink, setShortLink] = useState('');
  const [shortLinkId, setShortLinkId] = useState('');
  const [pmi, setPmi] = useState('');
  let style = null;
  const navigateToPage = useNavigateToPage();
  const [waitingRoomEnabled, setWaitingRoomEnabled] = useState(false);
  const zmClient = useContext(zoomContext);
  const locationState = useLocationStore((state) => state.locationState);
  const meeting_doc_id = get(locationState, 'state.meeting_doc_id', '');
  const location = useLocation();
  const authValue = useContext(AuthContext)?.currentUser?.user || {};
  const [loading, setLoading] = useState(false); // New loading state
  const isGuestUser = authValue.isMeetingGuest || false;
  const initialSettings = useRef({ waitingRoomEnabled: false });

  useEffect(() => {
    fetchUserprofile();
    if (!isGuestUser) {
      fetchUserprofile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGuestUser, authValue]);

  const fetchUserprofile = async () => {
    try {
      const data = {
        api: api.users.get,
      };
      setLoading(true);
      const profiledata = await backend.fetch(data, authValue.accessToken || '');
      setLoading(false);
      if (profiledata) {
        const { personal_meeting_details, imageUrlPath } =
          profiledata;
        setProfileImageUrl(imageUrlPath);
        setPmi(get(personal_meeting_details,'pmi',''));
        setPasscode(get(personal_meeting_details,'default_pin',''));
        setWaitingRoomEnabled(get(personal_meeting_details,'waiting_room_enabled',''));
        setMeetingLink(get(personal_meeting_details,'meeting_link',''));
        initialSettings.current = {
          waitingRoomEnabled: get(personal_meeting_details,'waiting_room_enabled',false)
        };
        setShortLink(
          get(personal_meeting_details,'meeting_short_link',''))
          setShortLinkId(get(personal_meeting_details,'meeting_short_link_id',''))
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      await zmClient.leave();
      sessionStorage.clear();
      await auth.doSignOut();
    } catch (err: any) {
      console.log(err);
    } finally {
      navigateToPage('/logoutredirect', { replace: true }, true);
    }
  };
  const handleEditProfileClick = () => {
    if (!meeting_doc_id) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setWaitingRoomEnabled(initialSettings.current.waitingRoomEnabled);
  };
  const handleSaveProfile = async () => {
    try {
      let input: any = {};
      if (passcode || meetingLink || shortLinkId || pmi) {
        input = {
          default_pin: passcode,
          meeting_link: meetingLink,
          pmi: pmi,
          meeting_short_link_id: shortLinkId,
        };
      }

      if (waitingRoomEnabled !== initialSettings.current.waitingRoomEnabled) {
        input = {
        waiting_room_enabled: waitingRoomEnabled,
      };
      }

      if (Object.keys(input).length > 0) {
      const data = {
        api: api.users.edit,
        payLoad: JSON.stringify(input),
      };

      await backend.save(data, authValue.accessToken || '');
      }
      setIsModalOpen(false);
      initialSettings.current = {
        waitingRoomEnabled: waitingRoomEnabled,
      };
      await fetchUserprofile();
    } catch (err) {
      console.error('Error saving profile:', err);
    }
  };

  

  return (
    <>
      <Dropdown
        style={style}
        pointing='top right'
        icon={<ImageCanvas imageUrl={user_imageurl} className={'profile-image-property'} />}
      >
        <Dropdown.Menu>
          {((location.pathname.includes('/intermediate') && !isGuestUser) ||
            (location.pathname.includes('/zoom-meeting') && !isGuestUser && !meeting_doc_id)) && (
            <Dropdown.Item text='Edit Profile' icon='edit' onClick={handleEditProfileClick} />
          )}
          <Dropdown.Item text='Log Out' icon='power' onClick={signOut} />
        </Dropdown.Menu>
      </Dropdown>

      <Modal className='edit-profile-popup' open={isModalOpen} onClose={handleCloseModal}>
        <div className='zoom-meeting-edit-modal'>
          <h1 className='zoom-meeting-edit-modal-title'>Meeting Details</h1>
          <br />
          <br />
          <div className='zoom-meet-details'>
            {loading ? (
              <Spin size='large' />
            ) : (
              !isGuestUser && (
                <>
                  <div className='edit-profile-options-wrapper'>
                    <fieldset className='security-fieldset'>
                      <legend>Security</legend>
                      <br />
                      <label>
                        Password
                          <input
                            type='password'
                            name='passcode'
                            autoComplete='new-password'
                            className='new-meeting'
                            placeholder='Password'
                            value={passcode}
                            onChange={(e) => setPasscode(e.target.value)}
                          />
                      </label>
                      <span className='info-text'>
                        Only users who have the invite link or password can join the meeting
                      </span>
                      <br />
                      <br />
                      <br />
                      <label>
                        <input
                          type='checkbox'
                          checked={waitingRoomEnabled}
                          onChange={(e) => setWaitingRoomEnabled(e.target.checked)}
                        />{' '}
                        Waiting Room
                      </label>
                      <br />
                      <span className='info-text'>
                        Only users admitted by the host can join the meeting
                      </span>
                      <br />
                      <br />
                      <br />
                      <legend>Meeting Link</legend>
                      <div className='zoom-meeting-participants-link-box'>
                        <div className='zoom-meeting-participants-invitation-link'>
                          {meetingLink}
                        </div>
                        <button
                          className={`zoom-meeting-participants-copy-button ${
                            isFullLinkVisible ? 'copied' : ''
                          }`}
                          onClick={() => {
                            navigator.clipboard.writeText(meetingLink);
                            setIsFullLinkVisible(true);
                            setTimeout(() => setIsFullLinkVisible(false), 2000);
                          }}
                        >
                          <img src={CopyIcon} alt='Copy' width={38} height={38} />
                          {isFullLinkVisible && (
                            <div className='zoom-meeting-participants-copied-notification visible'>
                              Copied to Clipboard
                            </div>
                          )}
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </>
              )
            )}
          </div>
          {!loading && (
         <>
          <button className='zoom-meeting-cancel-button' onClick={handleCloseModal}>
            Cancel
          </button>
          <button className='zoom-meeting-save-button' onClick={handleSaveProfile}>
            Save
          </button>
        </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ProfileIcon;
