import { useEffect } from 'react';
import useParticipants from '../../Hooks/useParticipants';
import { LayoutType } from '../../types/header-actiontypes';
import InvertIcon from '../images/invert-icon.svg';
import StackDownIcon from '../images/stack-down.svg';
import StackUpIcon from '../images/stack-up.svg';
import Gallery from '../images/gallery-view.svg';
import Icon from '../images/keyboard-arrow-down.svg';
import { Dropdown, Menu } from 'antd';
import './styles.css';

interface LayoutOptionsProps {
  layoutMode: LayoutType;
  setLayoutMode: (option: LayoutType) => void;
}

const LayoutOptions: React.FC<LayoutOptionsProps> = ({ layoutMode, setLayoutMode }) => {
  const participants: any = useParticipants() || [];

  useEffect(() => {
    adjustContainerClassBasedOnParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  const adjustContainerClassBasedOnParticipants = () => {
    const container = document.getElementById('video-player-custom-container');
    if (!container) return;

    if (participants.length > 4 && layoutMode === 'gallery') {
      toggleContainerClasses(container, 'gallery-main', 'gallery-mini');
    } else if (participants.length <= 4 && layoutMode === 'gallery') {
      toggleContainerClasses(container, 'gallery-mini', 'gallery-main');
    }
  };

  const toggleContainerClasses = (
    container: HTMLElement,
    addClass: string,
    removeClass: string,
  ) => {
    container.classList.add(addClass);
    container.classList.remove(removeClass);
  };

  const handleLayoutModeToggle = (layout: LayoutType) => {
    const container = document.getElementById('video-player-custom-container');
    // const nextLayoutMode = calculateNextLayoutMode();

    if (container) {
      applyLayoutModeClasses(container, layout);
    }

    setLayoutMode(layout);
  };

  //   const calculateNextLayoutMode = () => {
  //     return layoutMode === 'gallery' ? '' : layoutMode === 'invert'  ? 'gallery' : 'invert';
  //   };

  const applyLayoutModeClasses = (container: HTMLElement, nextLayoutMode: string) => {
    if (nextLayoutMode === 'gallery') {
      participants.length <= 4
        ? toggleContainerClasses(container, 'gallery-mini', 'gallery-main')
        : toggleContainerClasses(container, 'gallery-main', 'gallery-mini');
    } else {
      container.classList.remove('gallery-mini', 'gallery-main');
    }
  };

  const dropdownMenuItems = [
    {
      key: '1',
      label: 'Speaker View - Top Panel',
      value: 'invert',
      className: 'speaker-view',
      icon: (
        <img
          src={StackUpIcon}
          alt='Toggle Layout'
          width={'50%'}
          onClick={() => handleLayoutModeToggle('invert')}
          className='layout-option-button'
        />
      ),
    },
    {
      key: '2',
      label: 'Speaker View - Bottom Panel',
      value: '',
      icon: (
        <img
          src={StackDownIcon}
          alt='Toggle Layout'
          width={'50%'}
          onClick={() => handleLayoutModeToggle('')}
          className='layout-option-button'
        />
      ),
    },
    {
      key: '3',
      label: 'Gallery View',
      value: 'gallery',
      icon: (
        <img
          src={Gallery}
          alt='Toggle Layout'
          width={'50%'}
          onClick={() => handleLayoutModeToggle('gallery')}
          className='layout-option-button'
        />
      ),
    },
  ];

  const handleMenuClick = (e: any) => {
    const selectedValue = dropdownMenuItems.find((item) => item.key === e.key)?.value;
    if (selectedValue !== undefined) {
      const container = document.getElementById('video-player-custom-container');
      if (container) {
        applyLayoutModeClasses(container, selectedValue);
      }
      setLayoutMode(selectedValue as LayoutType);
    }
  };
  const menuClassName = 'zoom-meeting-layout-option-wrapper';
  const menu = (
    <Menu onClick={handleMenuClick} className={menuClassName}>
      {dropdownMenuItems.map((item, index) => (
        <Menu.Item 
          className='zoom-meeting-layout-options'
          key={item.key}
        >
          {item.icon}
          {item.label}
          {index !== dropdownMenuItems.length - 1 && <div className='gallery-custom-divider' />}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className='right-buttons invert-icons'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Dropdown 
        overlay={menu} 
        trigger={['click']} 
        placement={'bottomLeft'}>
        <img
          src={StackDownIcon}
          alt='Toggle Layout'
          width={'50%'}
            // This image now triggers the dropdown
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default LayoutOptions;
