import { useState, useEffect, useCallback, useContext } from 'react';
import useDbTrigger from '../../../../hooks/useDbTrigger';
import { useLocationStore } from '../../../../store/useLocationStore';
import { get, isTimestampRecent } from '../../Helpers/utils';
import { notification, Spin } from 'antd';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import { AuthContext } from '../../../../Context/authContext';
import { LoadingOutlined } from '@ant-design/icons';
import zoomContext from '../../context/zoom-context';
import useFilteredParticipants from '../../Hooks/useFilteredParticipants';

interface Participant {
  id: string;
  participant_show_user_role_id : string;
  name: string;
  status: string;
  meeting_id: string;
  updated_at:any
}


const WaitingParticipants: React.FC = () => {
  const locationState = useLocationStore((state) => state.locationState);
  const meetingId = get(locationState, 'state.meetingId', '');
  const show_id = get(locationState, 'state.show_id', '');

  const [participants, setParticipants] = useState<Participant[]>([]);
  const zmClient = useContext(zoomContext);
  const participantJoinedSession = zmClient?.getSessionInfo().isInMeeting;
  const notifications = useDbTrigger('meeting_participants', participantJoinedSession);
  const filteredParticipants = useFilteredParticipants(participants);

  const [actionLoading, setActionLoading] = useState<{
    allow: string;
    remove: string;
  }>({ allow: '', remove: '' });
  const loaderIcon = <LoadingOutlined style={{ fontSize: 20, color: "#1890ff" }} spin />;

  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  // const isHost = get(authValue, 'role', '') === 'host';

  const [loading, setLoading] = useState(false);

  const allowGuestUser = async (guest: Participant) => {
    setActionLoading((prev) => ({ ...prev, allow: guest.participant_show_user_role_id }));
    try {
      const { participant_show_user_role_id } = guest;

      const data = {
        api: api.meetings.allowGuest,
        payLoad: {
          guestUserId: participant_show_user_role_id,
          meetingId: meetingId,
          show_id: show_id,
        },
      };
      const result = await backend.save(data, get(authValue, 'accessToken', ''));
      if (result) {
        removeParticipant(participant_show_user_role_id);
      }
    } catch (e: any) {
      notification.error({
        message: 'Error allowing guest user',
        description: e.message || 'An unexpected error occurred.',
      });
    } finally {
      setActionLoading((prev) => ({ ...prev, allow: '' }));
    }
  };

  const removeGuestUser = async (guest: Participant) => {
    setActionLoading((prev) => ({ ...prev, remove: guest.participant_show_user_role_id }));
    try {
      const { participant_show_user_role_id } = guest;

      const data = {
        api: api.meetings.removeGuest,
        payLoad: {
          guestUserId: participant_show_user_role_id,
          meetingId: meetingId,
          show_id: show_id,
        },
      };
      const result = await backend.save(data, get(authValue, 'accessToken', ''));
      if (result) {
        removeParticipant(participant_show_user_role_id);
      }
    } catch (e: any) {
      notification.error({
        message: 'Error removing guest user',
        description: e.message || 'An unexpected error occurred.',
      });
    } finally {
      setActionLoading((prev) => ({ ...prev, remove: '' }));
    }
  };

  const fetchWaitingGuests = async () => {
    setLoading(true);
    try {
      const data = {
        api: api.meetings.getMeetingGuests,
        queryParam: {
          meetingId: meetingId,
          show_id: show_id,
        },
      };

      const response = await backend.fetch(data, get(authValue, 'accessToken', ''));

      if (response.length) {
        setParticipants(response.filter((user: Participant) => user.status === 'waiting'));
      }
    } catch (e: any) {
      notification.error({
        message: 'Error fetching waiting guests',
        description: e.message || 'An unexpected error occurred.',
      });
    } finally {
      setLoading(false);
    }
  };

  const addParticipant = useCallback(
    (participant: Participant) => {
      setParticipants((prev) => {
        const index = prev.findIndex(
          (p) => p.participant_show_user_role_id === participant.participant_show_user_role_id
        );
        if (index !== -1) {
          if (prev[index].updated_at !== participant.updated_at) {
            // Create a new array with the updated participant
            const updatedParticipants = [...prev];
            updatedParticipants[index] = participant;
            return updatedParticipants;
          }
          return prev; // No update needed
        }
        return [...prev, participant]; // Add new participant
      });
    },
    [setParticipants],
  );
  

  const removeParticipant = useCallback(
    (participantId: string) => {
      setParticipants((prev) => {
        const updatedParticipants = prev.filter((p) => p.participant_show_user_role_id !== participantId);
        return updatedParticipants;
      });
    },
    [setParticipants],
  );

  useEffect(() => {
    if (!notifications?.doc?.data()) return;

    const { status, participant_show_user_role_id , meeting_id, updated_at } = notifications.doc.data();
    const isRecentRecord = isTimestampRecent(updated_at)
    if (meeting_id === meetingId) {
      if (status === 'allowed' || (!isRecentRecord && status === 'waiting')) {
        removeParticipant(participant_show_user_role_id );
      } 
    }
  }, [notifications, meetingId, removeParticipant]);

  useEffect(()=> {
    if (!notifications?.doc?.data()) return;

    const { status, meeting_id, updated_at } = notifications.doc.data();
    if(meeting_id === meetingId){
      const isRecentRecord = isTimestampRecent(updated_at)
      if (status === 'waiting' && notifications.type !== 'removed' && isRecentRecord){
          addParticipant(notifications.doc.data() as Participant);
        }
      }
    }, [notifications, meetingId,addParticipant]
  );

  useEffect(() => {
    if (!participants.length) {
      fetchWaitingGuests();
    }

  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <div className='zoom-meeting-loading-container'>
          <Spin />
        </div>
      ) : null}
      <div
        className='zoom-meeting-waiting-container'
        style={{ display: !filteredParticipants.length ? 'none' : 'block' }}
      >
        <div className='zoom-meeting-participants-window-waiting'>
          <div className='zoom-meeting-participants-count'>
            <div>{`${filteredParticipants.length} person${
              filteredParticipants.length !== 1 ? 's' : ''
            } is waiting`}</div>
          </div>
          <div className='zoom-meeting-waiting-participants'>
          {filteredParticipants.map((participant) => (
            <div
              key={`${get(participant,'participant_show_user_role_id','')}`}
              className='zoom-meeting-waiting-view'
            >
              <span className='zoom-meeting-guest-name'>{get(participant,'name','')}</span>
              
              <div className='zoom-meeting-button-container'>
                <button
                  className='zoom-meeting-remove-button'
                  onClick={() => removeGuestUser(participant)}
                  disabled={actionLoading.remove === get(participant,'participant_show_user_role_id','')}
                >
                  {actionLoading.remove === get(participant,'participant_show_user_role_id','') ? loaderIcon : 'Remove'}
                </button>
                <button
                  className='zoom-meeting-allow-button'
                  onClick={() => allowGuestUser(participant)}
                  disabled={actionLoading.allow === get(participant,'participant_show_user_role_id','')}
                >
                  {actionLoading.allow === get(participant,'participant_show_user_role_id','') ? loaderIcon : 'Allow'}
                </button>
              </div>  
              
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingParticipants;