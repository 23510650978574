import { useState, useEffect, useContext } from 'react';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { get } from '../../../Utils/helpers';
import { AuthContext } from '../../../Context/authContext';
import { getGuestToken } from '../Helpers/waitingRoomHelpers';
import { auth } from '../../../Firebase/firebase';
import { useConferenceCommands } from './useConferenceCommands';
import { useLocationStore } from '../../../store/useLocationStore';
import useNavigateToPage from '../../../hooks/useNavigateToPage';

interface UseMeetingValidationResult {
  loading: boolean;
  status: 'approved' | 'rejected' | null;
  meetingDetails: any | null;
}

export const useMeetingValidation = (
  meeting_doc_id: string,
  show_id: string,
  meeting_id: string,
): UseMeetingValidationResult => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<'approved' | 'rejected' | null>(null);
  const [meetingDetails, setMeetingDetails] = useState<any | null>(null);
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const userId = get(authValue, 'uid', '');
  const isGuestUser = get(authValue, 'isMeetingGuest', false);
  const locationState = useLocationStore((state) => state.locationState);
  const participant_show_user_role_id = get(locationState, 'state.user_role_id', '');
  const sessionId = get(locationState, 'state.session_id', '');
  const show_name = get(locationState, 'state.show_name', '');
  const show_code = get(locationState, 'state.show_code', '');
  const team_admin = get(locationState, 'state.team_admin', '');
  const zoomFlag = get(locationState, 'state.zoomFlag', '');
  const viewerFlag = get(locationState, 'state.viewerFlag', '');

  const { leaveConference } = useConferenceCommands(meeting_id,show_id,participant_show_user_role_id,sessionId);
  const navigateToPage = useNavigateToPage();

  const validateMeeting = async (meetingdocId: string, show_id: string, accessToken: string) => {
    try {
      const body = { show_id: show_id, meetingId: meeting_id, meeting_doc_id: meetingdocId };
      const data = {
        api: api.meetings.validateMeetingAccess,
        payLoad: JSON.stringify(body),
      };
      const result = await backend.save(data, accessToken);

      return result;
    } catch (error) {
      console.error('Error fetching meeting details:', error);
      throw error;
    }
  };

  const handleMeetingResponse = (result: any) => {
    Object.assign(result, { host_id: get(result, 'created_by', '') });
    setMeetingDetails(result);

    const createdByUser = get(result, 'created_by', '') === userId;
    if (createdByUser) {
      setStatus('approved');
      return;
    }

    const waitingRoomEnabled = get(result, 'waitingRoomEnabled', false);
    if (!waitingRoomEnabled) {
      setStatus('approved');
      return;
    }

    const isApproved = getGuestToken('at');
    setStatus(isApproved ? 'approved' : 'rejected');
    if (!isApproved) {
      auth.signOut();
    }
  };

  const getMeetingDetails = async () => {
    try {
      setLoading(true);
      const accessToken = getAccessToken();
      const result = await fetchMeetingDetails(accessToken);
      handleMeetingResponse(result);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAccessToken = () => {
    return get(authValue, 'accessToken', {});
  };

  const fetchMeetingDetails = async (accessToken: string) => {
    const result = await validateMeeting(meeting_doc_id, show_id, accessToken);
    
    if (result.data) {
      const waitingRoomEnabled = get(result, 'data.security.waitingRoomEnabled', false);
      Object.assign(result.data, { waitingRoomEnabled });
    }
    return result.data ? result.data : result;
  };

  const navigateToIntermediate = () => {
    navigateToPage('/intermediate', {
      state: {
        show_id,
        show_name,
        show_code,
        team_admin,
        zoomFlag,
        user_role_id: participant_show_user_role_id,
        viewerFlag,
      },
    });
  };

  const handleError = async (error: any) => {
    console.error('Error fetching meeting details:', error);
    setStatus('rejected');
    setMeetingDetails(null);
    if (isGuestUser) {
      await leaveConference(false);
      await auth.signOut();
      navigateToPage('/logoutredirect', { state: { isGuest: true }, replace: true });
    } else {
      await leaveConference(false);
      navigateToIntermediate();
    }
  };

  useEffect(() => {
    // if (meeting_id) {
      getMeetingDetails();
    // }
  }, [meeting_doc_id, meeting_id]);

  return { loading, status, meetingDetails };
};
