// DeviceTestResultModal/DeviceTestResult.tsx
import React, { useContext } from 'react';
import { Button } from 'antd';
import './styles.css';
import useCameraAndMicSwitcher from '../../Hooks/useCameraAndMicSwitcher';
import ZoomMediaContext from '../../context/media-context';

const DeviceTestResult: React.FC<{ onClose: any , selectedSpeaker: any, selectedMicroPhone: any }> = ({ onClose, selectedSpeaker, selectedMicroPhone }) => {
  const { checkAndSwitchActiveDevices } = useCameraAndMicSwitcher();
  const { mediaStream } = useContext(ZoomMediaContext) || { mediaStream: null };
  const handleConfirmDevice = () => {
    if (!!mediaStream) {
      checkAndSwitchActiveDevices(mediaStream);
    }
    onClose();
  };
  return (
    <div className="zoom-meeting device-test-result-container">
      <h3 className="head">Your device is working properly!</h3>
      <div className="result-item-speaker">
        <h3 className="result-title">Speaker</h3>
        <p className="success-style">
          {selectedSpeaker}<span className="check-mark">✔</span>
        </p>
      </div>
      <div className="result-item">
        <h3 className="result-title">Microphone</h3>
        <p className="success-style">
          {selectedMicroPhone} <span className="check-mark">✔</span>
        </p>
      </div>
      <Button className="end-button" onClick={handleConfirmDevice}>End Test</Button>
    </div>
  );
};

export default DeviceTestResult;