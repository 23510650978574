import _ from 'lodash';
import { get } from './utils';

type Changes = {
  [key: string]: 'Deleted' | 'Added' | { from: any; to: any };
};

type DifferenceSummary = {
  userId: string;
  isVideoChanged: boolean;
  videoState: boolean;
  muteState: boolean;
  userIdentity: string;
  displayName:string;
};

export function difference(obj1: object, obj2: object): DifferenceSummary {
  const changes: Changes = {};
  const differences: DifferenceSummary = {
    userId: '',
    isVideoChanged: false,
    videoState: false,
    muteState: false,
    userIdentity: '',
    displayName:''
  };

  function compare(obj1: object, obj2: object, changes: Changes, path: string[] = []): void {
    for (const key in obj1) {
      const currentPath = path.concat(key);
      const value1 = (obj1 as any)[key];
      const value2 = (obj2 as any)[key];

      if (_.isEqual(value1, value2)) continue;

      if (!(key in obj2)) {
        changes[currentPath.join('.')] = 'Deleted';
      } else if (typeof value1 === 'object' && typeof value2 === 'object') {
        compare(value1, value2, changes, currentPath);
      } else {
        differences.userId = get(path, '0', '');

        if (key === 'bVideoOn') {
          differences.isVideoChanged = true;
          differences.videoState = get(obj2, 'bVideoOn', false);
        }
        differences.muteState = get(obj2, 'muted', false);
        differences.userIdentity = get(obj2, 'userIdentity', '');
        differences.displayName = get(obj2,'displayName','')

        changes[currentPath.join('.')] = { from: value1, to: value2 };
      }
    }

    for (const key in obj2) {
      if (!(key in obj1)) {
        const currentPath = path.concat(key);
        changes[currentPath.join('.')] = 'Added';
      }
    }
  }

  compare(obj1, obj2, changes);
  return differences;
}
