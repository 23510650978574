import { Stream } from '@zoom/videosdk';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { get } from '../Helpers/utils';
import { AUTH_ROUTES } from '../../App/AllRoutes';
import ZoomVideo from "@zoom/videosdk";

const useCameraAndMicSwitcher = () => {
  const location = useLocation();

  // const switchToactiveCameraandMic = useCallback(async (mediastream: typeof Stream) => {
  //   const micId = sessionStorage.getItem('mic');
  //   const speakerId = sessionStorage.getItem('speaker');

  //   if (speakerId) {
  //     await mediastream.switchSpeaker(speakerId);
  //   }
  //   if (micId) {
  //     await mediastream.switchMicrophone(micId);
  //   }
  // }, []);

  const fetchDevices = useCallback(async (kind: string) => {
    const devices = await ZoomVideo.getDevices();
    return devices.filter(device => device.kind === kind);
  }, []);

  const fetchSelectedDevice = useCallback(async (deviceId: string, kind: string) => {
    const devices = await fetchDevices(kind);
    return devices.find(device => device.deviceId === deviceId) || null;
  }, [fetchDevices]);

  const fetchSelectedMicrophones = useCallback(async (micId: string) => {
    const matchedMic = await fetchSelectedDevice(micId, "audioinput");
    if (matchedMic) {
      const sameGroupMics = await fetchDevices("audioinput").then(mics => 
        mics.filter(mic => mic.groupId === matchedMic.groupId)
      );
      return sameGroupMics.length > 0 
        ? sameGroupMics.reduce((prev, current) => (prev.deviceId.length > current.deviceId.length) ? prev : current) 
        : matchedMic;
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSelectedDevice]);

  const fetchSelectedSpeakers = useCallback(async (speakerId: string) => {
    const matchedSpeaker = await fetchSelectedDevice(speakerId, "audiooutput");
    if (matchedSpeaker) {
      const sameGroupSpeakers = await fetchDevices("audiooutput").then(speakers => 
        speakers.filter(speaker => speaker.groupId === matchedSpeaker.groupId)
      );
      return sameGroupSpeakers.length > 0 
        ? sameGroupSpeakers.reduce((prev, current) => (prev.deviceId.length > current.deviceId.length) ? prev : current) 
        : matchedSpeaker;
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSelectedDevice]);

  const checkAndSwitchActiveDevices = useCallback(async (mediastream: typeof Stream) => {
    const micId = sessionStorage.getItem('mic') || '';
    const speakerId = sessionStorage.getItem('speaker') || '';

    if (get(location, 'pathname', '') === AUTH_ROUTES.zoommeeting) {
      const [activeMic, activeSpeaker, selectedMic, selectedSpeaker] = await Promise.all([
        mediastream.getActiveMicrophone(),
        mediastream.getActiveSpeaker(),
        fetchSelectedMicrophones(micId),
        fetchSelectedSpeakers(speakerId)
      ]);

      if (activeMic !== selectedMic?.deviceId && selectedMic) {
        await mediastream.switchMicrophone(selectedMic.deviceId);
      }
      if (activeSpeaker !== selectedSpeaker?.deviceId && selectedSpeaker) {
        await mediastream.switchSpeaker(selectedSpeaker.deviceId);
      }
    }
  }, [fetchSelectedMicrophones, fetchSelectedSpeakers, location]);

  return { checkAndSwitchActiveDevices };
};

export default useCameraAndMicSwitcher;
