import { Checkbox, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import './styles.css';
import api from '../../../Service/Api';
import { useLocation } from 'react-router';
import backend from '../../../Service/Backend';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../../../Firebase/firebase';
import useNavigateToPage from '../../../hooks/useNavigateToPage';
import { get } from '../Helpers/utils';
import { AuthContext } from '../../../Context/authContext';
import { LoadingOutlined } from '@ant-design/icons';
import ReCAPTCHA from "react-google-recaptcha";

const MeetingLogin: React.FC = () => {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [passcode, setPasscode] = useState('');
  const [errors, setErrors] = useState({
    fname: '',
    lname: '',
    passcode: '',
    terms: '',
    captcha: ''
  });
  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const loaderIcon = <LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />;
  const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
  
  const location = useLocation();
  const meeting_id = new URLSearchParams(location.search).get('id');
  const session_id = new URLSearchParams(location.search).get('s');
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const navigateToPage = useNavigateToPage();
  const maskStyles = { mask : {backdropFilter: 'blur(25px)'}}

  useEffect(() => {
    if (authValue) {
      auth.signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const url = window.location.href;
    console.log('Current URL:', url);

    const regex = /[?&]p=([^&#]*)/;
    const results = regex.exec(url);
    const password = results ? decodeURIComponent(results[1]) : null;

    if (password) {
      setPasscode(password);
      setIsPasswordDisabled(true);
    } else {
      setIsPasswordDisabled(false);
    }
  }, []);

  const validateForm = () => {
    const newErrors = {
      fname: '',
      lname: '',
      passcode: '',
      terms: '',
      captcha: ''
    };
    let isValid = true;

    if (!fname.trim()) {
      newErrors.fname = 'First name is required';
      isValid = false;
    } else if (fname.trim().length > 20) {
      newErrors.fname = 'First name should not exceed 20 characters';
      isValid = false;
    }

    if (!lname.trim()) {
      newErrors.lname = 'Last name is required';
      isValid = false;
    } else if (lname.trim().length > 20) {
      newErrors.lname = 'Last name should not exceed 20 characters';
      isValid = false;
    }

    if (!isAgreed) {
      newErrors.terms = 'Please agree to the terms and conditions';
      isValid = false;
    }

    if (!captchaVerified) {
      newErrors.captcha = 'Please complete the captcha';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleVerificationSuccess = (value: any) => {
    if (value) {
      setCaptchaVerified(true);
      setErrors(prevErrors => ({ ...prevErrors, captcha: '' }));
    } else {
      setCaptchaVerified(false);
      setErrors(prevErrors => ({ ...prevErrors, captcha: 'Please complete the captcha' }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMessage("");
    setErrors(prevState => {
      let newErrors = { ...prevState };
      if (name === 'fname' || name === 'lname') {
        if (!value.trim()) {
          newErrors[name] = `${name === 'fname' ? 'First' : 'Last'} name is required`;
        } else if (value.trim().length > 20) {
          newErrors[name] = `${name === 'fname' ? 'First' : 'Last'} name should not exceed 20 characters`;
        } else {
          newErrors[name] = '';
        }
      } else if (name === 'passcode') {
        newErrors.passcode = value.trim() === '' ? 'Password is required' : '';
      }
      return newErrors;
    });

    if (name === 'fname') setFname(value);
    if (name === 'lname') setLname(value);
    if (name === 'passcode') setPasscode(value);
  };

  const handleCheckboxChange = (e: any) => {
    setIsAgreed(e.target.checked);
    setErrors(prev => ({ ...prev, terms: e.target.checked ? '' : 'Please agree to the terms and conditions' }));
  };

  const joinMeeting = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setErrorMessage('');
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      const data = {
        api: api.meetings.login,
        payLoad: {
          firstName: fname.trim(),
          lastName: lname.trim(),
          accessCode: passcode,
          meetingId: get(meeting_id?.split('?'), '0', ''),
          sessionId: session_id
        },
      };
      const result = await backend.save(data, '');
      if (result.status === "error") {
        setErrorMessage(result.message || 'An error occurred');
        return;
      }
      if (result.token) {
        const customAuthRes = await signInWithCustomToken(auth, result.token);
        if (customAuthRes) {
          const route = get(result, 'userWaiting', false) ? '/waiting-room' : '/zoom-meeting';
          setTimeout(() => {
            navigateToPage(route, {
              state: {
                meetingId: get(meeting_id?.split('?'), '0', ''),
                wid: get(result, 'waiting_room_id', ''),
                meeting_data: get(result, 'meeting_data', ''),
                meeting_doc_id: get(result, 'meeting_doc_id', ''),
                user_role_id: get(result, 'participant_show_user_role_id', ''),
                session_id:session_id
              },
            });
          }, 2000);
        }
      }
    } catch (error) {
      console.error('Error joining meeting:', error);
      setErrorMessage('An error occurred while joining the meeting');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='meeting-login-container'>
      <div className='meeting-login-div-top'></div>
      <Modal
        open={true}
        footer={null}
        title={null}
        mask={true}
        className='login-modal'
        styles={maskStyles}
        centered={true}
        width={613}
      >
        <div className='login-modal-container'>
          <div className='login-modal-title-text'>Guest Login</div>

          <form autoComplete='off'>
            <div className='login-modal-form'>
              <div className='login-field-container'>
                <div className="input-wrapper">
                  <input
                    type='text'
                    name='fname'
                    placeholder='First Name'
                    className={`login-input-field ${errors.fname ? 'error' : ''}`}
                    value={fname}
                    onChange={handleInputChange}
                    maxLength={20}
                  />
                  {errors.fname && 
                    <div className="error-message">
                      {errors.fname}
                    </div>
                  }
                </div>
                <div className="input-wrapper">
                  <input
                    type='text'
                    name='lname'
                    placeholder='Last Name'
                    className={`login-input-field ${errors.lname ? 'error' : ''}`}
                    value={lname}
                    onChange={handleInputChange}
                    maxLength={20}
                  />
                  {errors.lname && 
                    <div className="error-message">
                      {errors.lname}
                    </div>
                  }
                </div>
              </div>
              {!!passcode && <div className='login-field-container'>
                <div className="input-wrapper" style={{width: '100%'}}>
                  <input
                    type='password'
                    name='passcode'
                    className={`login-input-field ${errors.passcode ? 'error' : ''}`}
                    placeholder='Password'
                    autoComplete="new-password"
                    value={passcode}
                    onChange={handleInputChange}
                    disabled={isPasswordDisabled}
                  />
                  {errors.passcode && 
                    <div className="error-message">
                      {errors.passcode}
                    </div>
                  }
                  {errorMessage && <div className='error-message terms-error'>{errorMessage}</div>}
                </div>
              </div>}
              {/* <div className='login-captcha-container'> */}
              <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                  onChange={handleVerificationSuccess}
                />
                {errors.captcha && <div className="captcha-error-message">{errors.captcha}</div>}
              {/* </div> */}
              <div className='login-field-container'>
                <button 
                  type='submit' 
                  className='meeting-login-btn' 
                  onClick={joinMeeting}
                  disabled={
                    isLoading || 
                    !fname.trim() || 
                    !lname.trim() || 
                    !isAgreed || 
                    !captchaVerified
                  }
                >
                  {isLoading ? loaderIcon : 'Join Meeting'}
                </button>
              </div>
              <div className='login-form-divider'></div>
            </div>
          </form>
          <div className='terms-container'>
            <div className={`checkbox-container ${errors.terms ? 'error' : ''}`}>
              <Checkbox 
                name='agree'
                checked={isAgreed}
                onChange={handleCheckboxChange}
              /> 
              <span> I Agree </span>
            </div>
            {errors.terms && 
              <div className="error-message terms-error">
                {errors.terms}
              </div>
            }
            <label className='login-terms-conditions'>
              By creating an account or continuing to use a PacPost Live you acknowledge and agree
              that you have accepted the <b>Terms of Service</b> and reviewed the{' '}
              <b>Privacy Policy</b>
            </label>
          </div>
        </div>
      </Modal>
      <div className='meeting-login-div-bottom' style={{ bottom: '0px' }}></div>
    </div>
  );
};

export default MeetingLogin;