import React, { useContext, useEffect, useState } from 'react';
import { notification, Spin } from 'antd';
// import CreateMeeting from './CreateMeetingForm';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import { get } from '../../Helpers/utils';
import { AuthContext } from '../../../../Context/authContext';
import { useLocationStore } from '../../../../store/useLocationStore';
import useNavigateToPage from '../../../../hooks/useNavigateToPage';
import './styles.css';
import PlusIcon from '../../Images/Plus.png';
import PlusHighlightIcon from '../../Images/plus-highlight.png';
import CalendarIcon from '../../Images/Calender.png';
import CalendarHighlightIcon from '../../Images/calender-highlight.png';
// import Stream from '../../Images/stream.png';
// import ScheduleMeeting from '../../MeetingPopUp/ScheduleMeeting';
import MeetingPopUp from '../../MeetingPopUp';
import Meeting from '../../Images/stream.svg';
import MeetingDetails from './MeetingDetails';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  isPopupVisible: boolean;
  setIsPopupVisible: (val:boolean) => void;
}

const ListOfMeetings: React.FC<IProps> = (props) => {
  const [meetings, setMeetings] = useState<any[]>([]);
  const [meetingDetails, setMeetingDetails] = useState<any>({
    pmi: '',
    default_pin: '',
    waiting_room_enabled: false,
    meeting_link: '',
  });
  // ... existing code ...
  const [isScheduleMeetingModalVisible, setIsScheduleMeetingModalVisible] =
    useState<boolean>(false);
  const [isCreateMeetingModalVisible, setIsCreateMeetingModalVisible] = useState<boolean>(false);
  const locationState = useLocationStore((state) => state.locationState);
  const show_id = get(locationState, 'state.show_id', '');
  const user_role_id = get(locationState, 'state.user_role_id', '');
  // using locationstate if it exists first
  // const storedUserRoleId = user_role_id;
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const navigateToPage = useNavigateToPage();
  const [isMeetingListLoading, setIsMeetingListLoading] = useState<boolean>(false);
  // const [searchQuery, setSearchQuery] = useState<string>('');

  const [isEditMeetingFlow, setIsEditMeetingFlow] = useState<boolean>(false);
  const [editMeetingData, setEditMeetingData] = useState(null);
  const [zoomScheduleMeetingFeature, setZoomScheduleMeetingFeature] = useState<boolean>(false);
  useEffect(() => {
    if (user_role_id) {
      fetchMeetings();
      fetchZoomScheduleMeetingFeature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_role_id]);
  const loaderIcon = <LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />;

  const fetchMeetings = async () => {
    const result = await listAllMeetings();
    if (result) {
      const filteredMeetings = get(result, 'meetings', []).filter(
        (meeting: any) => meeting.status !== 'deleted',
      );
      setMeetings(filteredMeetings);
      setMeetingDetails({
        pmi: get(result, 'meetingDetails.pmi', ''),
        default_pin: get(result, 'meetingDetails.default_pin', ''),
        waiting_room_enabled: get(result, 'meetingDetails.waiting_room_enabled', ''),
        meeting_link: get(result, 'meetingDetails.meeting_link', ''),
      });
    }
  };
  const fetchZoomScheduleMeetingFeature = async () => {
    const featureData = {
      api: api.featureFlags.listFeatureFlags
    };
    const flags = await backend.fetch(featureData, get(authValue, 'accessToken', ''));

    const zoomScheduleMeetingFeature = get(get(flags, 'ZOOM_SCHEDULE_MEETING', ''), 'feature_enable', false);
    setZoomScheduleMeetingFeature(zoomScheduleMeetingFeature);
  };

  const listAllMeetings = async () => {
    setIsMeetingListLoading(true);

   
    try {
      const data = {
        api: api.meetings.getUserFutureMeetings,
        queryParam: { show_id: show_id, show_user_role_id: user_role_id },
      };

      const result: any[] = await backend.fetch(data, get(authValue, 'accessToken', {}));
      return result;
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsMeetingListLoading(false);
    }
  };

  const createMeetings = async (formData: any, unscheduled?: boolean) => {
    try {
      const data = {
        api: api.meetings.create,
        payLoad: JSON.stringify(formData),
      };

      const result: any[] = await backend.save(data, get(authValue, 'accessToken', {}));
      if (result) {
        if (unscheduled) {
          navigateToPage('/zoom-meeting', {
            state: {
              // meetingId: meetingId,
              // meetingLink: meetingLink,
              show_id: show_id,
              user_role_id: user_role_id,
            },
          });
        }
        await fetchMeetings();
        setIsScheduleMeetingModalVisible(false);
      }
      return result;
    } catch (err: any) {
      console.error(err);
      notification.error({
        message: `${err.errMessage}`,
      });
    }
  };

  const closeEditMeetingModal = () => {
    setIsEditMeetingFlow(false);
    setEditMeetingData(null);
  };

  const updateMeeting = async (payload: any, editMeetingData: any) => {
    try {
      const accessToken = get(authValue, 'accessToken', '');
      const data = {
        api: api.meetings.updateMeeting,
        payLoad: {
          meetingId: editMeetingData.data.dynamic_meeting_id,
          show_id: show_id,
          value: payload,
          session_id: editMeetingData.data.session_id,
        },
      };
      const response = await backend.save(data, accessToken);

      if (response) {
        setIsScheduleMeetingModalVisible(false);
      } else {
        console.warn('API call returned an error:', response);
      }
      await fetchMeetings();
      setIsEditMeetingFlow(false);
      setEditMeetingData(null);
      return response;
    } catch (error) {
      console.error('Error updating meeting:', error);
    } 
  };

  const toggleScheduleMeetingModalVisibility = () => {
    setIsScheduleMeetingModalVisible((prev) => !prev);
  };

  const toggleCreateMeetingModalVisibility = () => {
    setIsCreateMeetingModalVisible((prev) => !prev);
  };

  // const filteredMeetings = meetings.filter((meeting) =>
  //   meeting.data.title.toLowerCase().includes(searchQuery.toLowerCase()),
  // );
  const triggerEditMeeting = (meeting: any) => {
    setIsEditMeetingFlow(true);
    setEditMeetingData(meeting);
  };

  return (
    <div className='meeting-list'>
      {/* <div className='search-container'>
        <div className='search-icon'>
          <SearchOutlined className='search-icon-style' />
        </div>
        <div className='zoom-meeting-search'>
          <input
            className='zoom-meeting-search-box'
            type='text'
            placeholder='Search meetings...'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div> */}
      {/* {isMeetingListLoading ? (
        <div className='meeting-loader-container'>
          <Spin spinning={true} size={'small'} tip={'Loading Meetings...'} indicator={loaderIcon} />
        </div>
      ) : meetings.length === 0 ? (
        // <div className='meeting-list no-meetings'>
        //   <h4>No Meetings Today</h4>
        //   <p>See scheduled meetings here</p>
        // </div>
        <> </>
      ) : (
        <ul className='meetings-container no-bullets'>
          {meetings.map((meeting: any, index: number) => (
            <li key={index} className='meeting-item'>
              <div className='meeting-wrapper'>
                <img src={Meeting} alt='stream icon' className='stream-icon' />
                <span className='meeting-name'>{meeting.data.title}</span>
              </div>
              <MeetingDetails
                startTime={meeting.data.start_time}
                endTime={meeting.data.end_time}
                startDate={meeting.data.start_date}
                endDate={meeting.data.end_date}
                title={meeting.data.title}
                host_id={meeting.data.created_by}
                meetingId={get(meeting, 'data.dynamic_meeting_id', '')}
                meetingLink={get(meeting, 'data.meeting_link', '')}
                password={get(meeting, 'data.security.password', '')}
                waitingRoomStatus={get(meeting, 'data.security.waitingRoomEnabled', '')}
                passwordEnabled={get(meeting, 'data.security.passwordEnabled', '')}
                doc_id={get(meeting, 'id', '')}
                isRecurring={get(meeting, 'data.isRecurring', '')}
                recurrence={get(meeting, 'data.recurrence', '')}
                video_config={get(meeting, 'data.video_config', '')}
                audio_config={get(meeting, 'data.audio_config', '')}
                session_id={get(meeting, 'data.session_id', '')}
                // refreshMeetings={fetchMeetings}
                triggerEditMeeting={() => triggerEditMeeting(meeting)}
                isPopupVisible={props.isPopupVisible}
                setIsPopupVisible={props.setIsPopupVisible}
              />
              <div className='meeting-details'>
              <div className='meeting-info'>
                <span className='meeting-name'>{meeting.data.title}</span>
                <span className='meeting-time'>
                  {formatMeetingTime(meeting.data.start_time, meeting.data.end_time)}
                </span>
              </div>
              <button className='join-btn'>Join</button>
            </div>
            </li>
          ))}
        </ul>
      )} */}
      <br />
      <br />

      <div className='meeting-item'>
        <div className='intermediate-new-meeting-btn'>
          <div
            className={`meeting-options-wrapper ${isMeetingListLoading ? 'invisible' : ''}`}
            onClick={toggleCreateMeetingModalVisibility}
          >
            <img
              src={isCreateMeetingModalVisible ? PlusHighlightIcon : PlusIcon}
              alt='New Meeting'
              className='stream-icon'
              width={42}
              height={42}
            />
            <span className='meeting-name'>New Meeting</span>
          </div>
          {zoomScheduleMeetingFeature && (
          <div
            className={`meeting-options-wrapper ${isMeetingListLoading ? 'disabled' : ''}`}
            onClick={toggleScheduleMeetingModalVisibility}
          >
            <img
              src={isScheduleMeetingModalVisible ? CalendarHighlightIcon : CalendarIcon}
              alt='Schedule '
              className='stream-icon'
            />
            <span className='meeting-name'>Schedule Meeting</span>
          </div>)}
        </div>
      </div>
      {/* <ul className='streams-container'>
        {meetings.map((meeting, index) => (
          <li
            key={index}
            style={{
              width: '50px',
              height: '30px',
              backgroundColor: 'blue',
              color: '#fff',
              borderRadius: '50%',
            }}
            onClick={() => {
              navigateToPage('/zoom-meeting', {
                state: {
                  meetingId: get(meeting, 'data.dynamic_meeting_id', ''),
                  meetingLink: get(meeting, 'data.meeting_link', ''),
                },
              });
            }}
          >
            {meeting.data.title}
          </li>
        ))}
      </ul> */}
      {/* <button
        onClick={handleClick}
        style={{ width: '50px', height: '50px', backgroundColor: '#fff', borderRadius: '50%' }}
      >
        Create meeting`
      </button> */}
      <MeetingPopUp
        isMeetingRoomOpen={isScheduleMeetingModalVisible}
        activeModalName={isScheduleMeetingModalVisible ? 'scheduleMeeting' : ''}
        createMeeting={createMeetings}
        closeModal={() => setIsScheduleMeetingModalVisible(false)}
      />
      <MeetingPopUp
        isMeetingRoomOpen={isEditMeetingFlow}
        activeModalName={isEditMeetingFlow ? 'scheduleMeeting' : ''}
        isEditFlow={isEditMeetingFlow}
        editMeetingData={editMeetingData}
        createMeeting={createMeetings}
        closeModal={closeEditMeetingModal}
        updateMeeting={updateMeeting}
      />
      <MeetingPopUp
        isMeetingRoomOpen={isCreateMeetingModalVisible}
        activeModalName={isCreateMeetingModalVisible ? 'createMeeting' : ''}
        createMeeting={createMeetings}
        meetingLink={meetingDetails.meeting_link}
        closeModal={() => setIsCreateMeetingModalVisible(false)}
      />
      {/* <Modal open={isCreateMeetingModalVisible} closable onOk={toggleCreateMeetingModalVisibility}>
        create meeting
        <br />
        {meetingDetails.meeting_link}
      </Modal> */}
    </div>
  );
};

export default ListOfMeetings;
