import React, { useEffect, useRef, useState } from 'react';

const CheckVideo = (props: any) => {
  const { handleNext } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoList, setVideoList] = useState<MediaDeviceInfo[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string>('');
  const basicProfileImg = 'default-profile.png'; // Replace with your image path

  // Fetch available video devices
  const getVideoDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      setVideoList(videoDevices);
      if (videoDevices.length) {
        setSelectedDevice(videoDevices[0].deviceId);
      }
    } catch (error) {
      console.error('Error fetching video devices:', error);
    }
  };

  // Start the video stream based on the selected device
  const startVideoStream = async () => {
    try {
      if (selectedDevice) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: selectedDevice },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      }
    } catch (error) {
      console.error('Error starting video stream:', error);
    }
  };

  const stopVideoStream = () => {
    const stream = videoRef.current?.srcObject as MediaStream;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    getVideoDevices();
  }, []);

  useEffect(() => {
    startVideoStream();
    return () => {
      stopVideoStream();
    };
  }, [selectedDevice]);

  const handleVideoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDevice(event.target.value);
  };

  const handleVideoTest = () => {
    handleNext(3);
  };

  return (
    <div className='check-video-container'>
      <div className='details-container'>
        <h1>Check Your Video</h1>
        <p>
          Move in front of your camera to make sure it's working. If you don't see your video, try
          changing the selected camera. If the camera isn't part of your computer, check your
          settings to make sure your system recognizes it.
        </p>
        <p>
          <b>Does your video look ok?</b>
        </p>
        <button className='theme-button' onClick={handleVideoTest}>
          Yes
        </button>
        <span onClick={handleVideoTest}>Skip for now</span>
      </div>
      <div className='video-preview-container'>
        <div className='video-preview-wrapper'>
          <h3>Video Preview</h3>
          <div className='video-tile-preview'>
            <video
              preload='metadata'
              poster={basicProfileImg}
              ref={videoRef}
              width='200'
              height='200'
              autoPlay
              muted
            ></video>
          </div>
          <div className='video-dropdown'>
            <label>Camera</label>
            <br />
            <select onChange={handleVideoChange} value={selectedDevice}>
              <option hidden defaultChecked>
                --default--
              </option>
              {videoList.map((video) => (
                <option key={video.deviceId} value={video.deviceId}>
                  {video.label || `Camera ${video.deviceId}`}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckVideo;
