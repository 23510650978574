import React, { useState, useRef, useEffect } from 'react';
import { Button, Select } from 'antd';
import ZoomVideo from '@zoom/videosdk';
import SoundLevelBars from '../SoundLevelBars/SoundLevel';
import './styles.css';

const { Option } = Select;


const TestSpeaker: React.FC<{ onNext: () => void, speakerSelect: any }> = ({ onNext, speakerSelect }) =>{
  const [ selectedSpeaker, setSelectedSpeaker ] =  useState<string>("");
  const [speakers, setSpeakers] = useState<any>([]);
  const [speakerTestinProgress, setSpeakerTestinProgress] = useState(false);
  const [audioLevel, setAudioLevel] = useState<number>(0);
  let localAudioTrack: any;
  const localAudioRef = useRef<any | null>(null);
  const [isYesEnabled, setYesEnabled] = useState(false);

  useEffect(() => {
    const fetchSpeakers = async () => {
      let speakerDevices: any;
      await ZoomVideo.getDevices().then((devices) => {
        speakerDevices = devices.filter((device) => {
          return device.kind === "audiooutput";
        });
      });
      setSpeakers(speakerDevices); 
      if (speakerDevices && speakerDevices.length > 0) {
        setSelectedSpeaker(speakerDevices[0].deviceId);
        
        sessionStorage.setItem('speaker', `${speakerDevices[0].deviceId}`)
        speakerSelect(speakerDevices[0].label)
      }
    };

    fetchSpeakers();
  }, []);

  const testSpeaker = (event: any) => {
    const target = event.target as HTMLElement;
    const outputLevelElm = document.querySelector("#speaker-output-level");

    if (!speakerTestinProgress) {
      localAudioTrack = ZoomVideo.createLocalAudioTrack(selectedSpeaker);
      localAudioRef.current = localAudioTrack.testSpeaker({
        speakerId: selectedSpeaker,
        onAnalyseFrequency: (v: any) => {
          setAudioLevel(v)
        },
      });
      setSpeakerTestinProgress(true);
      // target.textContent = 'Stop test';
    } else {
      setSpeakerTestinProgress(false);
      localAudioRef.current.stop();
      setYesEnabled(true);
    }
  };

  const isButtonDisabled = (isYesEnabled: boolean, speakerTestinProgress: boolean): boolean => {
    return !isYesEnabled || speakerTestinProgress;
  };

  const handleSpeakerChange = (value: any, option: any) => {
    setSelectedSpeaker(value);
    sessionStorage.setItem('speaker',`${value}`)
    speakerSelect(option.children);
  };

  const handleNextSpeaker = () => {
    const currentIndex = speakers.findIndex((device:any) => device.deviceId === selectedSpeaker);
    const nextIndex = (currentIndex + 1) % speakers.length; // Loop back to the first speaker if at the end
    const nextSpeaker = speakers[nextIndex];
    setSelectedSpeaker(nextSpeaker.deviceId);
    sessionStorage.setItem('speaker',`${nextSpeaker.deviceId}`)
    speakerSelect(nextSpeaker.label);
  };
  return (
    <div className="zoom-meeting test-speaker-container">
      <h3 className="head-speaker">Do you hear the ringtone?</h3>
      <div className="zoom-meeting test-speaker-wrapper">
        <p className="speaker-heading-test">Select Speaker</p>
        <Select
          onChange={handleSpeakerChange}
          className="options-speaker"
          value={selectedSpeaker}
          popupClassName='black-theme-dropdown'
        >
          {speakers.map((device: any) => (
            <Option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </Option>
          ))}
        </Select>
        <p className="speaker-heading-output">Output Level</p>
        <SoundLevelBars audioLevel={audioLevel} />
        <div className="button-group-speaker">
          <Button onClick={handleNextSpeaker} className="try-another" disabled={speakerTestinProgress}>
            No, Try Another Speaker
          </Button>
          <Button onClick={testSpeaker} className="try-another">
            {speakerTestinProgress ? 'Stop Testing' : 'Test'}
          </Button>
          <Button 
          onClick={onNext} 
          className="yes" 
          disabled={isButtonDisabled(isYesEnabled, speakerTestinProgress)}>Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TestSpeaker;
