import { useState, useCallback, useEffect } from 'react';
import { ZoomClient } from '../types/index-types';

export function useActiveVideo(zmClient: ZoomClient) {
  const [activeSpeaker, setActiveSpeaker] = useState<number>(0);

  // Callback to handle changes in the active speaker
  const onActiveSpeakerChange = useCallback((payload: any) => {
    if (Array.isArray(payload) && payload.length > 0) {
      const { userId } = payload[0];
      console.log(payload);
      setActiveSpeaker(userId);
    }
  }, []);

  useEffect(() => {
    zmClient.on('active-speaker', onActiveSpeakerChange);
    return () => {
      zmClient.off('active-speaker', onActiveSpeakerChange);
    };
  }, [zmClient, onActiveSpeakerChange]);

  // Return the current active speaker's user ID
  return activeSpeaker;
}
