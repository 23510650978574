/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useRef, useContext } from "react";
import { Button, Dropdown } from "semantic-ui-react";

import { get, isEmpty } from "../../../Utils/helpers";
import { auth } from "../../../Firebase";
import { isGuestAuthenticated, logOutGuestUser } from '../../../Utils/guestHelpers';
import ConfirmationalPopup from '../../../Utils/ConfirmationalPopup';


import chatImg from '../../../Images/chat.svg';
import closeImg from '../../../Images/close.svg';
import fitToScreen from '../../../Images/fullScreen.svg';
import transmission from '../../../Images/transmission.svg';
import ham from '../../../Images/hamburger-menu.svg';
import tc_Img from '../../../Images/room-finder1.png';
import changeView from '../../../Images/changeView.svg';
import volume from '../../../Images/newVolume.svg';
import volumeMuteIcon from '../../../Images/volume_mute.svg';
import showDominantSpeakerIcon from '../../../Images/show-dom-speaker.svg';
import hideDominantSpeakerIcon from '../../../Images/hide-dom-speaker.svg';
import dndActiveIcon from '../../../Images/dnd-active-icon.svg';
import dndInactiveIcon from '../../../Images/dnd-inactive-icon.svg';
import watermarkIcon from '../../../Images/watermark_icon.svg';
import pencil from '../../../Images/pencil.svg';
import erase from '../../../Images/eraser.svg';
import phoneFilled from '../../../Images/newPhone_outline.svg';
import trash from '../../../Images/trash.svg';
import group from '../../../Images/People.svg';
import guestWhite from '../../../Images/guest_whitebg.svg';

import Reload from '../Reload';
import ImageCanvas from '../../UserSettings/ImageCanvas';
import { useViewerSlice, useWhiteboardingStore, useChatStore, useShowspeakerStore, useWatermarkStore } from "../../../store";
import { useRedIcon } from "./useRedIcon";
import Devices from "./Devices";
import AudioToggle from "./AudioToggle";
import VideoToggle from "./VideoToggle";
import NoiseCancellation from "./NoiseCancellation";
import GlobalMute from "./GlobalMute";
import HeaderAccountSettings from "./AccountSettings";
import useConferenceCommands from "../hooks/useConferenceCommands";
import InvertLayout from "./InvertLayout";

import ZoomContext from '../context/zoom-context';
import { useLocationStore } from "../../../store/useLocationStore";
import useGuestSessions from "../GuestInvite/useGuestSessions";
import useShareGuestSessions from "../GuestInvite/useShareGuestSessions";
import zoomContext from "../context/zoom-context";
import { Col, Grid, Row, Modal as AntdModal } from "antd";
import { RightOutlined } from "@ant-design/icons";

const HeaderView = (props: any) => {
  const {
    showMultiStreamViewer,
    showDominantSpeaker,
    isVCHost,
    fullScreenVideo,

    // removeGuestUser,
    signOut,
    showDominantSpeakerView,
    showStreamContent,
    handleDND,
    rpstate, setRPState,
    stream,
    // showPopup, setShowPopup,
    // handelVideoConfEndAll,
    // handelVideoConfEnd,
    user_imageurl,
    handleHudStatsDisplayPanel,
    isDND,
    user_chime_preferance,
    updateUserChimePref,
    resizeCanvas,
    isPublisher,
    watermarkFeatureIncluded,
    setWatermarkDetails,
    watermarkDetails,
    encoderFeatureIncluded,
    isTimeCodeFeatureIncluded,
    isGuestInviteIncluded,
    reloadPage,
    reloadSubStreams,
    activeStreamStatus, setActiveStreamStatus,
    showVcIdref,
    volumeMute,
    setVolumeMute
  } = props;

  const {
    isPencilLine,
    isPencilCircle,
    isEraser,
    showDrawingOption,
    setDrawingOption,
    setEraserStateWithRef,
    setPencilCircleStateWithRef,
    setPencilLineStateWithRef,
    resetAllTools
  } = useWhiteboardingStore()

  // const locationState = useLocationStore((state) => state.locationState);
  // const show_id = locationState.state ? locationState.state.show_id : '';
  // const team_admin = locationState.state ? locationState.state.team_admin : false;
  // const isGuestViewer = get(locationState, 'state.isGuestViewer', false);
  const redIcon = useRedIcon();
  const vc_id = showVcIdref.current
  const {showSpeakerViewer} = useShowspeakerStore()
  const zmClient = useContext(zoomContext);
  const { setPreview } = useWatermarkStore();
  const [showPopup, setShowPopup] = useState(false);
  const [timeCodePopup, setTimeCodePopup] = useState(false);
  //Volume show and hide
  const [showVolume, setShowVolume] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(100);

  const [headerIconsModalOpen, setHeaderIconsModalOpen] = useState(false); 
  const [rightTopOptionsOpen, setRightTopOptionsOpen] = useState(false); 

  const [muteAll, setMuteAll] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { rightActivePanel,openEncoderPanel, openChatPanel,openWaterMarkPanel, openGuestPanel, openPublisherPanel, closeRightPanel } =
  useViewerSlice();

  const {pubnub, setPubNub, messages, _setMessages} = useChatStore();
  const isGuestOpen = rightActivePanel === "guest";
  const isChatOpen = rightActivePanel === "chat";
  const isWaterMarkOpen = rightActivePanel === "watermark";
  const isPublisherPanelOpen = rightActivePanel === "publisherpanel";
  const isEncoderOpen = rightActivePanel === "encoder";

  const locationState = useLocationStore((state) => state.locationState);
  const show_id = get(locationState,'state.show_id','');
  
  const {updateGuestSession} = useGuestSessions(show_id);
  const { shareGuestSessions } = useShareGuestSessions(zmClient);
  const {leaveConference,endConference} =useConferenceCommands(zmClient,vc_id,isGuestInviteIncluded,watermarkFeatureIncluded,encoderFeatureIncluded);
  
  const messagesRef = useRef(messages);
  const setMessages = (data: any[]) => {
    messagesRef.current = data;
    _setMessages(data)
  }
  
  const handleLeaveConference = async () =>{
    leaveChat();
    closeRightPanel();
    leaveConference();
    setPreview(false);
    resetDrawingButtonState();
  }

  const handleEndConference = () =>{
    leaveChat();
    closeRightPanel();
    endConference();
    setPreview(false);
    resetDrawingButtonState();
  }

  const leaveChat = () => {
    let stream_ID = sessionStorage.getItem('stream_id') || ''
    console.log(stream_ID,'stream_id')
    let stream_channels = stream_ID ? [stream_ID] : [];
    // Disconnect from chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: stream_channels,
      });

      const msgs = messagesRef.current;
       msgs.length = 0;
      _setMessages(msgs);

    }
  };
  // pencil toggle function
  const pencilToggled = async () => {
    console.log('isPencilLine', isPencilLine)
    setEraserStateWithRef(false);
    setPencilCircleStateWithRef(false);
    setPencilLineStateWithRef(!isPencilLine);
    setDrawingOption(!showDrawingOption);
  };

  //circle toggle function
  const circleToggled = async () => {
    setEraserStateWithRef(false);
    setPencilCircleStateWithRef(!isPencilCircle);
    setPencilLineStateWithRef(false);
    setDrawingOption(!showDrawingOption);
  };
  
  // eraser toggle function
  const eraserToggled = async () => {
    setEraserStateWithRef(!isEraser);
    setPencilCircleStateWithRef(false);
    setPencilLineStateWithRef(false);
  };

  // Clear WhiteBoard for all
  const clearWhiteBoardAll = async () => {
    resetDrawingButtonState();
    const canvas: any = document.getElementById("canvas");
    if (canvas) {
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }
    // also send the clear function to others in the same zoom room
    const clear = true;
    const commandChannel = zmClient?.getCommandClient();
    commandChannel?.send(JSON.stringify({
      clear
    }))
  };

  const resetDrawingButtonState = () => {
    setEraserStateWithRef(false);
    setPencilCircleStateWithRef(false);
    setPencilLineStateWithRef(false);
  }

  const removeGuestUser = async () => {
    const currentUser = zmClient.getCurrentUserInfo();
    const gidentity = get(currentUser,'userIdentity','')
    let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
    allowedGuestSession =
      typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession;
    if (!!allowedGuestSession) {
      // Remove the guest user from session
      //console.log("remove guest2",gidentity)
      delete allowedGuestSession[gidentity];
      sessionStorage.setItem(
        'allowedGuest',
        typeof allowedGuestSession === 'string'
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession),
      );
      shareGuestSessions(allowedGuestSession)
    }
    signOutGl();
  };

const signOutGl = async () => {
    let allowedGuestSession = sessionStorage.getItem('allowedGuest');
    await updateGuestSession(
      typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession,vc_id
    );
    sessionStorage.removeItem('allowedGuest');
    // handleCloseWatermarkPanelWindow();
    logOutGuestUser();
    //to do add end conf
    handleLeaveConference();
  };

  const handleWatermarkPanelWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      watermarkPanel: true,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      encoder: false
    }));

    resizeCanvas();
  };

  const handleCloseWatermarkPanelWindow = async () => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationChatDisable: true,
      annotationCloseDisable: false,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      watermarkPanel: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      encoder: false
    }));

    resizeCanvas();
  };

  const handlePublisherPanelWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: true,
      showPublisherPanelCloseDisable: true,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
    setWatermarkDetails(watermarkDetails);
  };
  const handleClosePublisherPanelWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
  };

  const handleGuestInviteWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: true,
      showGuestInviteCloseDisable: true,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
    setWatermarkDetails(watermarkDetails);
  };
  const handleGuestInviteCloseWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
  };

  const handleChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: true,
      closeDisable: true,
      chatDisable: false,
      users: false,
      closeUsers: false,
      openUsers: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
    setWatermarkDetails(watermarkDetails);
  };

  const handleCloseChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
  };

  const handleAnnotationChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    let showTimeCodePopup = sessionStorage.getItem('showTimeCodePopup');
    if (!showTimeCodePopup) {
      sessionStorage.setItem('showTimeCodePopup', 'yes');
      setTimeCodePopup(true);
    }
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: true,
      annotationCloseDisable: true,
      annotationChatDisable: false,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
    resetDrawingButtonState();
  };

  const handleAnnotationCloseChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      watermarkPanel: false,
      encoder: false
    }));

    resizeCanvas();
    resetDrawingButtonState();
  };

  const handleEncoderWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      users: false,
      closeUsers: false,
      openUsers: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: true
    }));

    resizeCanvas();
  };

  const handleCloseEncoderWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState: any) => ({
      ...prevState,
      encoder: false
    }));
    resizeCanvas();
  };

  const onTimecodePopupConfirm = async () => {
    setTimeCodePopup(false);
  };

  const volumeUpDown = async (event: any) => {
    const elem: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
    if (elem) {
      const value = event.currentTarget.value;
      setVolumeLevel(value);
      elem.volume = value / 100;
      if (elem.volume === 0) {
        setVolumeMute(true);
      } else {
        setVolumeMute(false);
      }
    }
    resetDrawingButtonState();
  };

  const handleVolumeControlMouseOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!showVolume) {
      setShowVolume(true);
    }
  };

  const handleVolumeMouseOut = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!showVolume) {
      setShowVolume(false);
    }
  };

  const handleVolumeDoubleClick = () => {
    setShowVolume(false);
    resetAllTools();
    const elem: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
    elem.muted = !elem.muted;
    setVolumeMute(elem.muted);
    resetDrawingButtonState();
    // if (!elem.muted) {
    //   setShowVolume(true);
    // }
  };

  const renderConferenceSelection = (member?: string) => {
    const toggleTools = () => {
      setShowPopup(!showPopup);
      resetAllTools();
    };
    const isGuest = isGuestAuthenticated();
    return (
  <div className="top-menu-button-item call-options-dropdown" onClick={toggleTools}>
  
  <img src={phoneFilled} alt='' />
        <Dropdown icon='' open={showPopup} onClose={toggleTools}>
          <Dropdown.Menu className='cameraMenu'>
            {!member && (
              <Dropdown.Item>
                <Button
                  className='ui button'
                  onClick={handleEndConference}
                  style={{ backgroundColor: 'white' }}
                >
                  End Conference for All
                </Button>
              </Dropdown.Item>
            )}
            <Dropdown.Item>
              <Button
                className='ui button'
                onClick={isGuest ? removeGuestUser : handleLeaveConference}
                style={{ backgroundColor: 'white', width: '183px' }}
              >
                Leave Conference
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span>Tools</span>
      </div>
    );
  };

  const renderDrawingOptions = (showDomSpeaker: boolean) => {
    if (showMultiStreamViewer) {
      return;
    }
    return (
      <>
      <Col>
        <div className={get(screens, 'xs', false) ? 'miniheader-btn' :`top-menu-button-item pencil-tool-dropdown ${showSpeakerViewer ? 'disableddiv' : ''}`} onClick={() => setDrawingOption(!showDrawingOption)}>
          <img src={pencil} alt='' />
          {/* <i aria-hidden="true" className="dropdown icon" style={{ marginTop: "3px", marginLeft: "5px" }}></i> */}
          <Dropdown icon='' open={showDrawingOption} onClose={() => setDrawingOption(!showDrawingOption)}>
            <Dropdown.Menu className='cameraMenu'>
              <Dropdown.Item>
                <div className={showDominantSpeaker ? 'disableddiv' : ''}>
                  <Button
                    className='ui button'
                    onClick={pencilToggled}
                    style={{
                      backgroundColor: isPencilLine ? 'grey' : 'white',
                      paddingLeft: '70px',
                      paddingRight: '70px',
                    }}
                  >
                    Pencil
                  </Button>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className={showDominantSpeaker ? 'disableddiv' : ''}>
                  <Button
                    className='ui button'
                    onClick={circleToggled}
                    style={{
                      backgroundColor: isPencilCircle ? 'grey' : 'white',
                      paddingLeft: '70px',
                      paddingRight: '70px',
                    }}
                  >
                    Circle
                  </Button>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        </Col>
        <Col>
        <div
          className={get(screens, 'xs', false) ? 'miniheader-btn' : `top-menu-button-item ${showSpeakerViewer ? 'disableddiv' : isEraser ? 'eraser-on' : ''
            }`}
          onClick={eraserToggled}
        >
          <img src={erase} alt='' />
          {!get(screens, 'xs', false) &&<span>Erase</span>}
        </div>
        </Col>
        <Col>
        <div
          onClick={clearWhiteBoardAll}
          className={get(screens, 'xs', false) ? 'miniheader-btn' :`top-menu-button-item ${showSpeakerViewer ? 'disableddiv' : ''}`}
        >
          <img src={trash} alt='' />
          {!get(screens, 'xs', false) &&<span>Clear</span>}
        </div>
        </Col>
      </>
    );
  };

  const toggleHeaderIcons = () => {
    setHeaderIconsModalOpen(prev => !prev); // Function to open the modal
  };

 

// New function to toggle right top options
const toggleRightTopOptions = () => {
  setRightTopOptionsOpen(prev => !prev); // Function to toggle right top options
};

  const renderHeaderTopVCControls = () => {
    const showDomSpeaker = showDominantSpeaker;

    if (get(screens, 'xs', false) && !isEmpty(stream)) {
      return (
        <>
          {!isVCHost && <div>{renderConferenceSelection('member')}</div>}
          {isVCHost && <div>{renderConferenceSelection()}</div>}
          <div
            className={`top-menu-button-item`}
            onClick={toggleHeaderIcons} // Added onClick handler
          >
            <div>
              <RightOutlined style={{ fontSize: 20, color: 'white', paddingTop: '15px' }} />
              {/* <img src={ham} alt='' style={{ filter: 'brightness(1)', paddingTop:'10px' }} /> */}
            </div>
            <span>Toggle Video</span>
          </div>
          <div
            className={`top-menu-button-item`}
            onClick={toggleRightTopOptions} // Added onClick handler
          >
            <div>
              {/* <RightOutlined style={{ fontSize: 20, color: 'white', paddingTop: '10px' }} /> */}
              <img src={ham} alt='' style={{ filter: 'brightness(1)', paddingTop: '20px' }} />
            </div>
            <span>Toggle Video</span>
          </div>
          <div style={{ float: 'right', position: 'fixed', right: '2px' }}>
            <HeaderAccountSettings
              isVCHost={isVCHost}
              user_imageurl={user_imageurl}
              showMultiStreamViewer={showMultiStreamViewer}
              handleHudStatsDisplayPanel={handleHudStatsDisplayPanel}
              user_chime_preferance={user_chime_preferance}
              updateUserChimePref={updateUserChimePref}
              handleDND={handleDND}
              handleLeaveConference={handleLeaveConference}
              isDND={isDND}
              showDominantSpeaker={showDominantSpeaker}
              showDominantSpeakerView={showDominantSpeakerView}
              showStreamContent={showStreamContent}
              signOut={signOut}
              removeGuestUser={removeGuestUser}
              vc_id={vc_id}
            />
          </div>
          <AntdModal
            open={headerIconsModalOpen && get(screens, 'xs', true)} // State to control modal visibility
            // onClose={() => setHeaderIconsModalOpen(false)} // Close modal function
            className='miniheader-modal'
            bodyStyle={{ top: 100, backgroundColor: '#000' }}
            footer={null}
            onOk={() => {
              setHeaderIconsModalOpen(false);
            }}
            onCancel={() => {
              setHeaderIconsModalOpen(false);
            }}
          >
            <div className='miniheader-modal-wrapper'>
              <div className='miniheader-modal-row'>
                <div className='miniheader-btn'>
                  <Reload
                    showDomSpeaker={showSpeakerViewer}
                    reloadPage={reloadPage}
                    activeStreamStatus={activeStreamStatus}
                    setActiveStreamStatus={setActiveStreamStatus}
                    isMultiStreamEnabled={showMultiStreamViewer}
                    reloadSubStreams={reloadSubStreams}
                    resetDrawingButtonState={resetDrawingButtonState}
                    miniscreen={true}
                    zmClient={zmClient}
                  />
                </div>
                {renderDrawingOptions(showDomSpeaker)}
                <div
                  style={{
                    position: 'relative',
                    // width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: !!showVolume ? '100%' : '100%',
                  }}
                  className='miniheader-btn'
                  onMouseLeave={handleVolumeMouseOut}
                  onMouseEnter={handleVolumeControlMouseOver}
                >
                  <img
                    onClick={handleVolumeDoubleClick}
                    className='fullscreen-img'
                    src={!!volumeMute ? volumeMuteIcon : volume}
                    alt=''
                    style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
                  />
                  {!!showVolume ? (
                    <div className='volume-control'>
                      <input
                        type='range'
                        min='0'
                        max='100'
                        step='1'
                        value={volumeLevel}
                        style={{
                          background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                        }}
                        onInput={(event) => volumeUpDown(event)}
                        onChange={(event) => volumeUpDown(event)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className='miniheader-modal-row'>
                <AudioToggle
                  showDominantSpeaker={false}
                  resetDrawingButtonState={resetDrawingButtonState}
                />
                <VideoToggle
                  showDominantSpeaker={false}
                  resetDrawingButtonState={resetDrawingButtonState}
                />
                <Devices
                  showDominantSpeaker={false}
                  resetDrawingButtonState={resetDrawingButtonState}
                />
                <InvertLayout resetDrawingButtonState={resetDrawingButtonState} />
                <div onClick={fullScreenVideo} className={'miniheader-btn'}>
                  <img className='fullscreen-img' src={fitToScreen} alt='' />
                </div>
              </div>
            </div>
          </AntdModal>
          <AntdModal
            open={rightTopOptionsOpen} // State to control modal visibility
            // onClose={() => setHeaderIconsModalOpen(false)} // Close modal function
            className='miniheader-modal'
            bodyStyle={{ top: 100, backgroundColor: '#000' }}
            footer={null}
            onOk={() => {
              setRightTopOptionsOpen(false);
            }}
            onCancel={() => {
              setRightTopOptionsOpen(false);
            }}
          >
            <div className='miniheader-modal-row'>
            {renderMiniRightNavControls()}
            </div>
          </AntdModal>
        </>
      );
    }

    // Member
    if (!isVCHost) {
      // Show end button if conf is running and user participated
      if (!isEmpty(stream)) {
        return (
          <Row gutter={[10,10]}>
            <Fragment>
              <Col>{renderConferenceSelection('member')}</Col>
              <Reload
                showDomSpeaker={showSpeakerViewer}
                reloadPage={reloadPage}
                activeStreamStatus={activeStreamStatus}
                setActiveStreamStatus={setActiveStreamStatus}
                isMultiStreamEnabled={showMultiStreamViewer}
                reloadSubStreams={reloadSubStreams}
                resetDrawingButtonState={resetDrawingButtonState}
                miniscreen={false}
                zmClient={zmClient}
              />
              {renderDrawingOptions(showDomSpeaker)}
              <Col>
              <div
                  className={`top-menu-button-item ${showSpeakerViewer ? 'ui disableddiv' : 'ui'}`}
                  data-position='bottom center'
                >
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      height: !!showVolume ? '100%' : '100%',
                    }}
                    onMouseLeave={handleVolumeMouseOut}
                    onMouseEnter={handleVolumeControlMouseOver}
                  >
                    <img
                      onClick={handleVolumeDoubleClick}
                      className='fullscreen-img'
                      src={!!volumeMute ? volumeMuteIcon : volume}
                      alt=''
                      style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
                    />
                    {!!showVolume ? (
                      <div className='volume-control'>
                        <input
                          type='range'
                          min='0'
                          max='100'
                          step='1'
                          value={volumeLevel}
                          style={{
                            background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                          }}
                          onInput={(event) => volumeUpDown(event)}
                          onChange={(event) => volumeUpDown(event)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <span className={'volumeSpan'}>Volume</span>
                </div>
              </Col>

              <NoiseCancellation resetDrawingButtonState={resetDrawingButtonState} />
              <Col>
                <AudioToggle
                  showDominantSpeaker={false}
                  resetDrawingButtonState={resetDrawingButtonState}
                />
              </Col>
              <Col>
                <VideoToggle
                  showDominantSpeaker={false}
                  resetDrawingButtonState={resetDrawingButtonState}
                />
              </Col>
              <Col>
                <Devices
                  showDominantSpeaker={false}
                  resetDrawingButtonState={resetDrawingButtonState}
                />
              </Col>
              <Col>
              <div onClick={fullScreenVideo} className="top-menu-button-item">
                  <img className='fullscreen-img' src={fitToScreen} alt='' />
                  <span>FullScreen Video</span>
                </div>
              </Col>
              <Col>
                <InvertLayout resetDrawingButtonState={resetDrawingButtonState} />
              </Col>
              {/* <span className="dividervertical">&nbsp;</span> */}
            </Fragment>
          </Row>
        );
      }
    }

    // Admin
    if (isVCHost) {
      // Show end button if conf is running
      if (!isEmpty(stream)) {
        // const sId = {localParticipant.sid}+"_audio";
        return (
          <Row gutter={[10,10]}>
            <Fragment>
              <Col>{renderConferenceSelection()}</Col>
              {/* <span className="dividervertical">&nbsp;</span> */}

              <Reload
                showDomSpeaker={showSpeakerViewer}
                reloadPage={reloadPage}
                activeStreamStatus={activeStreamStatus}
                setActiveStreamStatus={setActiveStreamStatus}
                isMultiStreamEnabled={showMultiStreamViewer}
                reloadSubStreams={reloadSubStreams}
                resetDrawingButtonState={resetDrawingButtonState}
                miniscreen={false}
                zmClient={zmClient}
              />
              {/* <span className="dividervertical">&nbsp;</span> */}
<Col>
              <GlobalMute showDominantSpeaker={false} />
              </Col>
              {renderDrawingOptions(showDomSpeaker)}

              <Col>
              <div
                  className={`top-menu-button-item ${showSpeakerViewer ? 'ui disableddiv' : 'ui'}`}
                  data-position='bottom center'
                >
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      height: !!showVolume ? '100%' : '100%',
                    }}
                    onMouseLeave={handleVolumeMouseOut}
                    onMouseEnter={handleVolumeControlMouseOver}
                  >
                    <img
                      onClick={handleVolumeDoubleClick}
                      className='fullscreen-img'
                      src={!!volumeMute ? volumeMuteIcon : volume}
                      alt=''
                      style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
                    />
                    {!!showVolume ? (
                      <div className='volume-control'>
                        <input
                          type='range'
                          min='0'
                          max='100'
                          step='1'
                          value={volumeLevel}
                          style={{
                            background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                          }}
                          onInput={(event) => volumeUpDown(event)}
                          onChange={(event) => volumeUpDown(event)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <span className={'volumeSpan'}>Volume</span>
                </div>
              </Col>

              <NoiseCancellation resetDrawingButtonState={resetDrawingButtonState} />
              <Col>
              <AudioToggle
                showDominantSpeaker={false}
                resetDrawingButtonState={resetDrawingButtonState}
              /></Col>
              <Col>
              <VideoToggle
                showDominantSpeaker={false}
                resetDrawingButtonState={resetDrawingButtonState}
              /></Col>
              <Col>
              <Devices
                showDominantSpeaker={false}
                resetDrawingButtonState={resetDrawingButtonState}
              /></Col>
              <Col>
              <div onClick={fullScreenVideo} className="top-menu-button-item">
                  <img className='fullscreen-img' src={fitToScreen} alt='' />
                  <span>FullScreen Video</span>
                </div>
              </Col>
              <Col>
              <InvertLayout resetDrawingButtonState={resetDrawingButtonState} />
              </Col>
            </Fragment>
          </Row>
        );
      }
    }
  };

  const renderMiniRightNavControls = () =>{
    return(
      <div className="miniheader-modal-row">
              {isVCHost && isPublisher && encoderFeatureIncluded && (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              {isEncoderOpen ? (
                <a id='chatArea' className='active' onClick={closeRightPanel}>
                  <img className='closeinvite' src={closeImg} alt='' />
                </a>
              ) : (
                <a id='encoder' onClick={openEncoderPanel}>
                  <img className='transmission_img' src={transmission} alt='transmission' />
                </a>
              )}
            </div> {/* End of added parent div */}
          </li>
        )}

        {rpstate.annotationChatDisable && isTimeCodeFeatureIncluded && (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              <a id='annotationChatArea' onClick={handleAnnotationChatWindow}>
                <img className='tc_image' src={tc_Img} alt='TC' />
              </a>
            </div> {/* End of added parent div */}
          </li>
        )}

        {rpstate.annotationCloseDisable && (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              <a id='annotationChatArea' className='active' onClick={handleAnnotationCloseChatWindow}>
                <img className='closeinvite' src={closeImg} alt='' />
              </a>
            </div> {/* End of added parent div */}
          </li>
        )}
        {!isChatOpen && (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              <a id='chatArea' onClick={isChatOpen ? closeRightPanel : openChatPanel}>
                <img className='tc_image' src={chatImg} alt='' />
              </a>
            </div> {/* End of added parent div */}
          </li>
        )}
        {isChatOpen && (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              <a id='chatArea' className='active' onClick={closeRightPanel}>
                <img className='closeinvite' src={closeImg} alt='' />
              </a>
            </div> {/* End of added parent div */}
          </li>
        )}
        {isVCHost && isGuestInviteIncluded &&  (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              <a
                id='chatArea'
                className={isGuestOpen ? "active" : ""}
                onClick={isGuestOpen ? closeRightPanel : openGuestPanel}
              >
                {!redIcon ? (
                  <img
                    className={isGuestOpen ? 'closeinvite' : 'tc_image'}
                    src={isGuestOpen ? closeImg : guestWhite}
                    alt=''
                    style={{width:'25px'}}
                  />
                ) :isGuestOpen ? (
                  <img className={'closeinvite'} src={closeImg} alt='' />
                ) : (
                  <div className={'pulsating-icon'}></div>
                )}
              </a>
            </div> {/* End of added parent div */}
          </li>
        )}
        {isVCHost && (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              <a
                id='publisherPanelArea'
                className={
                  isPublisherPanelOpen ? 'active' : ''
                }
                onClick={
                  !isPublisherPanelOpen
                    ? openPublisherPanel
                    : closeRightPanel
                }
              >
                <img
                  className={isPublisherPanelOpen ? 'closeinvite' : 'tc_image'}
                  src={isPublisherPanelOpen ? closeImg : group}
                  alt=''
                  style={{width:'25px'}}
                />
              </a>
            </div> {/* End of added parent div */}
          </li>
        )}
        {isVCHost && watermarkFeatureIncluded && (
          <li>
            <div className='miniheader-btn'> {/* Added parent div */}
              <a
                id='watermarkPanelArea'
                className={isWaterMarkOpen ? 'active' :  ''}
                onClick={
                  isWaterMarkOpen
                    ? closeRightPanel
                    : openWaterMarkPanel
                }
              >
                <img
                  className={isWaterMarkOpen ? 'closeinvite' : 'tc_image'}
                  src={isWaterMarkOpen ? closeImg : watermarkIcon}
                  alt=''
                  style={{width:'25px'}}
                />
              </a>
            </div> {/* End of added parent div */}
          </li>
        )}
      </div>
    )
  }

  const renderRightNavVCControls = () => {
    if (isEmpty(stream) || get(screens,'xs',false)) {
      return;
    }
    return (
      <Fragment>
        {isVCHost && isPublisher && encoderFeatureIncluded && (
          <li>
            {isEncoderOpen ? (
              <a id='chatArea' className='active' onClick={closeRightPanel}>
                <img className='closeinvite' src={closeImg} alt='' />
              </a>
            ) : (
              <a id='encoder' onClick={openEncoderPanel}>
                <img className='transmission_img' src={transmission} alt='transmission' />
              </a>
            )}
          </li>
        )}

        {rpstate.annotationChatDisable && isTimeCodeFeatureIncluded && (
          <li>
            <a id='annotationChatArea' onClick={handleAnnotationChatWindow}>
              <img className='tc_image' src={tc_Img} alt='TC' />
            </a>
          </li>
        )}

        {rpstate.annotationCloseDisable && (
          <li>
            <a id='annotationChatArea' className='active' onClick={handleAnnotationCloseChatWindow}>
              <img className='closeinvite' src={closeImg} alt='' />
            </a>
          </li>
        )}
        {!isChatOpen && (
          <li>
            <a id='chatArea' onClick={isChatOpen ? closeRightPanel : openChatPanel}>
              <img className='tc_image' src={chatImg} alt='' />
            </a>
          </li>
        )}
        {isChatOpen && (
          <li>
            <a id='chatArea' className='active' onClick={closeRightPanel}>
              <img className='closeinvite' src={closeImg} alt='' />
            </a>
          </li>
        )}
        {isVCHost && isGuestInviteIncluded &&  (
          <li>
            <a
              id='chatArea'
              className={isGuestOpen ? "active" : ""}
              onClick={isGuestOpen ? closeRightPanel : openGuestPanel}
            >
              {!redIcon ? (
                <img
                  className={isGuestOpen ? 'closeinvite' : 'tc_image'}
                  src={isGuestOpen ? closeImg : guestWhite}
                  alt=''
                  style={{width:'25px'}}
                />
              ) :isGuestOpen ? (
                <img className={'closeinvite'} src={closeImg} alt='' />
              ) : (
                <div className={'pulsating-icon'}></div>
              )}
            </a>
          </li>
        )}
        {isVCHost && (
          <li>
            <a
              id='publisherPanelArea'
              className={
                isPublisherPanelOpen ? 'active' :''
              }
              onClick={
                !isPublisherPanelOpen
                  ? openPublisherPanel
                  : closeRightPanel
              }
            >
              <img
                className={isPublisherPanelOpen ? 'closeinvite' : 'tc_image'}
                src={isPublisherPanelOpen ? closeImg : group}
                alt=''
                style={{width:'25px'}}
              />
            </a>
          </li>
        )}
        {isVCHost && watermarkFeatureIncluded && (
          <li>
            <a
              id='watermarkPanelArea'
              className={isWaterMarkOpen ? 'active' :  ''}
              onClick={
                isWaterMarkOpen
                  ? closeRightPanel
                  : openWaterMarkPanel
              }
            >
              <img
                className={isWaterMarkOpen ? 'closeinvite' : 'tc_image'}
                src={isWaterMarkOpen ? closeImg : watermarkIcon}
                alt=''
                style={{width:'25px'}}
              />
            </a>
          </li>
        )}
      </Fragment>
    );
  };

  const renderHeaderRightNavControls = () => {
    if ( get(screens,'xs',false)) {
      return;
    }
    return (
      <ul>
        {/* Header Right Nav VC control */}
        {!showMultiStreamViewer && renderRightNavVCControls()}

        {/* Header Right Account Settings control */}
        <HeaderAccountSettings
          isVCHost={isVCHost}
          user_imageurl={user_imageurl}
          showMultiStreamViewer={showMultiStreamViewer}
          handleHudStatsDisplayPanel={handleHudStatsDisplayPanel}
          user_chime_preferance={user_chime_preferance}
          updateUserChimePref={updateUserChimePref}
          handleDND={handleDND}
          handleLeaveConference={handleLeaveConference}
          isDND={isDND}
          showDominantSpeaker={showDominantSpeaker}
          showDominantSpeakerView={showDominantSpeakerView}
          showStreamContent={showStreamContent}
          signOut={signOut}
          removeGuestUser={removeGuestUser}
          vc_id={vc_id}
          volumeMute={volumeMute}
          setVolumeMute={setVolumeMute}
        />
      </ul>
    );
  };

  return (
    <div className="topbar">
      {/* Top VC Controls*/}
      <div className="centerMenuTop" style={{}}>
        {renderHeaderTopVCControls()}
      </div>

      {/* Right Nav Controls with Settings */}
      <div className="menuTopRight">{renderHeaderRightNavControls()}</div>
    </div>
  );
};

export default HeaderView
