import React, { useContext } from 'react';
import { ReactComponent as FullScreenIcon } from '../images/full-screen-icon.svg';
import { notification } from 'antd';
import zoomContext from '../../context/zoom-context';

interface FullScreenOptionProps {
  layoutMode: string;
}

const FullScreenOption: React.FC<FullScreenOptionProps> = ({ layoutMode }) => {

  const zoomClient = useContext(zoomContext);

  const handleFullScreen = () => {
    if (layoutMode === 'gallery') {
      notification.warning({
        message: 'Full creen option not available in gallery view',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }
    const isCurrentUserSharingScreen: boolean = zoomClient?.getCurrentUserInfo()?.sharerOn;
    if(isCurrentUserSharingScreen){
      notification.warning({
        message: 'Full creen option not available while screen sharing',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }
    const videoScreen = document.getElementById('zoom-meeting-video-screen');
    if (videoScreen) {
      if (videoScreen.requestFullscreen) {
        document.getElementById("zoom-meeting-video-screen")?.classList.add("zoom-meeting-full-screen");
        videoScreen.requestFullscreen();
      }
    }
    // Listen for fullscreen change
    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        document.getElementById("zoom-meeting-video-screen")?.classList.remove("zoom-meeting-full-screen");
      }
    };
    document.addEventListener('fullscreenchange', onFullscreenChange);
    
  };

  return (
    <div onClick={handleFullScreen}>
      <FullScreenIcon />
    </div>
  );
};

export default FullScreenOption;
