import { Participant } from '@zoom/videosdk';
import './styles.css';
import Avatar from './Avatar';
import VideoControls from './VideoControls';
import { useContext } from 'react';
import ZoomContext from '../../../context/zoom-context';

// Define the props interface
interface VideoCellProps {
  user: Participant; // Expecting a Participant object
}

const VideoCell = ({ user }: VideoCellProps) => {
  // Destructure user from props
  const zmClient = useContext(ZoomContext);
  const isLocalUser = user.userId === zmClient.getCurrentUserInfo()?.userId;
  const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;
  const isVideoSwitchedOn: boolean = isLocalUser ? isCurrentUserStartedVideo : user.bVideoOn;
  return (
    <div className='video-tile'>
      <div
        className='player-container'
        id={`player_${user.userIdentity}`}
        style={{ display: isVideoSwitchedOn ? 'block' : 'none' }}
      >
        {' '}
      </div>
      <Avatar displayName={user.displayName} videoOn={isVideoSwitchedOn} />
      <VideoControls user={user} />
    </div>
  );
};

export default VideoCell;
