import './styles.css';

interface AvatarProps {
  displayName: string;
  videoOn: boolean;
}

const ZoomAvatar = (props: AvatarProps) => {
  const { displayName, videoOn } = props;

  return (
    <div
      className={'zoom-avatar'}
      style={{
        background: videoOn ? 'transparent' : '#141414',
        display: videoOn ? 'none' : 'block',
      }}
    >
      {!videoOn && <p className='center-name'>{displayName}</p>}
    </div>
  );
};

export default ZoomAvatar;
