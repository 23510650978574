import React, { useEffect, useContext } from 'react';
import './style.css';
import ZoomMediaContext from '../../context/media-context';
import { Participant } from '@zoom/videosdk';
import zoomContext from '../../context/zoom-context';
import { get } from '../../Helpers/utils';

const ScreenShare: React.FC<{ screenShareUser: Participant | null, activeComponent:string | null }> = ({ screenShareUser,activeComponent }) => {
  const { mediaStream } = useContext(ZoomMediaContext);
  const zmClient = useContext(zoomContext);
  const isLocalUserSharingScreen =
    get(screenShareUser, 'userId', '') === get(zmClient.getCurrentUserInfo(), 'userId', '');


  useEffect(() => {
    const canvasElement = document.getElementById('zoom-meeting-screen-share-canvas') as HTMLCanvasElement;
    if (screenShareUser && canvasElement && !isLocalUserSharingScreen) {
      mediaStream?.startShareView(canvasElement, screenShareUser.userId);
    } else {
      mediaStream?.stopShareView();
    }
  }, [screenShareUser, mediaStream, isLocalUserSharingScreen]);

  return (
    <div
      // className='zoom-meeting-screen-share-container'
      style={{
        height: !!screenShareUser || !!activeComponent ? '100%' : '1px',
        width: !!screenShareUser || !!activeComponent ? '100%' : '1px',
        textAlign:'center'
      }}
    >
      <video
        // className='screen-share-video'
        // width={'100%'}
        // height={'390px'}
        style={{
          height: !!activeComponent && (!screenShareUser || isLocalUserSharingScreen) ? '100%' : '1px',
          width: !!activeComponent && (!screenShareUser || isLocalUserSharingScreen) ? '60%' : '1px',
          // display: !!screenShareUser && isLocalUserSharingScreen ? 'block' : 'none',
        }}
        autoPlay
        playsInline
        id='zoom-meeting-screen-share-video'
      />

      <canvas
        id='zoom-meeting-screen-share-canvas'
        style={{
          height: !!screenShareUser && !isLocalUserSharingScreen ? '100%' : '1px',
          width: !!screenShareUser && !isLocalUserSharingScreen? '53%' : '1px',
          // display: !!screenShareUser && !isLocalUserSharingScreen ? 'block' : 'none',
        }}
      ></canvas>
    </div>
  );
};

export default ScreenShare;
