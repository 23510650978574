import { useContext } from 'react';
import zoomContext from '../context/zoom-context';
import { useActiveVideo } from '../hooks/useActiveVideo';
import useParticipants from '../hooks/useParticipants';
import { Participant } from '@zoom/videosdk';
import { get } from '../../../Utils/helpers';
let currentSpeaker = 0;
const ShowSpeakerViewer = () => {
  const zmClient = useContext(zoomContext);

  const activeSpeaker = useActiveVideo(zmClient);
  const changeInAudio = currentSpeaker !== activeSpeaker;
  const participants = useParticipants();
  const activeSpeakerIdentity = get(
    participants.find((participant: Participant) => participant.userId === activeSpeaker),
    'userIdentity',
    '',
  );
  const activeSpeakerTile = document.getElementById(
    `video-player-container-div${activeSpeakerIdentity}`,
  );
  const activeSpeakercontainer = document.getElementById('show-speaker-container-wrapper');
  if (activeSpeakerTile && changeInAudio) {
    const previousVideo = Array.from(get(activeSpeakercontainer, 'children', ''));

    if (previousVideo.length) {
      previousVideo.forEach((children: any) => {
        activeSpeakercontainer?.removeChild(children);
      });
    }
    
    const videoPlayer = document.getElementById('video-container');
    if (videoPlayer) {
      videoPlayer.style.display = 'none';
    }

    const activeSpeakerTileCopy: any = activeSpeakerTile?.cloneNode(true);
    activeSpeakerTileCopy.style.width = '600px';
    if (get(get(activeSpeakerTileCopy, 'children.0', ''), 'children.0', '')) {
      get(get(activeSpeakerTileCopy, 'children.0', ''), 'children.0', '').id = 'show-speaker-viewer';
    }

    const avatar = activeSpeakerTileCopy.querySelector('.zoom-avatar');
    if (avatar) {
      const nameP = avatar.querySelector('.center-name');
      if (nameP) {
        nameP.style.fontSize = '20px';
        nameP.style.fontWeight = '700'; 
        nameP.style.color = '#fff'; 
      }
    }
    // console.log(get(get(activeSpeakerTileCopy, 'children.0', ''), 'children.0', ''));
    // activeSpeakerTileCopy.id = 'test'
    activeSpeakercontainer?.appendChild(activeSpeakerTileCopy);
   
    currentSpeaker = activeSpeaker;
  }

  return (
    <div>
      <video-player-container>
        <div
          id='show-speaker-container-wrapper'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            height: '100%',
            fontSize: '16px',

          }}
        ></div>
      </video-player-container>
    </div>
  );
};
export default ShowSpeakerViewer;
