import React, { useContext, useState } from 'react';
import { ReactComponent as OptionIcon } from '../../../../Images/option-icon.svg';
import { ReactComponent as BackgroundBlurIcon } from '../../../../Images/background-blur-icon.svg';
import zoomContext from '../../../../context/zoom-context';
import { Popover } from 'antd';
import { clearPreviousVideo, get, styleVideoTile, styleZoomAvatar } from '../../../../Helpers/utils';

interface VideoUserProps {
  userId: number;
  isLocalUser: boolean;
  isVideoSwitchedOn: boolean;
  remoteParticipantVideoON: boolean;
  userIdentity: string;
  displayName: string;
}
const SESSION_KEYS = {
  PINNED_USER: 'pinned-user',
  ACTIVE_BG: 'active-bg',
};

const VideoOptions = ({
  activeSpeaker,
  closePopover,
  userIdentity,
  displayName,
}: {
  activeSpeaker: any;
  closePopover: () => void;
  userIdentity: string;
  displayName: string;
}) => {
  const zmClient = useContext(zoomContext);
  const mediaStream = zmClient.getMediaStream();
  const currentUser = zmClient.getCurrentUserInfo()?.userId;
  const [refreshUI, setRefreshUI] = useState(false);
  const isCurrentUserActiveSpeaker = currentUser === activeSpeaker;
  const pinnedUserId = sessionStorage.getItem(SESSION_KEYS.PINNED_USER) || '';
  const activeBlur = Boolean(sessionStorage.getItem(SESSION_KEYS.ACTIVE_BG) || '');

  const togglePinUser = () => {
    const isPinned = Boolean(pinnedUserId) && activeSpeaker === Number(pinnedUserId);
    sessionStorage.setItem(SESSION_KEYS.PINNED_USER, isPinned ? '' : activeSpeaker.toString());

    const activeSpeakerTile = document.getElementById(`video-player-container-div${userIdentity}`);
    const activeSpeakerContainer = document.getElementById('show-speaker-container-wrapper');
    const activeUserDisplayName = document.getElementById('active-user-display-name');

    if (activeSpeakerContainer) {
      clearPreviousVideo(activeSpeakerContainer);
      if (activeSpeakerTile) {
        const activeSpeakerTileCopy = activeSpeakerTile.cloneNode(true) as HTMLElement;
        const videoTile = activeSpeakerTileCopy.querySelector('.video-tile');
        const videoControls = videoTile?.querySelector('.video-controls');
        videoControls && videoTile?.removeChild(videoControls);

        if (videoTile instanceof HTMLElement) {
          styleVideoTile(videoTile);
          const zoomAvatar = videoTile.querySelector('.zoom-avatar');
          styleZoomAvatar(zoomAvatar as HTMLElement);
        }

        const showSpeakerViewer = get(get(activeSpeakerTileCopy, 'children.0', ''), 'children.0', '');
        if (showSpeakerViewer instanceof HTMLElement) {
          showSpeakerViewer.id = 'show-speaker-viewer';
          styleVideoTile(showSpeakerViewer);
        }

        activeSpeakerContainer?.appendChild(activeSpeakerTileCopy);
        activeUserDisplayName && (activeUserDisplayName.textContent = displayName);
      }
    }
    setRefreshUI(!refreshUI);
    closePopover();
  };

  const handleBlurBg = async () => {
    if (!isCurrentUserActiveSpeaker) return;

    try {
      const newActiveBlur = !activeBlur;
      sessionStorage.setItem(SESSION_KEYS.ACTIVE_BG, newActiveBlur ? 'true' : '');
      await mediaStream?.updateVirtualBackgroundImage(newActiveBlur ? 'blur' : undefined);
    } catch (error) {
      console.error('Error updating virtual background:', error);
    } finally {
      setRefreshUI(!refreshUI);
      closePopover();
    }
  };

  return (
    <div className='video-option-wrapper-tile'>
      <div className='option-item' onClick={togglePinUser}>
        <OptionIcon />
        <span>{pinnedUserId && activeSpeaker === Number(pinnedUserId) ? 'Unpin user' : 'Pin User'}</span>
      </div>

      {isCurrentUserActiveSpeaker && (
        <>
          <hr />
          <div className='option-item' onClick={handleBlurBg}>
            <BackgroundBlurIcon />
            <span>Blur background</span>
          </div>
        </>
      )}
    </div>
  );
};

const Options: React.FC<VideoUserProps> = ({
  userId,
  isLocalUser,
  isVideoSwitchedOn,
  remoteParticipantVideoON,
  userIdentity,
  displayName,
}) => {
  const [visible, setVisible] = useState(false);
  const isVideoOn = isLocalUser ? isVideoSwitchedOn : remoteParticipantVideoON;

  const handlePopoverClick = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      rootClassName='video-option-container-tile'
      placement='topRight'
      content={
        <VideoOptions
          activeSpeaker={userId}
          closePopover={() => setVisible(false)}
          userIdentity={userIdentity}
          displayName={displayName}
        />
      }
      trigger='click'
      open={visible}
    >
      <i
        className={`${isVideoOn ? 'icon virtual-bg-menu' : 'icon fullscreen-off'}`}
        id={`video-${userId}`}
        onClick={handlePopoverClick}
      ></i>
    </Popover>
  );
};

export default Options;
