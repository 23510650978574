import { Stream, VideoPlayer } from '@zoom/videosdk';
import { difference } from '../../Helpers/compareObjects';
import { clearPreviousVideo, get, hideDiv, showDiv, styleVideoTile, styleZoomAvatar } from '../../Helpers/utils';

const handleParticipantUpdates = async (
  updatedParticipants: any[],
  participants: any[],
  mediaStream: typeof Stream | null,
  videoPlayerListRef: Record<string, VideoPlayer>,
  setParticipants: (participants: any[]) => void,
  setSubscribers: (subscribers: number[]) => void,
  setVideoPlayerRef: (userIdentity: string, userVideo: HTMLVideoElement) => void,
) => {
  const updatedParticipantObject = Object.fromEntries(
    updatedParticipants.map((item) => [item.userId, item]),
  );

  const participantObject = Object.fromEntries(participants.map((item) => [item.userId, item]));

  const participantUpdates = difference(participantObject, updatedParticipantObject);
  if (!participantUpdates) return;

  const userId = Number(participantUpdates.userId);
  const userIdentity = participantUpdates.userIdentity;

  const isVideoChange = participantUpdates.isVideoChanged;
  const participantRemoved = participants.length > updatedParticipants.length;

  if (isVideoChange) {
    if (participantUpdates.videoState && mediaStream) {
      const userVideo: any = await mediaStream.attachVideo(userId, 1);
      const videoContainer = document.getElementById(`player_${userIdentity}`);
      if (videoContainer) {
        videoContainer.innerHTML = '';
        videoContainer.appendChild(userVideo);
        setVideoPlayerRef(userIdentity, userVideo);
        setTimeout(() => {
          const pinnedUser = sessionStorage.getItem('pinned-user') || '';
          const activeSpeakerContainer = document.getElementById('show-speaker-container-wrapper');

          if (Number(pinnedUser) === userId && !!activeSpeakerContainer) {
            const activeSpeakerTile = document.getElementById(
              `video-player-container-div${userIdentity}`,
            );
            // const activeUserDisplayName = document.getElementById('active-user-display-name');
            clearPreviousVideo(activeSpeakerContainer);
            // const videoContainerCopy = videoContainer.cloneNode(true) as HTMLElement;
            // const zoomAvatar = activeSpeakerContainer.querySelector('.zoom-avatar') as HTMLElement;
            // showDiv(videoContainerCopy);
            // if(zoomAvatar){
            //   // hideDiv(zoomAvatar)
            // }
            // activeSpeakerContainer.appendChild(videoContainerCopy);
            if (activeSpeakerTile) {
              const activeSpeakerTileCopy = activeSpeakerTile.cloneNode(true) as HTMLElement;
              const videoTile = activeSpeakerTileCopy.querySelector('.video-tile');
              const videoControls = videoTile?.querySelector('.video-controls');
              videoControls && videoTile?.removeChild(videoControls);
              const player_tag = videoTile?.querySelector('.player-container');

              if (player_tag && videoTile) {
                showDiv(player_tag as HTMLElement);
              }
              if (videoTile instanceof HTMLElement) {
                styleVideoTile(videoTile);
              }

              //     if (videoTile instanceof HTMLElement) {
              //       // styleVideoTile(videoTile);
              //       const zoomAvatar = videoTile.querySelector('.zoom-avatar') as HTMLElement;
              //       if(zoomAvatar){
              //         hideDiv(zoomAvatar)
              //       }
              //       videoTile.appendChild(videoContainerCopy);
              //       const player_tag = videoTile?.querySelector('.player-container') as HTMLElement;
              //       if(player_tag){
              //         showDiv(player_tag)
              //       }
              //       // styleZoomAvatar(zoomAvatar as HTMLElement);
              //     }

              //     const showSpeakerViewer = get(get(activeSpeakerTileCopy, 'children.0', ''), 'children.0', '');
              //     if (showSpeakerViewer instanceof HTMLElement) {
              //       showSpeakerViewer.id = 'show-speaker-viewer';
              //       styleVideoTile(showSpeakerViewer);
              //     }

              //     console.log(":::::::",activeSpeakerTileCopy)

              activeSpeakerContainer?.appendChild(activeSpeakerTileCopy);
              //     // activeUserDisplayName && (activeUserDisplayName.textContent = participantUpdates.displayName);
            }
          }
        }, 500);
      }
    } else {
      const videoContainer = document.getElementById(`player_${userIdentity}`);
      if (videoContainer && mediaStream) {
        videoContainer.innerHTML = '';
        await mediaStream.detachVideo(userId);
        delete videoPlayerListRef[userIdentity];
        setTimeout(() => {
          const pinnedUser = sessionStorage.getItem('pinned-user') || '';
          const activeSpeakerContainer = document.getElementById('show-speaker-container-wrapper');

          if (Number(pinnedUser) === userId && !!activeSpeakerContainer) {
            const activeSpeakerTile = document.getElementById(
              `video-player-container-div${userIdentity}`,
            );
            const activeSpeakerContainer = document.getElementById(
              'show-speaker-container-wrapper',
            );
            const activeUserDisplayName = document.getElementById('active-user-display-name');
            clearPreviousVideo(activeSpeakerContainer);
            if (activeSpeakerTile) {
              const activeSpeakerTileCopy = activeSpeakerTile.cloneNode(true) as HTMLElement;
              const videoTile = activeSpeakerTileCopy.querySelector('.video-tile');
              const videoControls = videoTile?.querySelector('.video-controls');
              videoControls && videoTile?.removeChild(videoControls);
              const player_tag = videoTile?.querySelector('.player-container');

              if (player_tag && videoTile) {
                hideDiv(player_tag as HTMLElement);
              }
              if (videoTile instanceof HTMLElement) {
                styleVideoTile(videoTile);
                const zoomAvatar = videoTile.querySelector('.zoom-avatar');
                if (zoomAvatar) {
                  if (!zoomAvatar.querySelector('.center-name')) {
                    const nameTag = document.createElement('p');
                    nameTag.className = 'center-name';
                    nameTag.textContent = participantUpdates.displayName;
                    zoomAvatar.appendChild(nameTag);
                  }
                }
                styleZoomAvatar(zoomAvatar as HTMLElement);
              }

              const showSpeakerViewer = get(
                get(activeSpeakerTileCopy, 'children.0', ''),
                'children.0',
                '',
              );
              if (showSpeakerViewer instanceof HTMLElement) {
                showSpeakerViewer.id = 'show-speaker-viewer';
                styleVideoTile(showSpeakerViewer);
              }

              activeSpeakerContainer?.appendChild(activeSpeakerTileCopy);
              activeUserDisplayName &&
                (activeUserDisplayName.textContent = participantUpdates.displayName);
            }
          }
        }, 500);
      }
    }
  }

  if (participantRemoved) {
    updatedParticipants.forEach((participant) => {
      const videoContainer = document.getElementById(`player_${participant.userIdentity}`);
      if (videoContainer && videoContainer.children.length === 0 && participant?.userIdentity && videoPlayerListRef[participant?.userIdentity]) {
        videoContainer.appendChild(videoPlayerListRef[participant?.userIdentity]);
      }
    });
  }
  setParticipants(updatedParticipants);

  const subscriberArray = updatedParticipants.filter((user) => user.bVideoOn).map((u) => u.userId);
  setSubscribers(subscriberArray);
};

export default handleParticipantUpdates;
