import { Modal } from 'antd';
import './styles.scss';

interface SuccessModalProps {
  onOk: () => void;
  open: boolean;
  data: any;
  okButtonProps?: any;
  className?: string;
  meetingLink?: string;
  meetingTitle?: string;
  createdTime?: string;
  password?: string
}
const SuccessModal = (props: SuccessModalProps) => {
  const styles = { height: 'auto' };
  return (
    <Modal
      className={`success-modal ${props.className}`}
      centered={true}
      style={styles}
      open={props.open}
      onOk={props.onOk}
      okButtonProps={props.okButtonProps}
      footer={[
        <button
          key='ok'
          onClick={props.onOk}
          {...props.okButtonProps}
          className='success-modal-ok-button purple-button'
        >
          OK
        </button>,
      ]}
    >
      <div>
        <h3>Success!</h3>
        <p>{props.data}</p>
        <div className='success-modal-content'>
          {props.meetingTitle && <><span className='success-modal-title'>Meeting Name:</span> {props.meetingTitle}</>}
          {props.meetingLink && <><br />
          <span className='success-modal-title'>Meeting Link:</span> {props.meetingLink}</>}
          {props.password && <><br />
          <span className='success-modal-title'>Password:</span> {props.password}</>}
          {props.createdTime && <><br />
          <span className='success-modal-title'>Created Time:</span> {props.createdTime}</>}
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
