import * as Sentry from '@sentry/react';

import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';

import Header from './Header';
import VideoTray from './VideoTray';
import './styles.css';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import ZoomVideo from '@zoom/videosdk';
import { mediaReducer, mediaShape } from './Helpers/utils';
import FallbackComponent from './FallbackComponent';
import api from '../../Service/Api';
import { get } from '../../Utils/helpers';
import backend from '../../Service/Backend';
import { AuthContext } from '../../Context/authContext';
import Sidebar from './SidePannel';
import { useLocationStore } from '../../store/useLocationStore';
import VideoContainer from './VideoScreen';
import { useMeetingValidation } from './Hooks/useMeetingValidation';
import { Spin } from 'antd';
import { LayoutType } from './types/header-actiontypes';

const ZoomMeeting = () => {
  const zmClient = useMemo(() => ZoomVideo.createClient(), []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mediaStream, setMediaStream] = useState<MediaStream | null | any>(null);

  const locationState = useLocationStore((state) => state.locationState);
  const meetingId = get(locationState, 'state.meetingId', '');
  const audioPrefrence = get(locationState, 'state.meeting_data.isAudioON', false);
  const show_id = get(locationState, 'state.show_id', '');
  const session_id = get(locationState, 'state.session_id', '');
  const meeting_doc_id = get(locationState, 'state.meeting_doc_id', '');
  // const host_id = get(locationState, 'state.host_id', '');
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [activeModal,setActiveModal] = useState('');

  const displayName = get(authValue, 'displayName', '');
  const isGuestUser = get(authValue, 'isMeetingGuest', false);
  const { loading,status,meetingDetails } = useMeetingValidation(meeting_doc_id, show_id, meetingId);
 

  const userId = get(authValue, 'uid', '');
  
  const userRole =
    get(meetingDetails, 'host_id', '') === userId
      ? 'host'
      : isGuestUser
      ? 'guest'
      : 'participant';
  const userIdentity = `${userRole}#${userId}_${displayName}`;
  

  const mediaContext: any = useMemo(
    () => ({ ...mediaState, mediaStream }),
    [mediaState, mediaStream],
  );

  const [activeComponent, setActiveComponent] = useState<string | null>(null);
  const [showChat, setShowChat] = useState<string | null>(null);
  const [showParticipants, setShowParticipants] = useState<string | null>(null);
  const [layoutMode, setLayoutMode] = useState<LayoutType>('');
  const isSidebarOpen = !!(showChat || showParticipants);
  const generateVideoSDKJWT = async () => {
    let host: boolean = userRole === 'host';
    let sessionName = !!session_id ? session_id : meetingId;
    try {
      const data = {
        api: api.vc.generateVideoSDKJWT,
        payLoad: {
          host: host,
          sessionName: sessionName,
          userIdentity: userIdentity,
          name: displayName,
        },
      };

      const token = get(authValue, 'accessToken', {});
      const response = await backend.save(data, token);
      return response;
    } catch (error: any) {
      console.log('generateVideoSDKJWT error:', error);
      return '';
    }
  };

  useEffect(() => {
    const joinMeeting = async () => {
      let webEndpoint: any = 'zoom.us';
      await zmClient.init('en-US', 'Global', {
        webEndpoint,
        patchJsMedia: true,
        enforceMultipleVideos: true,
        enforceVirtualBackground: true,
        stayAwake: true,
        leaveOnPageUnload: true,
      });
      const videoSDKJWT = await generateVideoSDKJWT();
      const sessionName = !!session_id ? session_id : meetingId;
      await zmClient.join(sessionName, videoSDKJWT, displayName, '');
      const stream: any = await zmClient?.getMediaStream();
      setMediaStream(stream);
      setActiveModal('joinAudio');
      setShowParticipants('participants');
      sessionStorage.setItem('sidepanel','true')
      const micId = sessionStorage.getItem('mic');
      const speakerId = sessionStorage.getItem('speaker');
      const audioOptions = { mute: !audioPrefrence };

      // Check if activeMic or speakerId exist before starting audio
      Object.assign(audioOptions, {
        ...(micId ? { microphoneId: micId } : {}),
        ...(speakerId ? { speakerId: speakerId } : {})
      });

      await stream.startAudio(audioOptions);
    };
    if (!loading && status === 'approved') {
      joinMeeting();
    }

    return () => {
      zmClient.leave();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <ZoomContext.Provider value={zmClient}>
      <ZoomMediaContext.Provider value={mediaContext}>
        <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
          <Spin spinning={loading || status !== 'approved'}>
            <div
              className='meeting-room'
              style={{
                position: 'relative',
              }}
            >
              <div>
                <Header
                  setActiveComponent={setActiveComponent}
                  setShowChat={setShowChat}
                  setShowParticipants={setShowParticipants}
                  layoutMode={layoutMode}
                  setLayoutMode={setLayoutMode}
                  activeModal={activeModal}
                  setActiveModal={setActiveModal}
                  userRole={userRole}
                  showParticipants={showParticipants}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns:  '100%',
                  height: 'calc(100% - 80px)',
                  position:'relative'
                }}
                className='zoom-meeting-content'
              >
                <div
                  className='zoom-meeting-content-wrapper'
                  style={{
                    flexDirection: layoutMode === 'invert' ? 'column-reverse' : 'column',
                  }}
                >
                  {/* <div
                  className='zoom-meeting-main-content'
                  style={{
                    backgroundColor: 'black',
                    display: 'flex',
                  }}
                >
                  <div className='zoom-meeting-video-screen' >
                    {activeComponent === 'screenshare' && <ScreenShare />}
                    {activeComponent === 'webcam' && <WebCam />}
                  </div>
                </div> */}
                  <VideoContainer
                    activeComponent={activeComponent}
                    hideScreen={layoutMode === 'gallery'}
                    setActiveComponent={setActiveComponent}
                  />
                  <VideoTray isSidebarOpen={isSidebarOpen}/>
                </div>
                {(!!showChat || !!showParticipants) && (
                  <Sidebar showChat={showChat} showParticipants={showParticipants} />
                )}
              </div>
            </div>
          </Spin>
        </Sentry.ErrorBoundary>
      </ZoomMediaContext.Provider>
    </ZoomContext.Provider>
  );
};
export default ZoomMeeting;
