import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import TestSpeaker from './TestSpeakerModal/TestSpeaker';
import ReplyCheck from './ReplyCheckModal/ReplyCheck';
import DeviceTestResult from './DeviceTestResultModal/DeviceTestResult';
import JoinAudio from './JoinAudioModal/Joinaudio';
import ScheduleMeeting from './ScheduleMeeting';
import CreatMeeting from './CreateMeeting';
import WaitingRoom from './WaitingRoom';
import SuccessModal from './ScheduleMeeting/SuccessModal';


const MeetingPopUp: React.FC<{
  isMeetingRoomOpen: boolean;
  activeModalName?: string;
  createMeeting?: (formData: any) => Promise<any[] | undefined>;
  meetingLink?:string;
  joinAudio?: () => void,
  closeModal?: () => void;
  closeoption?:boolean;
  isEditFlow?: boolean;
  editMeetingData?: any;
  updateMeeting?: any
}> = ({ isMeetingRoomOpen, activeModalName, createMeeting, joinAudio, closeModal, meetingLink,closeoption, isEditFlow = false, editMeetingData, updateMeeting }) => {
  const [activeModal, setActiveModal] = useState<string | null>(activeModalName || null);
  const [selectedMicroPhone, setSelectedMicroPhone] = useState<string | null>(null)
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | null>(null)
  const [storedMeetingLink, setStoredMeetingLink] = useState<string | null>(null);
  const [ successModalProps, setSuccessModalProps ] = useState<any>(null)
    const [successModalVisible, setSuccessModalVisible] = useState(false);

  useEffect(() => {
    const linkFromStorage = sessionStorage.getItem('meetingLink');
    if (linkFromStorage) {
      setStoredMeetingLink(linkFromStorage);
    }
    if (meetingLink) {
      sessionStorage.setItem('meetingLink', meetingLink);
    }
  }, [meetingLink]);

  useEffect(() => {
    if (activeModalName) {
      setActiveModal(activeModalName);
    } else {
      setActiveModal('');
    }
  }, [isMeetingRoomOpen]);

  const nextModal = (modalName: string) => {
    setActiveModal(modalName);
  };

  const microPhoneSelect = (val: any) => {
    setSelectedMicroPhone(val)
  };

  const speakerSelect = (val: any) => {
    setSelectedSpeaker(val)
  };

  const onCancel = () =>{
    setActiveModal('');
    closeModal && closeModal()
  }

  const onSuccessModalClose = () => {
    setSuccessModalVisible(false)
  }

  useEffect(() => {
    setSuccessModalVisible(successModalProps?.open)
  }, [successModalProps?.open])

  const renderModalContent = () => {
    switch (activeModal) {
      case 'joinAudio':
        return <JoinAudio onNext={() => nextModal('testSpeaker')} closeModal={closeModal} />;
      case 'testSpeaker':
        return <TestSpeaker onNext={() => nextModal('replyCheck')} speakerSelect={speakerSelect}  />;
      case 'replyCheck':
        return <ReplyCheck onNext={() => nextModal('deviceTestResult')} microPhoneSelect={microPhoneSelect} />;
      case 'deviceTestResult':
        return <DeviceTestResult onClose={closeModal} selectedMicroPhone={selectedMicroPhone} selectedSpeaker={selectedSpeaker}/>;
      case 'scheduleMeeting':
        return <ScheduleMeeting isEditFlow={isEditFlow} editMeetingData={editMeetingData} createMeetings={createMeeting} onClose={onCancel} updateMeeting={updateMeeting} setModalData={setSuccessModalProps} />;
      case 'createMeeting':
        return <CreatMeeting meetingLink={meetingLink} createMeetings={createMeeting} setShowPopup={closeModal}/>;
      // case 'waitingRoom':
      //   return <WaitingRoom />;
      default:
        return null;
    }
  };

  const getModalStyle = () => {
    switch(activeModal){
      case 'scheduleMeeting':
        return 'zoom-meeting-modal schedule-meeting';
      case 'createMeeting':
        return 'zoom-meeting-modal create-meeting';
      case 'waitingRoom':
        return 'zoom-meeting-modal waiting-room';
      case 'joinAudio':
        return 'zoom-meeting-modal join-audio';
      default:
        return 'zoom-meeting-modal'
    }
  };



  return (
    <>
    <Modal
      open={!!activeModal}
      footer={null}
      closable={closeoption || false}
      className={
        getModalStyle()
      }
      destroyOnClose={true}
    >
      {renderModalContent()}
    </Modal>
    <SuccessModal 
      {...successModalProps}
      open={successModalVisible}
      onOk={onSuccessModalClose}
    />
  </>
  );
};

export default MeetingPopUp;
