import { useState, useContext, useEffect } from 'react';
import { ReactComponent as MicMute } from './images/mic-mute.svg';
import { ReactComponent as MicUnMute } from './images/microphone_unmute.svg';

import { ReactComponent as SwapIcon } from './images/swap-icon.svg';
import { ReactComponent as ParticipantsIconOn } from './images/participants-icon-on.svg';
import { ReactComponent as ParticipantsIconOff } from './images/participants-icon-off.svg';
import { ReactComponent as ChatOn } from './images/chat-on-highlight.svg';
import { ReactComponent as ChatOff } from './images/chat-off.svg';
import { ReactComponent as FullScreenIcon } from './images/full-screen-icon.svg';
import ZoomContext from '../context/zoom-context';
import { useVideo } from '../Hooks/useVideo';
import './styles.scss';
import MeetingPopUp from '../MeetingPopUp';
import { useAudio } from '../Hooks/useAudio';
import useParticipants from '../Hooks/useParticipants';
import { useScreenShare } from '../Hooks/useScreenShare';
import ProfileIcon from './ProfileIcon';
import ParticipantsNotification from '../SidePannel/Participants/ParticipantsNotification';
import { ReactComponent as Icon } from './images/keyboard-arrow-down.svg';
import EndConference from './EndConference';
import VideoOption from './VideoOption';
import LayoutOptions from './LayoutOption';
import { ActionType, LayoutType } from '../types/header-actiontypes';
import { Tooltip } from 'antd';
import ScreenShare from './ScreenShareOption';
import FullScreenOption from './FullScreenOption';

interface ExtendedHeaderProps {
  setActiveComponent: (component: string) => void;
  setShowChat: (show: 'chat' | '') => void;
  setShowParticipants: (show: 'participants' | '') => void;
  layoutMode: LayoutType;
  setLayoutMode: (component: LayoutType) => void;
  activeModal: string;
  setActiveModal: (str: string) => void;
  userRole: string;
  showParticipants: string | null;
}

interface Message {
  id: string;
  message: string;
  sender: {
    userId: string;
    name: string;
    avatar: string;
  };
  receiver: {
    userId: string;
    name: string;
  };
  timestamp: number;
  file?: any; // Added here
}

const Header: React.FC<ExtendedHeaderProps> = ({
  setActiveComponent,
  setShowChat,
  setShowParticipants,
  layoutMode,
  setLayoutMode,
  activeModal,
  setActiveModal,
  userRole,
  showParticipants,
}) => {
  const [isMicOn, setIsMicOn] = useState(false);
  // const [ isVideoOn, setIsVideoOn ] = useState(false)
  const [isChatOn, setIsChatOn] = useState(false);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);
  const [showParticipantsToggle, setShowParticipantsToggle] = useState(true);
  // const [isMeetingRoomOpen, setIsMeetingRoomOpen] = useState(false); // State to control the popup
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const zoomClient = useContext(ZoomContext);
  const { isVideoOn, onCamClick } = useVideo(zoomClient);
  const [isInvertOn, setIsInvertOn] = useState(false);
  // const {mediaStream} = useContext(ZoomMediaContext);
  const { isAudioOn, onMicClick, joinAudio } = useAudio(zoomClient);
  // const [ activeModalName, setActiveModalName ] = useState('')
  const [messages, setMessages] = useState<
    {
      id: string;
      message: string;
      sender: {
        userId: string;
        name: string;
        avatar: string;
      };
      receiver: {
        userId: string;
        name: string;
      };
      timestamp: number;
    }[]
  >([]);

  const participants: any = useParticipants() || [];

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const toggleHeaderIcons = (action: ActionType) => {
    resetHeaderState();

    switch (action) {
      case 'Microphone':
        // setIsMicOn(!isMicOn);
        onMicClick();
        // setIsMeetingRoomOpen(!isMicOn);
        // setActiveModalName(!isMicOn ? 'joinAudio' : '')
        break;
      case 'Video':
        // setIsVideoOn(!isVideoOn)
        // if(isVideoOn){
        //   setActiveComponent('')
        // }
        // else{
        // setActiveComponent('webcam')
        onCamClick();
        // }
        break;
      case 'ScreenShare':
        setIsScreenShareOn(!isScreenShareOn);
        setActiveComponent(isScreenShareOn ? '' : 'screenshare');
        break;
      case 'Participants':
        setShowParticipantsToggle(!showParticipantsToggle);
        if (showParticipantsToggle) {
          setShowParticipants('');
          sessionStorage.removeItem('sidepanel');
        } else {
          setShowChat('');
          setShowParticipants('participants');
          sessionStorage.setItem('sidepanel', 'true');
        }
        break;
      case 'Chat':
        setIsChatOn(!isChatOn);
        if (isChatOn) {
          setShowChat('');
          setMessages([]);
        } else {
          setShowChat('chat');
          setShowParticipants('');
          sessionStorage.removeItem('sidepanel');
          setUnreadMessagesCount(0);
        }
        break;
      // case 'invert':
      //     setIsInvertOn(!isInvertOn);
      //     if (isInvertOn) {
      //       setIsInvert(false)
      //     } else {
      //       setIsInvert(true)
      //     }
      //     break;
      default:
        console.warn(`Unhandled action: ${action}`);
    }
  };

  const resetHeaderState = () => {
    setIsMicOn(false);
    // setIsVideoOn(false)
    setIsScreenShareOn(false);
    setIsChatOn(false);
    setShowParticipantsToggle(false);
  };

  const closeModal = () => {
    setActiveModal(''); // Open the popup when the header is clicked
  };

  const isJSON = (e: any) => {
    try {
      const message = JSON.parse(e.message);
      return message;
    } catch (error) {
      return false;
    }
  };

  const getChatCount = (e: any) => {
    const isJSONMessage = isJSON(e);
    if (isJSONMessage && isJSONMessage.type === 'delete-message') {
      // If the message is a delete-message, remove it from the messages array
      setMessages((prevMessages) => prevMessages.filter(msg => msg.id !== isJSONMessage.messageId));
    } else if (!isJSONMessage || !isJSONMessage.type) {
      // Only adding non-deleted and non-control messages to the messages array
      const newMessage = {
        id: e.id,
        message: e.message,
        sender: e.sender,
        receiver: e.receiver,
        timestamp: e.timestamp,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      if ((e.receiver.userId.toString() === '0' || e.receiver.userId === zoomClient.getCurrentUserInfo().userId) && !isChatOn) {
        setUnreadMessagesCount((prevCount) => prevCount + 1); 
      }
    }
  };


  useEffect(() => {
    zoomClient.on('chat-on-message', getChatCount);
    return () => {
      zoomClient.off('chat-on-message', getChatCount);
    };
  }, [zoomClient, isChatOn]);

  return (
    <div className='zoom-meeting-header'>
      <div className='center-icons'>
        <div className='header-buttons micMute-icons'>
          <div onClick={() => toggleHeaderIcons('Microphone')}>
            {isAudioOn ? <MicUnMute /> : <MicMute />}
          </div>
          <div>
            <Icon className='down-arrow' onClick={() => setActiveModal('joinAudio')} />
          </div>
        </div>
        <VideoOption />
        <ScreenShare
          isScreenShareOn={isScreenShareOn}
          toggleHeaderIcons={toggleHeaderIcons}
          setActiveComponent={setActiveComponent}
        />
        <div
          className='header-buttons participate-icons'
          onClick={() => toggleHeaderIcons('Participants')}
        >
          {showParticipantsToggle ? (
            <Tooltip placement='bottom' title='Show Participants list'>
              <ParticipantsIconOn />
            </Tooltip>
          ) : (
            <Tooltip placement='bottom' title='Show Participants list'>
              <ParticipantsIconOff />
            </Tooltip>
          )}
          <Tooltip
            placement='bottom'
            title={
              isNotificationOpen
                ? showParticipantsToggle
                  ? 'Show Participants list'
                  : ''
                : 'Show Participants list'
            }
          >
            <div className='tooltip-container'>
              {userRole === 'host' && zoomClient?.getSessionInfo().isInMeeting ? (
                <ParticipantsNotification
                  showParticipants={showParticipants}
                  openParticipantsPanel={() => {
                    setShowParticipants('participants');
                    sessionStorage.setItem('sidepanel', 'true');
                  }}
                  setIsNotificationOpen={setIsNotificationOpen}
                />
              ) : null}
              <span className={`participants-count ${showParticipantsToggle ? 'active' : ''}`}>
                {participants?.length > 0 ? participants?.length : null}
              </span>
            </div>
          </Tooltip>
        </div>
        <Tooltip placement='bottom' title={'Show Chat'}>
          <div className='header-buttons chat-icons' onClick={() => toggleHeaderIcons('Chat')}>
            {isChatOn ? (
              <ChatOn />
            ) : (
              <div>
                <ChatOff />
                {unreadMessagesCount > 0 && <span>{unreadMessagesCount}</span>} 
              </div>
            )}
          </div>
        </Tooltip>
        <EndConference />
        <div className='header-buttons swap-icons'>
          <SwapIcon />
        </div>
      </div>

      <div className='right-icons'>
        <LayoutOptions layoutMode={layoutMode} setLayoutMode={setLayoutMode} />

        <div className='right-buttons fullScreen-icons'>
          <FullScreenOption layoutMode={layoutMode} />
          <MeetingPopUp
            isMeetingRoomOpen={!!activeModal}
            joinAudio={joinAudio}
            activeModalName={activeModal}
            closeModal={closeModal}
          />{' '}
          {/* Render the MeetingPopUp */}
        </div>
        <div className='right-header-image'>
          <ProfileIcon />
        </div>
      </div>
    </div>
  );
};

export default Header;
