import React, { useState, useContext, useEffect, useCallback } from 'react';
import { auth } from '../../../../Firebase';
import useNavigateToPage from '../../../../hooks/useNavigateToPage';
import { get } from '../../Helpers/utils';
import { ReactComponent as EndCall } from '../images/end-call.svg';
import ZoomContext from '../../context/zoom-context';
import { AuthContext } from '../../../../Context/authContext';
import { Dropdown, Menu } from 'antd';
import '../styles.scss';
import LeaveAndEndOptions from './LeaveandEndConference';
import { ConnectionState } from '@zoom/videosdk';
import { useLocationStore } from '../../../../store/useLocationStore';
import { useConferenceCommands } from '../../Hooks/useConferenceCommands';
import ProgressLoader from '../../../Common/ProgressLoader';

const EndConference = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigateToPage = useNavigateToPage();
  
    const locationState = useLocationStore((state) => state.locationState);
    const show_id = get(locationState, 'state.show_id', '');
    const show_name = get(locationState, 'state.show_name', '');
    const show_code = get(locationState, 'state.show_code', '');
    const team_admin = get(locationState, 'state.team_admin', '');
    const zoomFlag = get(locationState, 'state.zoomFlag', '');
    const participant_show_user_role_id = get(locationState, 'state.user_role_id', '');
    const viewerFlag = get(locationState, 'state.viewerFlag', '');
    const meetingId = get(locationState, 'state.meetingId', '');
    const sessionId = get(locationState, 'state.session_id', '');
    
  const zoomClient = useContext(ZoomContext);
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const isGuestUser = get(authValue, 'isMeetingGuest', false);
  const uid = get(authValue, 'uid', '');
  const { leaveConference } = useConferenceCommands(meetingId,show_id,participant_show_user_role_id,sessionId);

  const isHost = zoomClient?.getCurrentUserInfo()?.isHost;

  const navigateToShowSelection = () => {
    navigateToPage('/intermediate', { state: { userId: uid }, replace: true }, true);
  };

  const navigateToIntermediate = () => {
    navigateToPage('/intermediate', {
      state: {
        show_id,
        show_name,
        show_code,
        team_admin,
        zoomFlag,
        user_role_id: participant_show_user_role_id,
        viewerFlag,
      },
    });
  };

  const onConnectionChange = useCallback(
    async (payload: any) => {
      if (payload.state === ConnectionState.Closed) {
        if (payload.reason === 'ended by host') {
          if (isGuestUser) {
            setLoading(true);
            await leaveConference(false);
            await zoomClient.leave();
            await auth.doSignOut();

            navigateToPage('/logoutredirect', { state: { isGuest: true }, replace: true });
          } else {
            navigateToShowSelection();
          } 
        }
      }
    },
    [zoomClient],
  );

  const onCommandChannelMessage = useCallback(
    async (payload: any) => {
      const { removeUser } = JSON.parse(payload.text);

      if (removeUser) {
        const currentUser = zoomClient?.getCurrentUserInfo();
        if (currentUser?.userId === removeUser && !currentUser?.isHost) {
          await handleMenuClick({ key: 'leave' });
        }
      }
    },
    [zoomClient],
  );
  
  useEffect(() => {
    zoomClient.on('connection-change', onConnectionChange);
    zoomClient.on('command-channel-message', onCommandChannelMessage);
  }, [zoomClient]);

  const handleMenuClick = async ({ key }: { key: string }) => {
    setLoading(true);
    sessionStorage.setItem('hasShownConfirmation', 'false');
    try {
      if (key === 'leave') {
        if (isGuestUser) {
          await leaveConference(false);
          await zoomClient.leave();
          await auth.doSignOut();

          navigateToPage('/logoutredirect', { state: { isGuest: true }, replace: true });
        } else {
          await leaveConference(false);
          await zoomClient.leave();
          navigateToIntermediate();
        }
      } else if (key === 'endForEveryone') {
        await leaveConference(isHost);
        await zoomClient.leave(isHost);
        navigateToIntermediate();
      }

      setDropdownVisible(false);
    } catch (error) {
      console.error('Error handling menu click:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <ProgressLoader loading={loading} />
      <Dropdown
        overlay={
          <Menu className={isHost ? 'custom-dropdown-menu-host' : 'custom-dropdown-menu-nonhost'}>
      <Menu.Item key='cancel' className='custom-dropdown-cancel'>
        Cancel
      </Menu.Item>
      <div className='custom-divider' />
      <LeaveAndEndOptions isHost={isHost} onMenuClick={handleMenuClick} loading={loading} />
    </Menu>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}
    >
      <div className='header-buttons endCall-icons'>
        <EndCall />
      </div>
    </Dropdown>
    </>
  );
};
export default EndConference;
