import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

function encrypt(key: string, strValue: string) {
  return CryptoAES.encrypt(strValue, key).toString();
}

function decrypt(key: string, encryptedValue: string) {
  return CryptoAES.decrypt(encryptedValue, key).toString(CryptoENC);
}

export const getGuestToken = (key: string) => {
  const encryptedGuestToken = sessionStorage.getItem(key);
  return encryptedGuestToken ? decrypt(key, encryptedGuestToken) : encryptedGuestToken;
};

export const setGuestToken = (guestToken: string) => {
  const encryptedGuestToken = encrypt('at', guestToken);
  sessionStorage.setItem('at', encryptedGuestToken);
};
