import React, { useContext } from 'react';
import './styles.css';
import WebCam from './WebCam';
import ScreenShare from './ScreenShare';
import { useScreenShare } from '../Hooks/useScreenShare';
import zoomContext from '../context/zoom-context';

interface VideoContainerProps {
  activeComponent: string | null;
  hideScreen:boolean
  setActiveComponent:(arg:string)=>void;
}

const VideoContainer: React.FC<VideoContainerProps> = ({ activeComponent,
  hideScreen, 
  setActiveComponent }) => {
  const zmClient = useContext(zoomContext);
  const { sharingUser } = useScreenShare(zmClient, setActiveComponent);

  return (
    <div
      className= {hideScreen ? 'zoom-meeting-content-shrink' :'zoom-meeting-main-content'}
      style={{
        backgroundColor: '#fff',
        display:  'flex',

      }}
    >
      <div className='zoom-meeting-video-screen' id='zoom-meeting-video-screen'>
        {/* {activeComponent === 'screenshare' &&  */}
        <ScreenShare screenShareUser={sharingUser} activeComponent={activeComponent}/>
        {activeComponent !== 'screenshare' && !sharingUser && <WebCam />}

        {/* } */}
        {/* <WebCam /> */}
      </div>
    </div>
  );
};

export default VideoContainer;
