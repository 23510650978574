import React, { useEffect } from 'react';
import './styles.css'

interface soundLevelBarsInterface {
  audioLevel: number;
}

const SoundLevelBars = ({ audioLevel }: soundLevelBarsInterface) => {
  // const [audioLevel, setAudioLevel] = useState<number>(0);

    useEffect(() => {
        var bars = document.getElementsByClassName("bar");
        console.log(audioLevel);
        for (var i = 0; i < bars.length; i++) {
          if(audioLevel / (50 / bars.length) > i) {
            bars[i].classList.add("fill");
          } else {
            bars[i].classList.remove("fill");
          }
        }
      }, [audioLevel]);
    return (
      <div className="audio-progress">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div> 
    );
  };
  
export default SoundLevelBars;
