import React, { useContext, useState } from 'react';
import './index.scss';
// import { ReactComponent as MicMute } from '../../Header/images/mic-mute.svg';
import { ReactComponent as MicUnMute } from '../../Header/images/microphone_unmute.svg';
// import { ReactComponent as VideoOff } from '../../Header/images/video-off.svg';
import { ReactComponent as VideoOn } from '../../Header/images/video-filled-large.svg';
import { get } from '../../Helpers/utils';
import moment from 'moment';
import { AuthContext } from '../../../../Context/authContext';
import CameraPreview from './CameraPreview';
import {ReactComponent as DarkModeMicMute} from '../../Images/dark mode mic icon box.svg';
import {ReactComponent as DarkModeVideoOff} from '../../Images/video_Off.svg';

const WaitingRoom = ({
  triggerTestSpeaker = () => {},
  meeting_data,
  setMeetingData,
}: {
  triggerTestSpeaker?: () => void;
  meeting_data: any;
  setMeetingData: (data: any) => void;
}) => {
  const [isMicOn, setIsMicOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(get(meeting_data, 'video_preference', false));
  const ispersonalmeeting = get(meeting_data, 'ispersonalmeeting', false);
  const meeting_name = get(meeting_data, 'meeting_name', '');
  // const meeting_name = ispersonalmeeting
  //   ? `${get(meeting_data, 'meeting_name', '')} 's Meeting`
  //   : get(meeting_data, 'meeting_name', '');
  const format_time = (unixTimestamp: number) => {
    return moment(unixTimestamp).format('hh:mm A');
  };

  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const displayName = get(authValue, 'displayName', '').charAt(0);

  const toggleMic = () => {
    const newAudioState = !isMicOn;
    setMeetingData({ ...meeting_data, isAudioON: newAudioState });
    setIsMicOn(newAudioState);
  };

  const toggleVideo = () => {
    const newVideoState = !isVideoOn;
    setMeetingData({ ...meeting_data, video_preference: newVideoState });
    setIsVideoOn(newVideoState);
  };

  return (
    <div className='waiting-room-container'>
      <h4>Waiting Room</h4>
      <div className='request-container'>
        <div
          className='controls-container'
        >
          {/* <img
            src='https://media.istockphoto.com/id/1295639732/photo/man-making-a-video-call-in-his-home.jpg?s=612x612&w=0&k=20&c=oke6OekH1Yze_wCWUxQ7FPo_cC8rTfqPYESqSb9b3qI='
            alt=''
          /> */}
          <div className='video-container'
            style={{ backgroundColor: isVideoOn ? 'transparent' : '#6A29FF' }}
          >
            {!isVideoOn ? `${displayName}` : <CameraPreview isVideoOn={isVideoOn} />}
          </div>
          <div className='control-buttons'>
            <span onClick={toggleMic}>{isMicOn ? <MicUnMute /> : <DarkModeMicMute />}</span>
            <span onClick={toggleVideo}>{isVideoOn ? <VideoOn />  : <DarkModeVideoOff />}</span>
          </div>
        </div>
        <div className='details-container'>
          <h2>{meeting_name}</h2>
          {!ispersonalmeeting && (
            <p className='time-range'>
              {`${format_time(Number(get(meeting_data, 'start_time', '')))} - ${format_time(
                Number(get(meeting_data, 'end_time', '')),
              )}`}
            </p>
          )}
          <p>The host will accept your request in a moment, please wait...</p>
          <span onClick={triggerTestSpeaker}>Test Speaker and Mic</span>
        </div>
      </div>
    </div>
  );
};

export default WaitingRoom;