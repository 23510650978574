// JoinAudioModal/JoinAudio.tsx
import React from 'react';
import { Button } from 'antd';
import './styles.css';

const JoinAudio: React.FC<{ onNext: () => void , closeModal:any}> = ({ onNext,closeModal }) => (
  <div className="zoom-meeting join-audio-container">
    <Button className="join-speaker" onClick={closeModal}>
      Join with Computer Audio
    </Button>
    <Button className="join-microphone" onClick={onNext}>
      Test Speaker and Microphone
    </Button>
  </div>
);

export default JoinAudio;