import React, { useState } from 'react';
import CopyIcon from '../images/copy.png';
import arrowDown from '../images/arrow-down.svg';
import arrowUp from '../images/arrow-up.svg';

interface GuestInviteSectionProps {
  inviteLink?: string;
  dialInNumbers?: string[];
  meetingPassword?: string;
}

const GuestInviteSection: React.FC<GuestInviteSectionProps> = ({
  inviteLink = "http://pacpost.live/xebrandlv.com",
  dialInNumbers = [
    "+1 386 347 5053",
    "+1 360 209 5623",
    "+1 346 248 7799"
  ],
  meetingPassword
}) => {
  const [copied, setCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCopy = async () => {
    const textToCopy = !!meetingPassword
      ? `${inviteLink}${inviteLink.includes('?') ? '&' : '?'}p=${encodeURIComponent(meetingPassword)}`
      : inviteLink;
  
    await navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="zoom-meeting-participants-guest-wrapper">
      <div className="zoom-meeting-participants-guest-section">
        <span className="zoom-meeting-participants-guest-label">Guest Invite Link</span>
        <div className="zoom-meeting-participants-link-box">
          <div className="zoom-meeting-participants-invite-link">{inviteLink}</div> 
          <button 
            className={`zoom-meeting-participants-copy-button ${copied ? 'copied' : ''}`}
            onClick={handleCopy}
          >
            <img 
              src={CopyIcon} 
              alt="Copy"
              width={38}
              height={38}
            />
            {copied && (
              <div className="zoom-meeting-participants-copy-notification visible">
                Copied to Clipboard
              </div>
            )}
          </button>
          <button onClick={toggleSection} className='zoom-meeting-arrow'>
            <img 
              src={isOpen ? arrowDown : arrowUp}
              alt="Toggle"
              width={20}
              height={20}
            />
          </button>
        </div>
        {isOpen && (
          <>
            <div className="zoom-meeting-participants-invite-link password">
              {meetingPassword ? meetingPassword : null}
            </div>
            {/*
            <div className="zoom-meeting-participants-dial-container">
              <span className="zoom-meeting-participants-dial-label">Dial In</span>
              <div className="zoom-meeting-participants-dial-numbers">
                {dialInNumbers.map((number, index) => (
                  <div key={index} className="zoom-meeting-participants-dial-number">
                    {number}
                  </div>
                ))}
              </div>
            </div>
            */}
          </>
        )}
      </div>
    </div>
  );
};

export default GuestInviteSection;