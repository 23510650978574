import React, { useRef, useEffect, useContext } from 'react';
import './style.css';
import { useLocationStore } from '../../../../store/useLocationStore';
import { get } from '../../Helpers/utils';
import GuestInviteSection from './GuestInviteSection';
import useParticipants from '../../Hooks/useParticipants';
import WaitingParticipants from './WaitingParticipants';
import zoomContext from '../../context/zoom-context';

const Participants: React.FC = () => {
  const participants = useParticipants();
  // const [input, setInput] = useState<string>('');
  const locationState = useLocationStore((state) => state.locationState);
  const meeting_link = get(locationState, 'state.meetingLink', '');
  const endOfListRef = useRef<HTMLDivElement | null>(null);
  const meeting_password = get(locationState, 'state.password', '');
  const zmClient = useContext(zoomContext);
  const isHost = zmClient?.getCurrentUserInfo()?.isHost;

  // const addParticipant = () => {
  //   if (input.trim()) {
  //     const newParticipant = {
  //       name: input,
  //       time: new Date().toLocaleTimeString(),
  //     };
  //     setParticipants([...participants, newParticipant]);
  //     setInput('');
  //   }
  // };

  // const hasParticipants = participants.some((participant: any) =>
  //   participant.userIdentity.startsWith('participant#'),
  // );

  // const hostUserId = participants
  //   .filter((participant: any) => participant.userIdentity.startsWith('participant#'))
  //   .map((participant: any) => participant.userIdentity.split('#')[1])
  //   .pop();

  // const currentUserId = participants[0]?.userIdentity?.split('#')[1];

  // const isCurrentUserHost = hostUserId && currentUserId && hostUserId === currentUserId;

  const removeUser = async(userId:number) => {
    try {
      await zmClient?.getCommandClient().send(
        JSON.stringify({removeUser:userId}), userId
      ); 
    } catch (error) {
      console.error('Error removing user:', error);
    }
  }

  useEffect(() => {
    endOfListRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [participants]);

  return (
    <div className='zoom-meeting-participants-container'>
      <div className='zoom-meeting-participants-header'>
        <div className='zoom-meeting-participants-view-container'>
          <span className='zoom-meeting-participants-text'>PARTICIPANTS</span>
        </div>
      </div>
      <div className='zoom-meeting-participants-window'>
        <div className='zoom-meeting-chat-scroll'>
          {isHost && <WaitingParticipants />}{' '}
          <div className='zoom-participants-wrapper'>
          <div className='zoom-meeting-participants-count'>
            {participants.length} participant{participants.length !== 1 ? 's' : ''} in the meeting
          </div>
          <div className='zoom-meeting-participants-list'>
          {participants.map((participant, index) => {
            return (
              <div key={index} className='zoom-meeting-participants-view'>
                <span
                  className={`zoom-meeting-participants-view-text ${
                    participant.userIdentity?.startsWith('host#') ? '(Host)' : ''
                  }`}
                >
                  {participant.displayName}{' '}
                  {participant.userIdentity?.startsWith('host#') && <span>(Host)</span>}
                </span>
                {isHost && !participant.isHost && (
                  <button
                    className='zoom-meeting-remove-button'
                    onClick={() => removeUser(participant.userId)}
                  >
                    Remove
                  </button>
                )}
              </div>
            );
          })}
          </div>
          </div>
          <div ref={endOfListRef} />
          {/* {meeting_link && (
        <div className='zoom-meeting-participants-view link-container'>
          <span className='zoom-meeting-participants-view-text'>{meeting_link}</span>
        </div>
        )} */}
        </div>
        {/* <div className='zoom-meeting-participants-input-container'>
        <input
          type='text'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => (e.key === 'Enter' ? addParticipant() : null)}
          placeholder='Add a participant...'
          className='zoom-meeting-participants-input-field'
        />
        <button onClick={addParticipant} className='zoom-meeting-participants-add-button'>
          Add
        </button>
      </div> */}
        {isHost && (
          <GuestInviteSection inviteLink={meeting_link} meetingPassword={meeting_password} />
        )}
      </div>
    </div>
  );
};

export default Participants;
