import React, { useEffect, useRef } from 'react';

interface ICanvasType {
    imageUrl: string;
    onClick?: () => void;
    className?: string;
    style?: any;
}

const ImageCanvas = ({ imageUrl, onClick, className, style }: ICanvasType) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas: any = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                };

                img.src = imageUrl;
            }
        }
    }, [imageUrl]);

    return <canvas className={className} ref={canvasRef} style={style} onClick={onClick} />;
}

export default ImageCanvas