import { Table, Button, Modal, List, Typography, notification, Spin, Row, Tooltip, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { get } from '../../Utils/helpers';
import algoliasearch from 'algoliasearch';
import { useContext, useEffect, useState } from 'react';
import backend from '../../Service/Backend';
import { AuthContext } from '../../Context/authContext';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import api from '../../Service/Api';
import { fetchAllShows } from '../../Service/show';
import './style.css';


const {
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_STREAM_INDEX_NAME,
} = process.env;

const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID || '',
  REACT_APP_ALGOLIA_SEARCH_KEY || '',
);

const VcLists = () => {
  const currentUser = get(useContext(AuthContext), 'currentUser', {});
  const [streamData, setStreamData] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [vCLoading, setVCLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLiveRoomSid, setSelectedLiveRoomSid] = useState('');
  const [isPreviousModalVisible, setIsPreviousModalVisible] = useState(false);
  const [previousSessions, setPreviousSessions] = useState<string[]>([]);
  const [selectedRoomDetails, setSelectedRoomDetails] = useState<any>(null);
  const [selectedRoomSid, setSelectedRoomSid] = useState<string>('');
  const [selectedLiveRoomDetails, setSelectedLiveRoomDetails] = useState<any>(null);
  const [availableShows, setAvailableShows] = useState<any[]>([]);
  const [filterOption, setFilterOption] = useState('active');
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  useEffect(() => {
    const fetchShows = async () => {
      try {
        setLoading(true);
        const shows = await fetchAllShows();
        const showOptions = shows?.hits.map((show: any) => ({
          value: get(show, 'show_name', ''),
          showId: get(show, 'objectID', ''),
          status:get(show,'show_status','')
        }));
        setAvailableShows(showOptions || []);
      } catch (error: any) {
        notification.error({
          message: 'Error fetching Shows',
          placement: 'topRight',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchShows();
  }, []);

  
  const fetchStreamByShowName = async (showName: string) => {
    try {
      setStreamData([]);
      setExpandedRowKeys([]);
      setLoading(true);
      if (streamData.length) {
        setStreamData([]);
        setSelectedLiveRoomDetails(null);
        setSelectedLiveRoomSid("");
        setPreviousSessions([]);
        setSelectedRoomSid('');
        setSelectedRoomDetails(null);
      }
      const shows = await fetchAllShows();
      const selected_show = shows?.hits.find((show: any) => get(show, 'show_name', '').localeCompare(showName, undefined, { sensitivity: 'case' }) === 0);
      const showId = get(selected_show, 'objectID', '');
  
      if (!selected_show || !showId) {
        notification.error({
          message: 'No Streams Found',
          description: `No streams found for show name: ${showName}`,
          placement: 'topRight',
        });
        return;
      }
  
      const indexName = REACT_APP_ALGOLIA_STREAM_INDEX_NAME;
      if (!indexName) {
        throw new Error('Algolia stream index name is not defined.');
      }
      const index = searchClient.initIndex(indexName);
      const results: any = await index.search('', {
        filters: `show_name:"${showName}"`,
        hitsPerPage: 10000,
      });
  
      const data = {
        api: api.vc.getVCRoomsByShowId,
        urlParam: showId,
      };
  
      const response = await backend.fetch(data, get(currentUser, 'user.accessToken', ''));
      const filteredStreamData = response?.data || response;

      const streamVcList = results.hits.map((hit: any) => {
        const match = filteredStreamData.find(
          (res: any) => res.show_id === hit.show_id && res.showStream_id === hit.objectID,
        );
          return {
            ...hit,
            vc: match ? [match] : [],
          };
      });
      
      setStreamData(streamVcList);
    } catch (error) {
      console.error('Failed to fetch show streams:', error);
      if (get(error, 'errCode', '') === 40301) {
        notification.error({
          message: get(error, 'errMessage', ''),
          placement: 'topRight',
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSearch = () => {
    fetchStreamByShowName(searchInput);
  };

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };
  const handleActiveButtonClick = () => { 
    setFilterOption('active');
  };

  const handleAllButtonClick = () => {
    setFilterOption('all'); 
  
  };

  const handleFilterOptionChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFilterOption(event.target.value);
    if (event.target.value === 'active') {
      handleActiveButtonClick();
    } else {
      handleAllButtonClick();
    }
  };

  const getTwilioRoomDetails = async (show_id: string, room_sid: string) => {
    try {
      const data = {
        api: api.vc.getTwilioRoom,
        urlParam: room_sid,
        queryParam: {
          show_id: show_id,
        },
      };
      setVCLoading(true);
      const roomDetails = await backend.fetch(data, get(currentUser, 'user.accessToken', ''));
      setLoading(false);
      return roomDetails;
    } catch (err: any) {
      console.error(err);
    } finally {
      setVCLoading(false);
    }
  };

  const handlePreviousClick = (record: any) => {
    const previous_records = get(record, 'previous_sessions', []).map((doc: any) => ({
      doc,
      show_id: record.show_id,
    }));
    
    // Using a Set to ensure unique room_sid values
  const uniquePreviousRecords = Array.from(
    new Map(previous_records.map((item: any) => [get(item, 'doc.room_sid', ''), item])).values()
  );
    const previous_records_sorted = previous_records.sort(
      (a: any, b: any) =>
        get(get(b, 'doc', ''), 'modified_ts._seconds', 0) * 1000 +
        get(get(b, 'doc', ''), 'modified_ts._nanoseconds', 0) / 1000000 -
        get(get(a, 'doc', ''), 'modified_ts._seconds', 0) * 1000 +
        get(get(a, 'doc', ''), 'modified_ts._nanoseconds', 0) / 1000000,
    );

    setPreviousSessions(previous_records_sorted);
    setIsPreviousModalVisible(true);
  };

  const handleViewRoomDetails = async (showId: string, roomSid: string) => {
    setSelectedRoomSid(roomSid);
    console.log(':', showId);
    const details = await getTwilioRoomDetails(showId, roomSid);
    // setSelectedRoomDetails(details);
    if (details) {
      setSelectedRoomDetails(details);
    } else {
      setSelectedRoomDetails(null); 
    }
  };

  const handleViewLiveRoomDetails = async (showId: string, roomSid: string) => {
    if(!roomSid){
      setSelectedLiveRoomDetails('Not available'); 
      return
    }
    setSelectedLiveRoomSid(roomSid);
    const details = await getTwilioRoomDetails(showId, roomSid);
    if(get(details,'status','') !== 'in-progress'){
      // Add details to previousSessions if the room is not in progress
      setPreviousSessions(prev => [...prev, details]); 
      setSelectedLiveRoomDetails('Not available'); 
      setSelectedLiveRoomSid('');
      return 
    }
    setSelectedLiveRoomDetails(details);
  };

  const renderActionButtons = (record: any) => {
    return (
      <div>
        <Button
          onClick={() => {
            handleViewLiveRoomDetails(record.show_id, record.live_room_sid);
            setIsModalVisible(true);
          }}
          className='device-action-btn'
        >
          LIVE
        </Button>
        <Button onClick={() => handlePreviousClick(record)} className='device-action-btn'>
          PREVIOUS
        </Button>
      </div>
    );
  };

  const expandedRowRender = (record: any) => {
    console.log('record', record);
    const columns = [
      { title: 'Show VC Room ID', dataIndex: 'id', key: 'id' },
      { title: 'Status', dataIndex: 'show_vc_roomstatus', key: 'show_vc_roomstatus' },
      { title: 'Room Name', dataIndex: 'twilio_room_name', key: 'twilio_room_name' },
      { title: 'Action', render: renderActionButtons },
    ];

    const filteredStreamData = record.vc.filter(
      (stream: any) => stream.showStream_id === record.objectID,
    );
    return (
      <div className="vc-list-padding-10">
        <Table columns={columns} dataSource={filteredStreamData} pagination={false} />
      </div>
    );
  };

  const formatDate = (timestamp: any) => {
    return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000).toDateString();
  };

  const streamDataSource =()=>{
    let dataSource = streamData.map((stream, index) => ({ ...stream, key: index }));
   //  console.log(dataSource)
     if (filterOption === 'active'){
       return dataSource.filter(stream => stream.stream_status === 'Active' && stream.stream_status !== 'deleted');
    }
    return dataSource;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Stream Label',
      dataIndex: 'stream_label',
      key: 'stream_label',
      sorter: (a, b) => get(a, 'stream_label', '').localeCompare(get(b, 'stream_label', '')),
    },
    {
      title: 'Stream Id',
      dataIndex: 'objectID',
      key: 'objectID',
    },
    {
      title: 'Stream Name',
      dataIndex: 'user_entered_stream_label',
      key: 'user_entered_stream_label',
      sorter: (a, b) => get(a, 'user_entered_stream_label', '').localeCompare(get(b, 'user_entered_stream_label', '')),
    },
    {
      title: 'Created Date',
      dataIndex: 'created_ts',
      key: 'created_ts',
      render: formatDate,
    },
    {
      title: 'Status',
      dataIndex: 'stream_status',
      key: 'stream_status',
      sorter: (a, b) => get(a, 'stream_status', '').localeCompare(get(b, 'stream_status', '')),
    },
    {
      title: 'Start Date',
      dataIndex: 'stream_start_ts',
      key: 'stream_start_ts',
      render: formatDate,
    },
    {
      title: 'End Date',
      dataIndex: 'stream_end_ts',
      key: 'stream_end_ts',
      render: (props) => (props ? formatDate(props) : 'NA'),
    }
  ];

  const cropIdentity = (identity: string) => {
    const parts = identity.split('#');
    if (parts.length === 2) {
      const [prefix, rest] = parts;
      const suffixParts = rest.split('_');
      if (suffixParts.length > 1) {
        const middlePart = suffixParts[0];
        const suffix = suffixParts.slice(1).join('_');
        return `${prefix}#${middlePart.slice(0, 4)}..._${suffix}`;
      }
    }
    return identity;
  };

  const filterShows = (inputValue:string,option:any) =>{
    return option.value.toLowerCase().includes(inputValue.toLowerCase())
  }


  
  const getShowList = () => {
    const showList =
      filterOption === 'active'
        ? availableShows.filter((show: any) => get(show, 'status', '').toLowerCase() === 'active')
        : availableShows;
  
    // Ensure no duplicates based on showId
    const uniqueShowList = Array.from(
      new Map(showList.map((show) => [show.showId, show])).values(),
    );
  
    return uniqueShowList.map((show: any) => ({
      text: get(show, 'value', ''),
      value: get(show, 'value', ''),
      key: get(show, 'showId', ''),
    }));
  };

  return (
    <Row>
      <div className='device-list-container'>
        <div className='superadmin-list-heading'>
          <p className='heading-text showlist-heading'>
            Super Admin: <span className='grey-text'>VC LISTS</span>
          </p>
          <div className='heading-text-right'>
            <div className='radio-buttons-container'>
              <label className='radio-label'>
                <input
                  type='radio'
                  value='active'
                  checked={filterOption === 'active'}
                  onChange={handleFilterOptionChange}
                />
                <span className='radio-text'>ACTIVE</span>
              </label>

              <label className='radio-label'>
                <input
                  type='radio'
                  value='all'
                  checked={filterOption === 'all'}
                  onChange={handleFilterOptionChange}
                />
                <span className='radio-text'>ALL</span>
              </label>
            </div>
          </div>
        </div>
        <div className='device-list-searchbar-container'>
          {/* <input
            className='device-list-searchbar'
            name='search'
            type='text'
            placeholder='Enter Show Name'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <br /> */}
          <Select
            // className={'vcListHeader'}
            showSearch
            options={getShowList()}
            placeholder='Enter Show Name'
            // value={searchInput || ''} // Ensure value is an empty string if searchInput is falsy
            onChange={(value: string) => setSearchInput(value)}
            onSelect={(value: string) => setSearchInput(value)}
            allowClear
            filterOption={filterShows}
            optionLabelProp="value" 
            style={{ width: '100%' }}
          />

          <br />
          <Button onClick={handleSearch} className='device-action-btn'>
            Search
          </Button>
        </div>
        <div className='ppl-device-list'>
          <Table
            columns={columns}
            expandable={{ expandedRowRender, expandedRowKeys, onExpand: handleExpand }}
            dataSource={streamDataSource()}
            className='table-bordered'
            pagination={false}
          />
        </div>
        <Modal
          title='Live Room Details'
          open={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          width='65vw'
          className="modal-top-17"
        >
          {/* <div style={{ height: '65vh', display: 'flex' }}>
            <div
              style={{
                width: '50%',
                overflowY: 'auto',
                borderRight: '1px solid #e8e8e8',
                paddingRight: '10px',
              }}
            > */}
            <div className="modal-content">
            <div className="modal-content-left">
              <List
                dataSource={[{ room_sid: selectedLiveRoomSid }]}
                renderItem={(item: any) => (
                  <List.Item>
                    <span>{item.room_sid}</span>
                    {selectedLiveRoomDetails && selectedLiveRoomDetails.connected_participants?.length > 0 && (
                      <Tooltip
                        title={
                          // <div style={{ maxHeight: '200px', overflowY: 'auto', width: '200px' }}>
                          <div className="tooltip-content">
                            <List
                              size='small'
                              dataSource={selectedLiveRoomDetails.connected_participants}
                              renderItem={(participant: { identity: string }) => (
                                <List.Item style={{ padding: '4px 0', borderBottom: 'none' }}>
                                  <Typography.Text ellipsis>
                                    {cropIdentity(participant.identity)}
                                  </Typography.Text>
                                </List.Item>
                              )}
                            />
                          </div>
                        }
                        placement='right'
                      >
                        <InfoCircleOutlined className="vc-list-info-icon" />
                      </Tooltip>
                    )}
                  </List.Item>
                )}
              />
            </div>
            <div className="modal-content-right">
              {vCLoading ? (
                <div className="loading-container">
                  <Spin size='large' />
                  <Typography.Title level={4} className="vc-list-text">
                    Loading
                  </Typography.Title>
                </div>
              ) : selectedLiveRoomDetails ? (
                <div>
                  <Typography.Title level={4}>Room Details</Typography.Title>
                  <pre>{JSON.stringify(selectedLiveRoomDetails, null, 2)}</pre>
                </div>
              ) : (
                <p>No details available for the live room.</p>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          title='Previous Session SIDs'
          open={isPreviousModalVisible}
          // onOk={() => setIsPreviousModalVisible(false)}
          onOk={() => {
            setIsPreviousModalVisible(false);
            setSelectedRoomDetails(null);
            setSelectedRoomSid('');
          }}
          // onCancel={() => setIsPreviousModalVisible(false)}
          onCancel={() => {
            setIsPreviousModalVisible(false);
            setSelectedRoomDetails(null); 
            setSelectedRoomSid(''); 
          }}
          width='65vw'
          className="modal-top-17"
        >
          <div className="modal-content">
            <div className="modal-content-left">
            
              <List
                dataSource={previousSessions}
                renderItem={(item: any) => (
                  <List.Item key={get(item, 'doc.room_sid', '')}>
                    <span
                      style={{
                        color:
                          get(item, 'doc.room_sid', '') === selectedRoomSid ? '#1890ff' : 'inherit',
                      }}
                    >
                      {get(item, 'doc.room_sid', '')}
                    </span>
                    <Button
                      onClick={() =>
                        handleViewRoomDetails(item.show_id, get(item, 'doc.room_sid', ''))
                      }
                      className='device-action-btn'
                    >
                      View
                    </Button>
                  </List.Item>
                )}
              />
            </div>
            <div className="modal-content-right" >
              {vCLoading ? (
                <div className="loading-container">
                  <Spin size='large' />
                  <Typography.Title level={4}  className="vc-list-text">
                    Loading
                  </Typography.Title>
                </div>
              ) : selectedRoomDetails ? (
                <div>
                  <Typography.Title level={4}>Room Details</Typography.Title>
                  <pre>{JSON.stringify(selectedRoomDetails, null, 2)}</pre>
                </div>
              ) : (
                <p>Select a session from the list to view details.</p>
              )}
            </div>
          </div>
        </Modal>
        <ProgressLoader loading={loading} />
      </div>
    </Row>
  );
};

export default VcLists;