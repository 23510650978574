
import produce from 'immer';
import moment from 'moment';
const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

const get = (source: any, path: string, defaultValue: any) => {
  if (!!source && !!path) {
    const parts = path.split(".");
    const length = parts.length;
    let result = source;
    for (let i = 0; i < length; i++) {
      let item = result[parts[i]];
      if (item === null || item === undefined) {
        return item || defaultValue;
      }
      result = item;
    }
    return result;
  }
  return defaultValue;
};

// const formatMeetingTime = (
//   startTime: string,
//   endTime: string,
//   isRecurring: boolean,
//   recurrence?: {
//     recurrence_type: string; // daily, weekly, monthly
//     recurrence_interval: number; // Interval of recurrence
//     recurrence_days: string[]; // Specific days (for weekly/monthly)
//   }
// ): string => {
//   const start = moment(parseInt(startTime, 10));
//   const end = moment(parseInt(endTime, 10));
//   const now = moment();

//   // Helper to format time in 12-hour format with AM/PM
//   const formatTime = (time: moment.Moment) => time.format('hh:mm A');

//   // Helper to format date with suffix
//   const formatDate = (date: moment.Moment) => date.format('MMM Do');

//   // Helper to find the next occurrence based on recurrence
//   const getNextOccurrence = () => {
//     if (!recurrence) return null;

//     const { recurrence_type, recurrence_interval, recurrence_days } = recurrence;

//     switch (recurrence_type) {
//       case 'daily':
//         return start.clone().add(recurrence_interval, 'days');

//       case 'weekly':
//         const dayOfWeekNow = now.day();
//         const nextDay = recurrence_days
//           .map((day) => parseInt(day, 10))
//           .find((day) => day > dayOfWeekNow);

//         if (nextDay !== undefined) {
//           return start.clone().day(nextDay);
//         } else {
//           return start.clone().add(recurrence_interval, 'weeks').day(parseInt(recurrence_days[0], 10));
//         }

//       case 'monthly':
//         const currentDay = parseInt(start.format('D'), 10);
//         const nextDayInMonth = recurrence_days
//           .map((day) => parseInt(day, 10))
//           .find((day) => day > currentDay);

//         if (nextDayInMonth !== undefined) {
//           return start.clone().date(nextDayInMonth);
//         } else {
//           return start.clone().add(recurrence_interval, 'months').date(parseInt(recurrence_days[0], 10));
//         }

//       default:
//         return null;
//     }
//   };

//   const nextOccurrence = isRecurring ? getNextOccurrence() : null;

//   // Recurring meeting logic
//   if (isRecurring && nextOccurrence) {
//     const recurrenceType = recurrence?.recurrence_type || 'recurring';

//     if (nextOccurrence.isSame(now, 'day')) {
//       return `${formatTime(start)} - ${formatTime(end)}, today (${recurrenceType}).`;
//     } else if (nextOccurrence.diff(now, 'days') === 1) {
//       return `${formatTime(start)} - ${formatTime(end)}, tomorrow (${recurrenceType}).`;
//     } else {
//       return `${formatTime(start)} - ${formatTime(end)}, ${formatDate(nextOccurrence)} (${recurrenceType}).`;
//     }
//   }

//   // Non-recurring meeting logic
//   if (start.isSame(now, 'day')) {
//     return `${formatTime(start)} - ${formatTime(end)}, today.`;
//   }

//   if (start.diff(now, 'days') === 1) {
//     return `${formatTime(start)} - ${formatTime(end)}, tomorrow.`;
//   }

//   return `${formatTime(start)} - ${formatTime(end)}, ${formatDate(start)}.`;
// };


const formatMeetingTime = (
  startTime: string,
  endTime: string,
  isRecurring: boolean,
  recurrence?: {
    recurrence_type: string; // daily, weekly, monthly
    recurrence_interval: number; // Interval of recurrence
    recurrence_days: string[]; // Specific days (for weekly/monthly)
  }
): string => {
  const start = moment(parseInt(startTime, 10));
  const end = moment(parseInt(endTime, 10));

  // Helper to format time in 12-hour format with AM/PM
  const formatTime = (time: moment.Moment) => time.format("hh:mm A");

  // Format the time range
  const timeRange = `${formatTime(start)} - ${formatTime(end)}`;

  // Determine recurrence type
  const recurrenceType = isRecurring && recurrence ? recurrence.recurrence_type : null;

  // Recurrence message
  const recurrenceMessage = (() => {
    switch (recurrenceType) {
      case "daily":
        return "(Daily)";
      case "weekly":
        return "(Weekly)";
      case "monthly":
        return "(Monthly)";
      default:
        return "";
    }
  })();

  return `${timeRange}\n${recurrenceMessage}`;
};
const isValidUrl = (url: string): boolean => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z0-9][a-z0-9-]*[a-z0-9])?\\.)+[a-z]{2,}|localhost|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
    '(\\:\\d+)?(\\/[-a-z0-9+&@#\\/%?=~_|!:,.;]*)*' + // path
    '(\\?[;&a-z0-9+%#=~_|!:,.;]*)?' + // query string
    '(\\#[-a-z0-9+&@#\\/%=~_|!:,.;]*)?$','i'); // fragment locator
  return !!pattern.test(url);
};
const getQueryParam = (url:string, param:string) => { 
  if(!url){
    return '';
  }
  const urlObj = new URL(url); 
  return urlObj.searchParams.get(param); 
};

const normalizeTimestamp = (timestamp: any): { seconds: number; nanoseconds: number } => {
  return {
    seconds: timestamp.seconds ?? timestamp._seconds ?? 0,
    nanoseconds: timestamp.nanoseconds ?? timestamp._nanoseconds ?? 0,
  };
};

const isTimestampRecent = (timestamp: any): boolean => {
  // Convert the DB timestamp to milliseconds
  const normalTimestamp = normalizeTimestamp(timestamp);
  const dbTimestamp = new Date(normalTimestamp.seconds * 1000 + normalTimestamp.nanoseconds / 1e6);

  // Get the current time
  const currentTime = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentTime.getTime() - dbTimestamp.getTime();
  // Check if the difference is within 30 seconds (30000 milliseconds)
  return timeDifference < 30000;
};

const clearPreviousVideo = (container: HTMLElement | null) => {
  const previousVideos = Array.from(get(container, 'children', ''));
  previousVideos.forEach((child: any) => {
    container?.removeChild(child);
  });
};

const hideDiv = (div: HTMLElement | null) => {
  if (div) {
    div.style.display = 'none';
  }
};

const showDiv = (div: HTMLElement | null) => {
  if (div) {
    div.style.display = 'block';
    div.style.width = '500px';
    div.style.height = '500px';
  }
};


const styleVideoTile = (videoTile: HTMLElement | null) => {
  if (videoTile) {
    videoTile.style.width = '480px';
    videoTile.style.height = '395px';
  }
};

const styleZoomAvatar = (zoomAvatar: HTMLElement | null) => {
  if (zoomAvatar) {
    zoomAvatar.style.textAlign = 'center';
    zoomAvatar.style.paddingTop = '50px';
    zoomAvatar.style.display = 'block';
    zoomAvatar.style.background = '#141414';
  }
};

export { mediaReducer, mediaShape, get, formatMeetingTime, getQueryParam, isValidUrl, isTimestampRecent, clearPreviousVideo,styleVideoTile,styleZoomAvatar, hideDiv, showDiv };
