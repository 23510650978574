import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import './style.css';
import { ReactComponent as Attachment } from '../images/attachment-icon.svg';
import { ReactComponent as DownloadIcon } from '../images/download.svg';
import { ReactComponent as DeleteIcon } from '../images/delted-bin-icon.svg';
import { ReactComponent as SendIcon } from '../images/send-message-button.svg';
import { ReactComponent as SmilyIcon } from '../images/smily-icon.svg';
import ZoomContext from '../../context/zoom-context';
import ZoomVideo, { Participant } from '@zoom/videosdk';
import EmojiPicker, { Theme } from 'emoji-picker-react';
import { downloadMessages } from './chatHelpers';
import ConfirmationalPopup from '../../../../Utils/ConfirmationalPopup';
import { ReactComponent as EditIcon } from '../../../../Images/edit_message.svg';
import { isValidUrl } from '../../Helpers/utils';
import { Spin } from 'antd';
interface Message {
  id: string;
  message: string;
  sender: {
    userId: string;
    name: string;
    avatar: string;
  };
  receiver: {
    userId: string;
    name: string;
  };
  timestamp: number;
  isDirectMessage: boolean;
  file?: {
    name: string;
    size: number;
    type: string;
    fileUrl: string;
  };
  isDeleted: boolean;
  isEdited: boolean;
}
const Chat: React.FC = () => {
  const zmClient = useContext(ZoomContext);
  const participants: Participant[] = zmClient.getAllUser();
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');
  const endOfChatRef = useRef<HTMLDivElement | null>(null);
  const [chatClient, setChatClient] = useState<any>(null);
  const [selectedUserId, setSelectedUserId] = useState<string>('everyone');
  const [users, setUsers] = useState([]);
  const [joined, setJoined] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pendingMessage, setPendingMessage] = useState<string>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [messageToDelete, setMessageToDelete] = useState<Message | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [messageToEdit, setMessageToEdit] = useState<Message | null>(null);
  const [editInput, setEditInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMeetingActive, setIsMeetingActive] = useState<boolean>(true);


  const onUsersChanged = () => {
    const participants = zmClient.getAllUser();
    console.log('participants', participants);
  };
  const isJSON = (e: any) => {
    try {

      const message = JSON.parse(e.message);
      return message;
    } catch (error) {
      return false;
    }
  };

  const onChat = (e: any) => {
    let isJSONMessage = isJSON(e);
    if (isJSONMessage && isJSONMessage.type === 'edit-message') {
      setMessages((prevMessages) => prevMessages.map(msg => 
        msg.id === isJSONMessage.messageId 
          ? { ...msg, message: isJSONMessage.newMessage, isEdited: true , isDeleted: false}
          : msg
      ));
    } else if (isJSONMessage && isJSONMessage.type === 'delete-message') {
      setMessages((prevMessages) => prevMessages.map(msg => 
        msg.id === isJSONMessage.messageId 
          ? { ...msg, message: '', file: undefined, isDeleted: true, isEdited: false }
          : msg
      ));
    } else {
      const newMessage = {
        id: e.id,
        message: e.message,
        sender: e.sender,
        receiver: e.receiver,
        timestamp: e.timestamp,
        file: e.file,
        isDirectMessage: e.sender.name !== 'Everyone' && e.receiver.name !== 'Everyone',
        isDeleted: false,
        isEdited: false,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }
    setMessages((prevMessages) => prevMessages.filter(msg => !msg.isDeleted));
  };

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === 'Closed') {
        setJoined(false);
        setUsers([]);
        setIsMeetingActive(false);
      }
    },
    [zmClient],
  );

  useEffect(() => {
    // Initialize chat client after joining the session
    const client = zmClient.getChatClient();
    setChatClient(client); // Set chat client

    // Event listeners
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('user-added', onUsersChanged);
    zmClient.on('user-removed', onUsersChanged);
    zmClient.on('user-updated', onUsersChanged);
    zmClient.on('chat-on-message', onChat);
    // zmClient.on("active-speaker", onActiveSpeakerChange);
    // zmClient.on("device-change", onDeviceChange);
    // zmClient.on("peer-video-state-change", onPeerVideoStateChange);
    // zmClient.on(`command-channel-message`, onCommandChannelMessage);
    // zmClient.on("active-share-change", onScreenShareChange);

    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('user-added', onUsersChanged);
      zmClient.off('user-removed', onUsersChanged);
      zmClient.off('user-updated', onUsersChanged);
      zmClient.off('chat-on-message', onChat);
      // zmClient.off("active-speaker", onActiveSpeakerChange);
      // zmClient.off("device-change", onDeviceChange);
      // zmClient.off("peer-video-state-change", onPeerVideoStateChange);
      // zmClient.off(`command-channel-message`, onCommandChannelMessage);
      // zmClient.off("active-share-change", onScreenShareChange);
    };
  }, [zmClient]);

  const fetchChatHistory = async (client: any) => {
    try {
      const history = await client.getHistory();

      let deletedMessageIds: string[] = [];
      let editedMessageIds: string[] = [];
      let editedThread: { [key: string]: any[] } = {};

      // First pass: identify deleted and edited messages
      history.forEach((msg: any) => {
        const parsedMessage = isJSON(msg);
        if (parsedMessage) {
          if (parsedMessage.type === 'delete-message') {
            deletedMessageIds.push(parsedMessage.messageId);
          } else if (parsedMessage.type === 'edit-message') {
            editedMessageIds.push(parsedMessage.messageId);
            if (editedThread[parsedMessage.messageId]) {
              editedThread[parsedMessage.messageId].push(parsedMessage);
            } else {
              editedThread[parsedMessage.messageId] = [parsedMessage];
            }
          }
        }
      });

      // Second pass: process regular messages with proper flags
      const processedMessages = history
        .filter((msg: any) => {
          const parsedMessage = isJSON(msg);
          // Filter out the control messages (delete/edit notifications)
          return !parsedMessage || (!parsedMessage.type);
        })
        .map((msg: any) => {
          const isDeleted = deletedMessageIds.includes(msg.id);
          const isEdited = editedMessageIds.includes(msg.id);
          
          let processedMessage = {
            ...msg,
            isDirectMessage: msg.sender.name !== 'Everyone' && msg.receiver.name !== 'Everyone',
            isDeleted,
            isEdited
          };

          if (isEdited && !isDeleted) {
            const edits = editedThread[msg.id];
            if (edits && edits.length > 0) {
              // Get the latest edit
              const latestEdit = edits.sort((a: any, b: any) => b.editedTime - a.editedTime)[0];
              processedMessage.message = latestEdit.newMessage;
            }
          }

          if (isDeleted) {
            processedMessage.message = '';
            processedMessage.file = undefined;
          }

          return processedMessage;
        });

      setMessages(processedMessages);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };
  useEffect(() => {
    if (chatClient) {
      fetchChatHistory(chatClient);
    }
  }, [chatClient]);

  const sendMessage = async () => {
    setShowEmojiPicker(false);
    if (!chatClient) {
      console.error('Chat client is not initialized.');
      return;
    }
    if (input.trim()) {
      try {
        if (!isMeetingActive) {
          console.error('Cannot send message: Meeting is not active.');
          return;
        }

        // Validate selectedUserId
        const isValidUser = participants && participants.some(
          (participant) => participant.userId.toString() === selectedUserId,
        );
        if (selectedUserId !== 'everyone' && !isValidUser) {
          console.error('Invalid target user:', selectedUserId);
          return;
        }

        const userIdToSend =
          selectedUserId === 'everyone' ? selectedUserId : Number(selectedUserId);

        if (selectedUserId === 'everyone') {
          setPendingMessage(input);
          const hasShownConfirmation = sessionStorage.getItem('hasShownConfirmation') === 'true';
          if (!hasShownConfirmation) {
            setIsModalOpen(true);
            return;
          } else {
            await chatClient.sendToAll(input);
            console.log('Message sent to everyone:', input);
            setInput('');
            return;
          }
        }
        await chatClient.send(input, userIdToSend);
        console.log(`Message sent to ${userIdToSend}:`, input);
        setInput('');
      } catch (error) {
        console.error('Error sending chat message:', error);
        if (error instanceof Error) {
          console.error('Error details:', error.message);
        } else {
          console.error('Unexpected error:', JSON.stringify(error));
        }
      }
    } else {
      console.error('Chat message is empty.');
    }
  };

  const handleConfirm = async () => {
    if (pendingMessage) {
      sessionStorage.setItem('hasShownConfirmation', 'true');
      await chatClient.sendToAll(pendingMessage);
      console.log('Message sent to everyone:', pendingMessage);
      setPendingMessage('');
      setInput('');
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setPendingMessage('');
    setIsModalOpen(false);
  };

  useEffect(() => {
    endOfChatRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const deleteAllMessages = () => {
    setMessages([]);
    console.log('All messages deleted');
  };

  const handleEmojiClick = (emoji: { emoji: string }) => {
    console.log('Emoji clicked:', emoji);
    setInput((prevInput) => prevInput + emoji.emoji);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      try {
        if (!isMeetingActive) {
          console.error('Cannot upload file: Meeting is not active.');
          return;
        }

        const userIdToSend = selectedUserId === 'everyone' ? 'everyone' : Number(selectedUserId);
        await chatClient.sendFile(file, userIdToSend);
        console.log(`File sent to ${userIdToSend}:`, file.name);
      } catch (error) {
        console.error('Error sending file:', error);
        if (error instanceof Error) {
          console.error('Error details:', error.message);
        } else {
          console.error('Unexpected error:', JSON.stringify(error));
        }
      }
    } else {
      console.error('No file selected');
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 7000);
  };

  const deleteMessage = (message:Message) => {
    setMessageToDelete(message);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (messageToDelete) {
      await chatClient.send(JSON.stringify({
        type: 'delete-message',
        messageId: messageToDelete.id,
      }), messageToDelete.receiver.userId);
      setMessages((prevMessages) => prevMessages.map(msg => 
        msg.id === messageToDelete.id 
          ? { ...msg, message: '', file: undefined, isDeleted: true }
          : msg
      ));
      setMessageToDelete(null);
    }
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setMessageToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const editMessage = (message: Message) => {
    setMessageToEdit(message);
    setEditInput(message.message);
    setIsEditModalOpen(true);
  };

  const handleEditConfirm = async () => {
    if (messageToEdit) {
      await chatClient.send(JSON.stringify({
        type: 'edit-message',
        messageId: messageToEdit.id,
        editedTime: new Date().getTime(),
        newMessage: editInput,
      }), messageToEdit.receiver.userId);

      setMessages((prevMessages) => prevMessages.map(msg => 
        msg.id === messageToEdit.id 
          ? { ...msg, message: editInput , isEdited: true,editedTime: new Date().getTime()}
          : msg
      ));
      setMessageToEdit(null);
      setEditInput('');
    }
    setIsEditModalOpen(false);
  };

  const handleEditCancel = () => {
    setMessageToEdit(null);
    setEditInput('');
    setIsEditModalOpen(false);
  };

  return (
    <div className='zoom-meeting-chat-container'>
      <div className='zoom-meeting-chat-header'>
        <div className='zoom-meeting-message-container'>
          <span className='zoom-meeting-text'>CHAT</span>
          <div className='zoom-meeting-icons-container'>
            <DownloadIcon
              className='zoom-meeting-icon'
              onClick={() => downloadMessages(messages)}
            />
            {/* <DeleteIcon className='zoom-meeting-icon' onClick={deleteAllMessages} /> */}
          </div>
        </div>
      </div>
      <div className='zoom-meeting-chat-filter'>
        <label>To:</label>
        <select
          onChange={(e) => {
            setSelectedUserId(e.target.value);
            if (e.target.value !== 'everyone') {
              sessionStorage.setItem('hasShownConfirmation', 'false');
            }
          }}
          className=''
        >
          <option value='everyone'>Everyone</option>
          {participants
            .filter((participant) => participant.userId !== zmClient.getCurrentUserInfo()?.userId)
            .map((participant) => (
              <option key={participant.userId} value={participant.userId}>
                {participant.displayName}
              </option>
            ))}
        </select>
      </div>
      {isLoading && (
        <div style={{ textAlign: 'center', backgroundColor: '#141414' }}>
          <Spin />
        </div>
      )}
      <div className='zoom-meeting-chat-window'>
        <div className='zoom-meeting-chat-scroll'>
          {messages.map((msg, index) => {
            const isCurrentUser =
              msg && msg.sender && msg.sender.userId?.toString() === zmClient.getCurrentUserInfo()?.userId?.toString();
            const formattedTime = new Date(msg.timestamp).toLocaleTimeString();

            return (
              <div key={index}>
                <div className={`zoom-meeting-chat-item ${isCurrentUser ? 'sent' : ''}`}>
                  <h4>
                    {isCurrentUser
                      ? `You to ${msg.receiver?.name || 'Unknown'}`
                      : msg.receiver?.userId?.toString() ===
                        zmClient.getCurrentUserInfo()?.userId?.toString()
                      ? `${msg.sender?.name || 'Unknown'} to You`
                      : `${msg.sender?.name || 'Unknown'} to ${msg.receiver?.name || 'Unknown'}`}
                    {msg.isDirectMessage && (
                      <span style={{ fontSize: 'small', color: '#d93f3f' }}> (Direct Message)</span>
                    )}
                  </h4>
                  {msg.file && msg.file.fileUrl ? (
                    <div>
                      <span className='zoom-meeting-file-name'>{msg?.file ? msg?.file.name : msg.message}</span>
                      <span style={{ fontSize: 'small', color: '#d93f3f' }}>
                        ({msg?.file.size} KB)
                      </span>
                      <DownloadIcon 
                        className='zoom-meeting-download-icon' 
                        onClick={async () => {
                          if (msg.file && msg.file.fileUrl) {
                            try {
                              const cancelDownload = await chatClient.downloadFile(msg.id, msg.file.fileUrl);
                            } catch (error) {
                              console.error('Error downloading file:', error);
                            }
                          }
                        }} 
                      />
                    </div>
                  ) : (
                    <>
                    {msg.isDeleted ? (
                      <p>Message deleted</p>
                    ) : messageToEdit?.id === msg.id ? (
                      <>
                      <input
                        type="text"
                        value={editInput}
                        onChange={(e) => setEditInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleEditConfirm()}
                        className="zoom-meeting-input-field-edit"
                      />
                      <button onClick={handleEditConfirm} className='zoom-meeting-edit-confirm-button'>Confirm</button>
                      <button onClick={handleEditCancel} className='zoom-meeting-edit-cancel-button'>Cancel</button>
                      </>
                    ) : (
                      <p>
                        {isValidUrl(msg.message) ? (
                          <a href={msg.message} 
                             target="_blank" 
                             rel="noopener noreferrer">
                            <div className="zoom-meeting-message-text" style={{wordBreak: 'break-word', overflowWrap: 'break-word'}}>
                              {msg.message}
                            </div>
                          </a>
                        ) : (
                          msg.message
                        )}
                        {msg.isEdited && <span style={{ fontSize: 'small', color: '#d93f3f' }}> (Edited)</span>}
                      </p>
                      )}
                    </>
                  )}
                  {isCurrentUser && !msg.isDeleted && (
                    <>
                      { !msg.file &&!messageToEdit?.id  && 
                        <EditIcon
                        className='zoom-meeting-edit-icon'
                        style={{cursor: 'pointer'}}
                        onClick={() => editMessage(msg)}
                      />}
                      <DeleteIcon
                        className='zoom-meeting-delete-icon'
                        style={{cursor: 'pointer'}}
                        onClick={() => deleteMessage(msg)}
                      />
                    </>
                  )}
                </div>
                <div className={`zoom-meeting-chat-time ${isCurrentUser ? 'sent' : ''}`}>
                  <span className='zoom-meeting-timestamp'>{formattedTime}</span>
                </div>
              </div>
            );
          })}
          <div ref={endOfChatRef} />
        </div>
      </div>
      <div className='zoom-meeting-input-container'>
        <div className='zoom-meeting-input-upload'>
          <Attachment
            className='zoom-meeting-icon'
            onClick={() => {
              console.log('Attachment icon clicked');
              if (selectedUserId === 'everyone') {
                alert('Cannot upload files when sending to everyone');
              } else {
                fileInputRef.current?.click();
              }
            }}
          />
          <input
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />
          <input
            type='text'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)}
            placeholder='Type a message...'
            className='zoom-meeting-input-field'
          />
          {showEmojiPicker && (
            <div className='emoji-picker'>
              <EmojiPicker
                onEmojiClick={(emoji: any) => handleEmojiClick(emoji)}
                theme={Theme.DARK}
              />
            </div>
          )}
          <SmilyIcon onClick={() => setShowEmojiPicker((prev) => !prev)} />
        </div>
        <SendIcon className='zoom-meeting-send-icon' onClick={sendMessage} />
      </div>
      <ConfirmationalPopup
        open={isModalOpen}
        data={'Are you sure you want to send this message to everyone?'}
        onCancel={handleCancel}
        onOk={handleConfirm}
      />
      <ConfirmationalPopup
        open={isDeleteModalOpen}
        data={'Are you sure you want to delete this message?'}
        onCancel={handleDeleteCancel}
        onOk={handleDeleteConfirm}
      />
    </div>
  );
};

export default Chat;
