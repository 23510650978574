import React, { useContext, useEffect, useState } from 'react';
import { useLocationStore } from '../../../store/useLocationStore';
import { get } from '../Helpers/utils';
import useDbTrigger from '../../../hooks/useDbTrigger';
import useNavigateToPage from '../../../hooks/useNavigateToPage';
import { AuthContext } from '../../../Context/authContext';
import { auth } from '../../../Firebase/firebase';
import { setGuestToken } from '../Helpers/waitingRoomHelpers';
import { Modal } from 'antd';
import WaitingRoomPopup from '../../ZoomMeeting/MeetingPopUp/WaitingRoom';
import TestSpeaker from '../MeetingPopUp/TestSpeakerModal/TestSpeaker';
import useApiPolling from '../Hooks/useApiPolling';
import { useConferenceCommands } from '../Hooks/useConferenceCommands';
import MeetingPopUp from '../MeetingPopUp';
// import { CloseOutlined } from '@ant-design/icons';

const WaitingRoom: React.FC = () => {
  const locationState = useLocationStore((state) => state.locationState);
  const meetingId = get(locationState, 'state.meetingId', '');
  const session_id = get(locationState, 'state.session_id', '');
  const show_id = get(locationState, 'state.show_id', '');
  const show_name = get(locationState, 'state.show_name', '');
  const show_code = get(locationState, 'state.show_code', '');
  const team_admin = get(locationState, 'state.team_admin', '');
  const viewerFlag = get(locationState, 'state.viewerFlag', '');
  const zoomFlag = get(locationState, 'state.zoomFlag', '');
  const wid = get(locationState, 'state.wid', '');
  const meeting_data = get(locationState, 'state.meeting_data', {});
  const meeting_doc_id = get(meeting_data,'meeting_doc_id','');
  const fromWaitingRoom = get(locationState, 'state.fromWaiting', false);
  const navigate = useNavigateToPage();
  const notifications = useDbTrigger('meeting_participants', true);
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const isGuestUser = get(authValue, 'isMeetingGuest', false);
  const participant_show_user_role_id = get(locationState, 'state.user_role_id', '');

  const [isTestSpeakerModal, setIsTestSpeakerModal] = useState(false);
  const [updatedMeetingDetails, setUpdatedMeetingDetails] = useState(meeting_data);
  const { leaveConference } = useConferenceCommands(
    meetingId,
    show_id,
    participant_show_user_role_id,
    session_id
  );

  useApiPolling(wid);

  const notificationData = notifications?.doc?.data();

  const shouldNavigate =
    // notifications?.doc?.id === wid &&
    notificationData?.meeting_id === meetingId &&
    notificationData?.status === 'allowed' &&
    notificationData?.participant_show_user_role_id === participant_show_user_role_id;

  const isParticipantMatch =
    notificationData?.participant_show_user_role_id === participant_show_user_role_id &&
    notificationData?.meeting_id === meetingId && get(notificationData,'session_id','') === session_id;

  const shouldLeave = isGuestUser
    ? isParticipantMatch && notifications?.type === 'removed'
    : isParticipantMatch && (notificationData?.status === 'host_removed' || notificationData?.status === 'left');

  useEffect(() => {
    if (fromWaitingRoom) {
      // The user is navigated to intermediate page or logged out if he tries to naviagte to waiting room after being allowed.
      if (isGuestUser) {
        auth.signOut();
        navigate('/logoutredirect', { state: { isGuest: true }, replace: true });
      } else {
        navigate('/intermediate', {
          state: {
            show_id,
            show_name,
            show_code,
            team_admin,
            viewerFlag,
            zoomFlag,
            user_role_id: participant_show_user_role_id,
          },
        });
      }
    }
    if (shouldNavigate) {
      setGuestToken(get(authValue, 'accessToken', {}));
      navigate('/zoom-meeting', {
        state: {
          meetingId,
          meeting_doc_id,
          meeting_data: updatedMeetingDetails,
          user_role_id: participant_show_user_role_id,
          fromWaiting: true,
          show_id,
          show_name,
          show_code,
          team_admin,
          viewerFlag,
          zoomFlag,
          session_id
        },
      });
    }
    if (shouldLeave) {
      if (authValue && isGuestUser) {
        auth.signOut();
        navigate('/logoutredirect', { state: { isGuest: true }, replace: true });
      } else {
        navigate('/intermediate', {
          state: {
            show_id,
            show_name,
            show_code,
            team_admin,
            viewerFlag,
            zoomFlag,
            user_role_id: participant_show_user_role_id,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldNavigate, shouldLeave]);

  const triggerTestSpeaker = () => {
    setIsTestSpeakerModal(!isTestSpeakerModal);
  };

  const handleClose = async () => {
    try {
      if (isGuestUser) {
        // For guest user, log out and navigate to logoutredirect page
        await leaveConference(false);
        await auth.signOut();
        navigate('/logoutredirect', { state: { isGuest: true }, replace: true });
      } else {
        // For viewer, navigate to intermediate page
        await leaveConference(false);
        navigate('/intermediate', {
          state: {
            show_id,
            show_name,
            show_code,
            team_admin,
            viewerFlag,
            zoomFlag,
            user_role_id: participant_show_user_role_id,
          },
        });
      }
    } catch (error) {
      console.error('Error during handleClose:', error);
      // Optionally, you can handle the error further, e.g., show a notification
    }
  };

  const maskStyles = { mask: { backdropFilter: 'blur(25px)' } };

  return (
    <div className='meeting-login-container'>
      <div className='meeting-login-div-top'></div>
      <Modal
        open={true}
        footer={null}
        centered={true}
        closable={!isTestSpeakerModal}
        maskClosable={false}
        onCancel={handleClose}
        styles={maskStyles}
        className={'zoom-meeting-modal waiting-room'}
      >
        {/* {!isTestSpeakerModal && (<CloseOutlined
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '16px',
            cursor: 'pointer',
            zIndex: 1000,
            color: '#ffffff',
            padding: '5px',
          }}
          
        />
        )} */}
        {isTestSpeakerModal ? (
          // <TestSpeaker onNext={triggerTestSpeaker} speakerSelect={() => {}} />
          <MeetingPopUp isMeetingRoomOpen={isTestSpeakerModal} activeModalName={'testSpeaker'} closeModal={triggerTestSpeaker}/>
        ) : (
          <WaitingRoomPopup
            triggerTestSpeaker={triggerTestSpeaker}
            meeting_data={updatedMeetingDetails}
            setMeetingData={setUpdatedMeetingDetails}
          />
        )}
      </Modal>
    </div>
  );
};

export default WaitingRoom;
