import { Participant } from '@zoom/videosdk';
import './styles.css';
import { useVideo } from '../../../../Hooks/useVideo';
import { useCallback, useContext } from 'react';
import ZoomContext from '../../../../context/zoom-context';
import { useAudio } from '../../../../Hooks/useAudio';
import Options from './Options';
import { get } from '../../../../Helpers/utils';

interface VideoControlsProps {
  user: Participant;
}
const VideoControls = ({ user }: VideoControlsProps) => {
  const zmClient = useContext(ZoomContext);
  const { isVideoOn } = useVideo(zmClient);
  const { onMicClick } = useAudio(zmClient);
  // const isAudioSwitchedOn = false;
  const isLocalUser = user.userId === zmClient.getCurrentUserInfo()?.userId;
  const isAudioSwitchedOn: boolean =
    (isLocalUser && zmClient.getCurrentUserInfo().audio !== '' && !user.muted) ||
    (!isLocalUser && user.audio !== '' && !user.muted);
  const isVideoSwitchedOn = isVideoOn;

  const onMicrophoneClick = useCallback(async () => {
    if (!isLocalUser) {
      return
    }
    await onMicClick()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocalUser]);

  return (
    <div className='video-controls'>
      <i
        className={`${isAudioSwitchedOn ? 'icon overlay-audio-on' : 'icon overlay-audio-off'}`}
        id={`audio-${user.userId}`}
        onClick={onMicrophoneClick}
      ></i>
      <span className='overlay-text-red prevent-textcopy'>{user.displayName}</span>

      {/* <i
        className={`${
          isVideoSwitchedOn ? 'icon overlay-video-on-tray' : 'icon overlay-video-off-tray'
        }`}
        id={`video-${user.userId}`} onClick={onCamClick}
      ></i> */}
      {/* <i
        className={`${isVideoSwitchedOn ? 'icon fullscreen-on' : 'icon fullscreen-off'}`}
        id={`video-${user.userId}`}
      ></i> */}
      <Options
        userId={user.userId}
        isLocalUser={isLocalUser}
        isVideoSwitchedOn={isVideoSwitchedOn}
        remoteParticipantVideoON={user.bVideoOn}
        userIdentity={get(user,'userIdentity','')}
        displayName={get(user,'displayName','')}
      />
    </div>
  );
};
export default VideoControls;
