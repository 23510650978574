import { useState, useCallback, useEffect, useRef } from 'react';
import { ZoomClient } from '../types/index-types';

const SPEAKER_SWITCH_DELAY = 1000; // 1.5 seconds delay before switching. Updated to 1 second 12th March

interface SpeakerInfo {
  displayName: string;
  userId: number;
}

export function useActiveVideo(zmClient: ZoomClient) {
  const [activeSpeaker, setActiveSpeaker] = useState<number>(0);
  const lastSwitchTime = useRef<number>(Date.now());
  const switchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const speakerQueueRef = useRef<SpeakerInfo[]>([]);

  const onActiveSpeakerChange = useCallback((payload: SpeakerInfo[]) => {
    if (Array.isArray(payload) && payload.length > 0) {
      const currentTime = Date.now();
      const newSpeaker = payload[0];
      
      // Update speaker queue
      speakerQueueRef.current = payload;

      // If we're still within the delay period, don't switch
      if (currentTime - lastSwitchTime.current < SPEAKER_SWITCH_DELAY) {
        return;
      }

      // Clear any pending switch
      if (switchTimeoutRef.current) {
        clearTimeout(switchTimeoutRef.current);
      }

      // Only schedule a switch if it's a different speaker
      if (newSpeaker.userId !== activeSpeaker) {
        switchTimeoutRef.current = setTimeout(() => {
          // Double check if this speaker is still active
          const mostRecentSpeaker = speakerQueueRef.current[0];
          if (mostRecentSpeaker && mostRecentSpeaker.userId === newSpeaker.userId) {
            setActiveSpeaker(newSpeaker.userId);
            lastSwitchTime.current = Date.now();
            console.log(`Switching to speaker: ${newSpeaker.displayName} (${newSpeaker.userId})`);
          }
        }, SPEAKER_SWITCH_DELAY);
      }
    }
  }, [activeSpeaker]);

  useEffect(() => {
    zmClient.on('active-speaker', onActiveSpeakerChange);
    return () => {
      zmClient.off('active-speaker', onActiveSpeakerChange);
      if (switchTimeoutRef.current) {
        clearTimeout(switchTimeoutRef.current);
      }
    };
  }, [zmClient, onActiveSpeakerChange]);

  // Return the current active speaker's user ID
  return activeSpeaker;
}