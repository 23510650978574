import React, {  useState } from 'react';
import './style.scss';
import GetStarted from './GetStarted';
import CheckVideo from './CheckVideo';
import CheckAudio from './CheckAudio';

const DiagnosticsNew = (props: any) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { setOpenDiagnosticsModal } = props;

  const handleNext = (step: number) => {
    setCurrentStep(step);
    if (step === 0) {
      setOpenDiagnosticsModal(false);
    }
  };

  return (
    <div className='diagnostic-new-container'>
      {currentStep === 1 && <GetStarted handleNext={handleNext} />}
      {currentStep === 2 && <CheckVideo handleNext={handleNext} />}
      {currentStep === 3 && <CheckAudio handleNext={handleNext} />}
    </div>
  );
};

export default DiagnosticsNew;
