import { useContext, useEffect, useRef, useState } from 'react';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import './styles.css';
import { Participant, VideoPlayer } from '@zoom/videosdk';
import VideoCell from './VideoCell';
import useParticipantsChange from '../../Hooks/useParticipantsChange';

import handleParticipantUpdates from './handleParticipantUpdates';
import useParticipants from '../../Hooks/useParticipants';
import ContentLoader from 'react-content-loader';
import { get } from '../../Helpers/utils';
const VideoTiles = () => {
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const videoPlayerListRef = useRef<Record<string, VideoPlayer>>({});
  const isUserInConference = zmClient?.getSessionInfo()?.isInMeeting;

  const [participants, setParticipants] = useState(zmClient.getAllUser());
  const participantsRender = useParticipants();
  const [subscribers, setSubscribers] = useState<number[]>(
    participants.filter((user) => user.bVideoOn).map((u) => u.userId),
  );

  const setVideoPlayerRef = (userIdentity: any, element: any) => {
    if (element) {
      videoPlayerListRef.current[`${userIdentity}`] = element;
    }
  };

  useParticipantsChange(zmClient, async (updatedParticipants) => {
    await handleParticipantUpdates(
      updatedParticipants,
      participants,
      mediaStream,
      videoPlayerListRef.current,
      setParticipants,
      setSubscribers,
      setVideoPlayerRef,
    );
  });

  useEffect(() => {
    const checkforExisting = setTimeout(async () => {
      //Check participants with video ON
      if (isUserInConference && !!mediaStream) {
        const subscribedUsers = participants.filter((user:Participant)=> user.bVideoOn)
        for (const index in subscribedUsers) {
          const subscriber: Participant = subscribedUsers[index];
          const userVideo = await mediaStream?.attachVideo(subscriber.userId, 1)
          let video_player: any = document.getElementById(`player_${subscriber?.userIdentity}`);
          //Remove all child components of the video tile
          const childArray = Array.from(get(video_player, 'children', ''));
          if (childArray.length) {
            await mediaStream?.detachVideo(subscriber.userId)
            for (const child of childArray) {
              video_player.removeChild(child);
            }
          }
          video_player?.appendChild(userVideo);
          setVideoPlayerRef(subscriber?.userIdentity, userVideo);
        }
      }
    }, 10);

    return () => {
      clearTimeout(checkforExisting);
    };
  }, [isUserInConference,mediaStream]);

  return (
    <div className='video-tile-wrapper'id="vtray">
      {participantsRender.length ? (
        <video-player-container id='video-player-custom-container'>         
            {participantsRender.map((user: Participant) => {
              return (
                <div
                  className='video-tile-container'
                  id={`video-player-container-div${user.userIdentity}`}
                >
                  <VideoCell user={user} />
                </div>
              );
            })}
          </video-player-container>
      ) : (
        <div>
          <ContentLoader
            width={190}
            height={170}
            backgroundColor='#000005'
            foregroundColor='#1d1d1d'
          >
            <rect x='16' y='16' rx='2' ry='2' width='190' height='125' />
            <rect x='16' y='146' rx='2' ry='2' width='190' height='30' />
          </ContentLoader>
        </div>
      )}
    </div>
  );
};
export default VideoTiles;
