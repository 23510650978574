import React, { useEffect, useState } from 'react';
import './style.scss';
import useParticipants from '../hooks/useParticipants';
import { get } from '../../../Utils/helpers';

const DominantSpeaker = ({
  dominantSpeakerID,
  dominantSpeakerTrack,
  localParticipantID,
  localParticpantTrack,
  localVideoMute,
  localAudioMute,
  troom,
}: any) => {
  const participants = useParticipants(troom);
  const [isTrackEnabled, setIsTrackEnabled] = useState(get(dominantSpeakerTrack,'isEnabled',false));
  const speakerId = !!dominantSpeakerID ? dominantSpeakerID : localParticipantID;
  const dominantSpeaker = participants.find((participant) => participant.sid === dominantSpeakerID) || troom?.localParticipant;
  const dominantSpeakerName = get(dominantSpeaker, 'identity', '').split('_').pop(); // Extracting the username
  const dominantSpeakerAudioTrack = get(dominantSpeaker, 'audioTracks', []);
  const dominantSpeakerVideoTrack = get(dominantSpeaker, 'videoTracks', []);
  const isLocalParticipant = !dominantSpeakerID;
  const track = get(dominantSpeakerVideoTrack.values().next(),'value','').track
  const isScreenShareUser = get(track,'name',0) === 'screen';


  const { isTrackEnabled: audioTrackEnabled } = get(dominantSpeakerAudioTrack,'size',0) > 0 ? dominantSpeakerAudioTrack.values().next().value : { isTrackEnabled: false };
  
  useEffect(() => {
    setIsTrackEnabled(get(dominantSpeakerTrack,'isEnabled',false));
  }, [get(dominantSpeakerTrack,'isEnabled',false),localVideoMute,localAudioMute]);

  const createSpeakerDiv = () => {
    const div = document.createElement('div');
    div.style.height = '100%';
    div.style.aspectRatio = '16/9';
    div.style.width = '100%';
    div.id = `${speakerId}_clone`;
    div.className = 'dominant-speaker';
    if(!isScreenShareUser){
      div.style.transform = 'scaleX(-1)';
    }
    const videoon = get(dominantSpeakerTrack,'isEnabled','');
    const audioon = audioTrackEnabled
    const shouldCreateBackupDiv = isLocalParticipant ? localVideoMute : (!audioon || !videoon); // Create backup div if either audio or video is off
    if (shouldCreateBackupDiv) {
      const backupDiv = document.createElement('div');
      backupDiv.className = 'backup_name_1';
      backupDiv.textContent = dominantSpeakerName; // Set content to dominantSpeakerName
      backupDiv.style.fontWeight = '700'; // Set font weight to 700
      backupDiv.style.fontSize = 'large'; // Set font size to large
      return { backupDiv, speakerDiv: div }; // Return both divs
    }

    return { speakerDiv: div }; // Return only the speaker div if enabled
  };

  const removeExistingSpeakers = (container: HTMLElement) => {
    Array.from(container.getElementsByClassName('dominant-speaker')).forEach((speaker) => {
      container.removeChild(speaker);
    });
    Array.from(container.getElementsByClassName('backup_name_1')).forEach((backup) => {
      container.removeChild(backup);
    });
  };

  const attachTrackToDiv = (div: HTMLDivElement) => {
    if (track) {
      div.appendChild(track.attach());
    }
  };

  useEffect(() => {
    const dominantSpeakerContainer: HTMLElement | null = document.getElementById('video-container');
    if (dominantSpeakerContainer) {
      removeExistingSpeakers(dominantSpeakerContainer);
      const { backupDiv, speakerDiv } = createSpeakerDiv();
      attachTrackToDiv(speakerDiv);

      if (backupDiv) {
        dominantSpeakerContainer.appendChild(backupDiv); // Insert backup div before speaker div
      } else {
        dominantSpeakerContainer.appendChild(speakerDiv);
      }

      const videoTile = speakerDiv.getElementsByTagName('video')[0];
      if (videoTile) {
        videoTile.style.width = '100%';
        videoTile.style.height = '100%';
      }
    }
  }, [dominantSpeakerID, dominantSpeakerTrack, troom, isTrackEnabled, audioTrackEnabled, localVideoMute,localAudioMute]);

  return (
    <div className='dominant-participant'>
      <div
        id='video-container'
        className='video-container'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      ></div>
    </div>
  );
};

export default DominantSpeaker;
