import React, { useEffect, useState, useContext } from 'react';
import backChevron from '../../../Assets/icons/back-chevron.svg';
import menuIcon from '../../../Assets/icons/hamburguer-menu.svg';
import StreamList from './StreamList/StreamList';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { get } from '../Helpers/utils';
import { AuthContext } from '../../../Context/authContext';
import { useLocationStore } from '../../../store/useLocationStore';
import useNavigateToPage from '../../../hooks/useNavigateToPage';
import ListOfMeetings from './Meeting';
import './styles.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ProfileIcon from '../Header/ProfileIcon';
import { firstBy } from 'thenby';

const Intermediate: React.FC = () => {
  
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [activeStreams, setActiveStreams] = useState<any[]>([]);
  const [streamNameEdit, setStreamNameEdit] = useState(false);
  
  const locationState = useLocationStore((state) => state.locationState);
  const show_id = get(locationState, 'state.show_id', '');
  const zoomFlag = get(locationState, 'state.zoomFlag', false);
  const show_code = get(locationState, 'state.show_code', '');
  const show_name = get(locationState, 'state.show_name', '');
  const team_admin = get(locationState, 'state.team_admin', false);
  const viewerFlag = get(locationState, 'state.viewerFlag', false);
  const user_role_id = get(locationState, 'state.user_role_id', ''); 
  const [isStreamListLoading, setIsStreamListLoading] = useState<boolean>(false);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const navigateToPage = useNavigateToPage();

  const handleNavigation = (stream: any) => {
    sessionStorage.setItem("show_id", show_id);
    sessionStorage.setItem('stream_id', get(stream, 'stream_id', ''));
    sessionStorage.setItem('stream_label', get(stream, 'stream_label', ''));
    const state = {
      show_id,
      show_name,
      show_code,
      team_admin,
      // stream_id: get(stream, 'stream_id', ''),
      viewerFlag: viewerFlag,
      zoomFlag: zoomFlag,
      user_role_id:user_role_id,
      // activeStreams: activeStreams,
    };
    navigateToPage(zoomFlag ? '/viewer' : '/showviewer', { state });
  };

  useEffect(() => {
    fetchStreamListData();
  }, []);
  const loaderIcon = <LoadingOutlined style={{ fontSize:20,color:"white" }} spin />;

  const fetchStreamListData = async () => {
    setIsStreamListLoading(true);
    try {
      const data = {
        api: api.streams.listViewerStreams,
        queryParam: { show_id },
      };
      const streams = await backend.fetch(data, get(authValue, 'accessToken', ''));

        if (streams && streams.length) {
          const cleanStream = streams.filter(
              (arr: any, index: any, self: any) =>
                  index === self.findIndex((temp: any) => temp.stream_id === arr.stream_id),
          );
          cleanStream.sort(
              firstBy(function (v: any) {
                  return v.stream_label.toLowerCase();
              }),
          );
          
          setActiveStreams(cleanStream);
        }

    } catch (err) {
      console.error('Error fetching streams:', err);
    } finally {
      setIsStreamListLoading(false);
    }
  };

  const NavigateToPage = () => {
    const uid = get(authValue, 'uid', '');
    navigateToPage('/showselection', { state: { userId: uid } });
  };

  const sideMenuProps = {
    NavigateToPage,
    activeStreams,
    setActiveStreams,
  };

  const onMenuClick = () =>{
    setIsPopupVisible(!isPopupVisible);
  }

  return (
    <div className='intermediate-container show-new-viewer'>
      <div className='intermediate-div-top'>
        <div className='right-header-image'>
          <ProfileIcon />
        </div>
      </div>
      <div
        className={
          `intermediate-sidemenu show-viewer-leftmenu ${
          (streamNameEdit || isPopupVisible) && 
          'intermediate-container-open'
        }`
      }
      >
        <div className='top-content'>
          <div className='menu-toggle'>
            <div className='back-to-home-wrapper' onClick={onMenuClick}>
              <img src={menuIcon} alt='Menu' className='menu-icon' />
              <p className='menu-toggle-label'
              >Menu</p>
            </div>
          </div>

          <div className='menu-toggle' onClick={sideMenuProps.NavigateToPage}>
            <div
              className='back-to-home-wrapper'
              
            >
              <img src={backChevron} alt='' className='back-chevron' />
              <p
                className='menu-toggle-label'
              >
                Back to home
              </p>
            </div>
          </div>

          <div className='intermediate-separator'></div>
        </div>

        {/* <div className="back-to-home" onClick={sideMenuProps.NavigateToPage} style={{ cursor: 'pointer' }}>
          <img src={backChevron} alt="Back" className="back-chevron" />
          <span>Back to home</span>
        </div> */}

        <div className='bottom-content'>
          {/* <div className='intermediate-section'> */}
          <div className='section-title'>STREAMS</div>

          {isStreamListLoading ? (
          <div className='loading-spinner'>
            <Spin
              spinning={true}
              size={'small'}
              tip={'Loading streams...'}
              indicator={loaderIcon}
            /></div>
          ) : (
            <StreamList
              activeStreams={sideMenuProps.activeStreams}
              setActiveStreams={sideMenuProps.setActiveStreams}
              handleNavigation={handleNavigation}
              streamNameEdit={streamNameEdit}
              setStreamNameEdit={setStreamNameEdit}
            />
          )}

          {/* </div> */}

          <div className='intermediate-separator'></div>

          
          <div className='intermediate-section'>
            <div className='section-title'>MEETINGS</div>
            <ListOfMeetings isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} />
          </div>
        </div>

        {/* </div> */}
      </div>
      <div className='intermediate-content'>
        {/* <h2>Welcome to the show!</h2>
        <h1>Select a Stream Room to Start</h1>
        <p>Hover over to the left and get it going</p> */}
        <p className='welcome-text'>Welcome to the show!</p>
        <p className='stream-selection-text'>Select a Stream Room to Start</p>
        <p className='hover-instruction-text'>Hover over to the left and get it going</p>
      </div>
      <div className='intermediate-div-bottom'></div>
    </div>
  );
};

export default Intermediate;