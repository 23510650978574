import { Tooltip } from 'antd';
import React, { useContext } from 'react';
import { ReactComponent as ShareOff } from '../images/share-off.svg';
import { ReactComponent as ShareOn } from '../images/share-on.svg';
import { ActionType } from '../../types/header-actiontypes';
import { useScreenShare } from '../../Hooks/useScreenShare';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';

const ScreenShare: React.FC<{
  isScreenShareOn: boolean;
  toggleHeaderIcons: (action: ActionType) => void;
  setActiveComponent: (arg: string) => void;
}> = ({ isScreenShareOn, toggleHeaderIcons, setActiveComponent }) => {
  const zoomClient = useContext(ZoomContext);
  const { onScreenShareClick } = useScreenShare(zoomClient, setActiveComponent, toggleHeaderIcons);
  const { mediaStream } = useContext(ZoomMediaContext);

  const handleScreenShareClick = () => {
    if (!isScreenShareOn) {
      toggleHeaderIcons('ScreenShare');
      onScreenShareClick();
    } else {
      mediaStream?.stopShareScreen();
    }
  };

  return (
    <Tooltip placement='bottom' title={'Share screen'}>
      <div className='header-buttons shareIcon-icons' onClick={handleScreenShareClick}>
        {isScreenShareOn ? <ShareOn /> : <ShareOff />}
      </div>
    </Tooltip>
  );
};

export default ScreenShare;
