import React, { useContext, useState } from 'react';
import Stream from '../../Images/stream.png';
import './StreamList.css';
import { auth } from '../../../../Firebase';
import streamEditIcon from '../../../../Images/stream_edit.svg';
import streamPolygon from '../../../../Assets/icons/stream-polygon.svg';
import crossTick from '../../../../Images/red-cancel.png';
import greeTick from '../../../../Images/green-tick.png';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import { get } from '../../../../Utils/helpers';
import { AuthContext } from '../../../../Context/authContext';

interface StreamListProps {
  activeStreams: any[];
  handleNavigation: (stream: any) => void;
  setActiveStreams: (streams: any) => void;
  streamNameEdit: boolean;
  setStreamNameEdit: (isEdit: boolean) => void;
}

const StreamList: React.FC<StreamListProps> = ({ activeStreams, handleNavigation, setActiveStreams, streamNameEdit, setStreamNameEdit }) => {

  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  const [streamNameEditBtnDisabled, setStreamNameEditBtnDisabled] = useState(false);
  const [updatedStreamId, setUpdatedStreamId] = useState('');
  const [updatedStreamLabel, setUpdatedStreamLabel] = useState('');
  const [endorLeaveConferenceLoading, setEndorLeaveConferenceLoading] = useState(false);
  const [streamLoader, setStreamLoader] = useState(false);
  const [updateStreamLabelLoader, setUpdateStreamLabelLoader] = useState(false);
  const streamLoaderIcon = <Loading3QuartersOutlined style={{ fontSize:15,color:"white" }} spin />;
  const [streamLabelUpdateMsg, setStreamLabelUpdateMsg] = useState('');
  const [updatedStreamLabelToAll, setUpdatedStreamLabelToAll] = useState(false);
  const [isVCHost, setIsVCHost] = useState(false);
  const [updateStreamLabelErrorModal, setUpdateStreamLabelErrorModal] = useState(false)
  
  const onStreamSubmit = (e: any) => {
    e.preventDefault();
    return false;
  };

  const handleStreamName = async (stream: any) => {
    const { stream_id = '', stream_label = '' } = stream;
    setUpdatedStreamId(stream_id);
    setUpdatedStreamLabel(!!updatedStreamLabel ? updatedStreamLabel : stream_label);
  };

  const handleUpdateStreamLabel = async () => {

    if (!updatedStreamLabel) return;
    updatedStreamLabel.trim();
    if (updatedStreamLabel === '') return;

    try {
        const data = {
            api: api.streams.update,
            urlParam: updatedStreamId,
            payLoad: {
                update_data: { stream_label: updatedStreamLabel },
            },
        };
        setUpdateStreamLabelLoader(true);
        await backend.save(data, get(authValue, 'accessToken', {}));

        setUpdatedStreamId("");
        setStreamLabelUpdateMsg("");
        setUpdatedStreamLabelToAll(!updatedStreamLabelToAll);
        const updateStreams = activeStreams.map((stream: any) => {
            if (stream.stream_id === updatedStreamId) {
                stream.stream_label = updatedStreamLabel;
            }
            return stream;
        });
        if (isVCHost) sessionStorage.setItem('stream_label', updatedStreamLabel);
        setActiveStreams(updateStreams);
    } catch (err: any) {
        const msg = 'Error while updating stream  labe!';
        setUpdatedStreamLabel('')
        setUpdateStreamLabelErrorModal(true);
        console.log(err);
        if (err.errMessage) {
            setStreamLabelUpdateMsg('ERROR: ' + err.errMessage);
        } else {
            setStreamLabelUpdateMsg(msg);
        }
    } finally {
        setUpdateStreamLabelLoader(false);
    }
};

  return (
    <div className='stream-list'>
      <ul className='streams-container'>
        {activeStreams
          .slice()
          .sort((a, b) => a.stream_label.toLowerCase().localeCompare(b.stream_label.toLowerCase()))
          .map((stream) => {
            let streamId = stream.stream_id;
            let created_user_email = stream.created_user_email;
            const authUserEmail = auth.getUserEmail();
            return <li
              key={streamId}
              data-stream_id={streamId}
              data-stream_label={stream.stream_label}
              onClick={updatedStreamId !== streamId ? () => handleNavigation(stream) : undefined}
              className={`sidemenu-item stream-selected${
                ((sessionStorage.getItem('stream_label') === stream.stream_label) && (sessionStorage.getItem('stream_id') === streamId) ) ? '-active' : '-inactive'
              }`}
              style={
                endorLeaveConferenceLoading || streamLoader
                  ? { pointerEvents: 'none' }
                  : {}
              }
            >
              <div className='sidemenu-item-icon stream-button'>
                {(stream.stream_label === sessionStorage.getItem('stream_label') && streamLoader) ||
                ((updatedStreamId === stream.stream_id) && updateStreamLabelLoader) ? (
                  streamLoaderIcon
                ) : (
                  <img src={streamPolygon} alt='img' />
                )}
              </div>
              {created_user_email === authUserEmail && !streamNameEdit && (
                <span id={streamId} className='sidemenu-hide-collapsed sidemenu-stream-name'>
                  { created_user_email === authUserEmail ? !!updatedStreamLabel ? updatedStreamLabel : stream.stream_label : stream.stream_label }
                </span>
              )}
              {created_user_email !== authUserEmail && ( <span id={streamId} className='sidemenu-hide-collapsed sidemenu-stream-name'>
                  { created_user_email === authUserEmail ? !!updatedStreamLabel ? updatedStreamLabel : stream.stream_label : stream.stream_label }
                </span>) }
              {created_user_email === authUserEmail && streamNameEdit && (
                <form onSubmit={onStreamSubmit} style={{ position: 'relative', flex: 1 }}>
                  <span
                    className='stream-label-edit-parent'
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >{console.log("updatedStreamLabel", updatedStreamLabel)}
                    <input
                      type='texbox'
                      className='stream-label-edit-box'
                      value={updatedStreamLabel}
                      maxLength={20}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const streamNameRegex = /^[A-Za-z0-9\_]+$/;
                        e.preventDefault();
                        e.stopPropagation();
                        if (streamNameRegex.test(value)) {
                          setUpdatedStreamLabel(value);
                        } else if (value === '') {
                          setUpdatedStreamLabel('');
                        }
                        if (value === stream.stream_label) {
                          setStreamNameEditBtnDisabled(true);
                        } else {
                          setStreamNameEditBtnDisabled(false);
                        }
                      }}
                    />
                    <img
                      src={crossTick}
                      className='cancelTick-img cursor-pointer'
                      alt='cancel'
                      onClick={() => {
                        setUpdatedStreamId('');
                        setStreamNameEdit(false)
                        setStreamLabelUpdateMsg('');
                        setUpdatedStreamLabel('')
                      }}
                    />
                    <button
                      onClick={() => {
                        setStreamNameEdit(false)
                        handleUpdateStreamLabel()
                      }}
                      className='stream-label-submit cursor-pointer'
                      disabled={!/^[A-Za-z0-9\_]+$/.test(updatedStreamLabel) || updatedStreamLabel === '' ||  updatedStreamLabel === stream.stream_label  || streamNameEditBtnDisabled}
                    >
                      <img src={greeTick} className='greenTick-img' alt='Save' />
                    </button>
                  </span>
                  {!streamLabelUpdateMsg && (
                    <span className='stream-label-helper-text'>
                      Note: Accepts only letters, numbers and underscore{' '}
                    </span>
                  )}
                  {streamLabelUpdateMsg && (
                    <span className='stream-label-error-text'>{streamLabelUpdateMsg}</span>
                  )}
                </form>
              )}
              {created_user_email === authUserEmail && !streamNameEdit && (
                <span className='sidemenu-hide-collapsed editPencil-padding'>
                  <img
                    src={streamEditIcon}
                    className='editPencil-img float-right'
                    style={{ width: '20px', height: '20px' }}
                    alt='Edit'
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setStreamNameEdit(true)
                      handleStreamName(stream);
                      setStreamNameEditBtnDisabled(true);
                    }}
                  />
                </span>
              )}
            </li>
          })}
      </ul>
    </div>
  );
};

export default StreamList;
