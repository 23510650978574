import { useEffect, useState, useContext, useCallback } from 'react';
import { Button, notification } from 'antd';
import _ from 'lodash';
import { get, isTimestampRecent } from '../../Helpers/utils';
import serviceApi from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import { AuthContext } from '../../../../Context/authContext';
import useDbTrigger from '../../../../hooks/useDbTrigger';
import { useLocationStore } from '../../../../store/useLocationStore';
import zoomContext from '../../context/zoom-context';

interface ModalProps {
  openParticipantsPanel: () => void;
  showParticipants: string | null;
  setIsNotificationOpen: (isOpen: boolean) => void; // Ensure this line is present

}

const ParticipantsNotification = ({ openParticipantsPanel, showParticipants, setIsNotificationOpen }: ModalProps) => {
  const zmClient = useContext(zoomContext);
  const participantJoinedSession = zmClient?.getSessionInfo().isInMeeting;
  const notifications = useDbTrigger('meeting_participants', participantJoinedSession);
  const [participants, setParticipants] = useState<any[]>([]);
  const [showParticipantsNotification, setShowParticipantsNotification] = useState(false);
  const locationState = useLocationStore((state) => state.locationState);
  const meetingId = get(locationState, 'state.meetingId', '');
  const showId = get(locationState, 'state.show_id', '');
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [api, contextHolder] = notification.useNotification();
  const accessToken = get(authValue, 'accessToken', '');

  const allowGuestUsers = async (participant: any) => {
    try {
      const data = {
        api: serviceApi.meetings.allowGuest,
        payLoad: {
          guestUserId: participant.participant_show_user_role_id,
          meetingId,
          show_id: showId,
        },
      };
      await backend.save(data, accessToken);
    } catch (error: any) {
      notification.error({
        message: 'Error allowing guest user',
        description: error.message || 'An unexpected error occurred.',
      });
    }
  };

  const fetchWaitingGuests = useCallback(async () => {
    try {

      if(showParticipants === 'participants' && !participants.length){
        Promise.resolve()
      }
      const accessToken = get(authValue, 'accessToken', '');
      const data = {
        api: serviceApi.meetings.getMeetingGuests,
        queryParam: {
          meetingId,
          show_id: showId,
        },
      };

      const response = await backend.fetch(data, accessToken);
      const alreadyJoinedParticipants = response.filter((user: any) => user.status === 'waiting');

      alreadyJoinedParticipants.forEach((participant: any) => {
        if(!Boolean(sessionStorage.getItem('sidepanel')) && showParticipants !== 'participants' && isTimestampRecent(get(participant,'updated_at',{}))){
          openNotification(participant);
        }
      });
      setParticipants((prev) =>
        _.uniqBy([...prev, ...alreadyJoinedParticipants], 'participant_show_user_role_id'),
      );
      // setShowParticipantsNotification(true);
    } catch (error: any) {
      notification.error({
        message: 'Error fetching waiting guests',
        description: error.message || 'An unexpected error occurred.',
      });
    }
  }, [authValue, meetingId, showId]);

  const handleAdmitUsers = async (participantSelected: any) => {
    try {
      setShowParticipantsNotification(false);
      if (participants.length > 1) {
        const promises = participants.map((participant: any) => allowGuestUsers(participant));
        await Promise.all(promises);
      } else {
        await allowGuestUsers(participantSelected);
      }
    } catch (error: any) {
      notification.error({
        message: 'Error allowing guest user',
        description: error.message || 'An unexpected error occurred.',
      });
    } finally {
      api.destroy();
      setParticipants([]);
      setIsNotificationOpen(false);
    }
  };

  const openNotification = (Participant: any) => {
  setIsNotificationOpen(true);
    const btn = (
      <div className='zoom-meeting-notification-btn-container'>
        <Button
          className='see-waiting-room-button'
          onClick={() => {
            setIsNotificationOpen(false);
            openParticipantsPanel();
            api.destroy();
          }}
        >
          See Waiting Room
        </Button>
        <Button
          className='admit-button'
          onClick={() => {
            handleAdmitUsers(Participant);
            setIsNotificationOpen(false);
          }}
        >
          Admit
        </Button>
      </div>
    );

    if (
      !participants.find(
        (participants: any) =>
          participants.participant_show_user_role_id === Participant.participant_show_user_role_id,
      )
    )
      api.open({
        message: '',
        description: `${Participant.name} ${
          participants.length > 1 ? `and ${participants.length - 1} others` : ''
        } has entered the waiting room for this meeting.`,
        placement: 'bottom',
        btn,
        className: 'participants-notification-modal',
        duration: null,
        style: { bottom: '170px',position:'fixed', color:'#fff' },
      });
  };

  useEffect(() => {
    if (notifications?.doc?.data()) {
      const { status, meeting_id, updated_at } = notifications.doc.data();
      if (meeting_id === meetingId && status === 'waiting' && notifications.type !== 'removed') {
        if (
          showParticipants !== 'participants' &&
          !Boolean(sessionStorage.getItem('sidepanel')) &&
          !participants.find(
            (participants: any) =>
              participants.participant_show_user_role_id ===
              notifications?.doc?.data().participant_show_user_role_id,
          ) && isTimestampRecent(updated_at)
        ) {
          openNotification(notifications?.doc?.data());
        }
        setParticipants((prev) =>
          _.uniqBy([...prev, notifications.doc.data()], 'participant_show_user_role_id'),
        );
        // setShowParticipantsNotification(true);
      }
    }
  }, [notifications, meetingId, showParticipants]);

  useEffect(() => {
    if (showParticipants === 'participants') {
      api.destroy();
    }
  }, [showParticipants]);

  useEffect(() => {
    fetchWaitingGuests();
  }, [fetchWaitingGuests,]);

  return <div>{contextHolder}</div>;
};

export default ParticipantsNotification;
