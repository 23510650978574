export const downloadMessages = (messages: any[]) => {
  const formattedMessages = messages
    .map((msg) => {
      const senderName = msg.sender.name;
      const messageText = msg.message;
      const timestamp = new Date(msg.timestamp).toLocaleString();
      return `${timestamp} - ${senderName}: ${messageText}`;
    })
    .join('\n');

  // Create a Blob from the formatted messages
  const blob = new Blob([formattedMessages], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);

  // Create a link element and trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = 'chat_messages.txt';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
