/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react';
import ZoomVideo, { ConnectionState, ReconnectReason, type VideoPlayerContainer, type VideoPlayer, Participant } from '@zoom/videosdk';
import produce from 'immer';
import { DOMAttributes, DetailedHTMLProps, HTMLAttributes, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Spin, notification, Layout } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { trace } from "firebase/performance";
import { performance } from "../../Firebase/firebase";
import { firstBy } from 'thenby';


import { get, isEmpty } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import { turnApiUrl, CHIME_VALUES, MULTI_STREAM_VIEWER, FEATURE_NAME } from '../../Utils/constants';
import { auth } from '../../Firebase';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { fetchFeatures, showActiveUsers } from '../../Service/show';
import { isGuestAuthenticated, logOutGuestUser } from '../../Utils/guestHelpers';

import Diagnostics from './Diagnostics';
import Sidemenu from './Sidemenu';
import HeaderView from './HeaderView';

import useCanvas from './hooks/useCanvas';

import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context'

import './zoomshowviewer.css';
import VideoTray from './VideoTray';
import { useShowspeakerStore, useViewerSlice, useWatermarkStore } from '../../store';
import GuestInvite from './GuestInvite/GuestInvite';
import PublisherPanel from './PublisherPanel/PublisherPanel'
import ShowChat from './Chat/Index';
import GuestWaitingModal from '../GuestWaitingModal';
import {createGuestSessions, isGuestAllowed} from './GuestInvite/guestHelpers';
import useGuestSessions from './GuestInvite/useGuestSessions';
import HudStats from '../NewShowViewer/HudStats';
import ShowStream from './ShowStream';
import Watermark from './WaterMark';
import useConferenceCommands from './hooks/useConferenceCommands';
import useWhiteBoard from './hooks/useWhiteBoard';
import useLockedGuestsList from '../ZoomViewer/GuestInvite/useLockedGuests';
import Encoder from './Encoder';
import useCommandChannel from './hooks/useCommandChannel';
import Stream from './ShowStream/Stream';
import { useLocationStore } from '../../store/useLocationStore';
import useNavigateToPage from '../../hooks/useNavigateToPage';
import useShareGuestSessions from './GuestInvite/useShareGuestSessions';
const chimeAudioUrl = require('../../Assets/sounds/chime.mp3');


const { Header, Sider, Content } = Layout;


const mediaShape = {
    audio: {
        encode: false,
        decode: false,
    },
    video: {
        encode: false,
        decode: false,
    },
    share: {
        encode: false,
        decode: false,
    },
};

const mediaReducer = produce((draft, action) => {
    switch (action.type) {
        case 'audio-encode': {
            draft.audio.encode = action.payload;
            break;
        }
        case 'audio-decode': {
            draft.audio.decode = action.payload;
            break;
        }
        case 'video-encode': {
            draft.video.encode = action.payload;
            break;
        }
        case 'video-decode': {
            draft.video.decode = action.payload;
            break;
        }
        case 'share-encode': {
            draft.share.encode = action.payload;
            break;
        }
        case 'share-decode': {
            draft.share.decode = action.payload;
            break;
        }
        case 'reset-media': {
            Object.assign(draft, { ...mediaShape });
            break;
        }
        default:
            break;
    }
}, mediaShape);

const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#0958d9',
};

const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#1677ff',
};

declare global {
    interface Window {
        webEndpoint: string | undefined;
        zmClient: any | undefined;
        mediaStream: any | undefined;
        crossOriginIsolated: boolean;
        ltClient: any | undefined;
        logClient: any | undefined;
    }
}

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ['video-player']: DetailedHTMLProps<HTMLAttributes<VideoPlayer>, VideoPlayer> & { class?: string };
            ['video-player-container']: CustomElement<VideoPlayerContainer> & { class?: string };
        }
    }
}

const sessionArray:any = [];
const ZoomShowViewerDashboard = () => {
    const authValue = get(useContext(AuthContext), 'currentUser.user', {});
    const navigationType = useNavigationType();

    const navigateToPage = useNavigateToPage();
    const locationState = useLocationStore((state) => state.locationState);
    const show_id = get(locationState,'state.show_id','');
    const show_name = get(locationState,'state.show_name','');
    const show_code = get(locationState,'state.show_code','');
    const team_admin = get(locationState,'state.team_admin',false);
    const stream_id = get(locationState,'state.stream_id',false);
    const guestLoader = get(locationState,'state.guestLoader',false);
    const sessionStreamId = sessionStorage.getItem('stream_id') ?? '';
    
    const guestUid = get(locationState, 'state.guestUid', '');
    const guestDisplayName = get(locationState, 'state.guestDisplayName', '');
    const isGuestViewer = get(locationState, 'state.isGuestViewer', false);

    //full screen
    const fullScreenHandler = useFullScreenHandle();

    const zmClient = useMemo(() => ZoomVideo.createClient(), []);

    // const { sdk, generateVideoSDKJWT } = useZoomSlice();

    const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
    const [mediaStream, setMediaStream] = useState<MediaStream | null | any>(null);
    const mediaContext: any = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);

    //Feature Flags
    const [featuresList, setFeaturesList] = useState()

    const [ipAddress, setIpAddress] = useState('');

    const [showId, setShowId] = useState('');

    const [globalGuestAutoAllow, setGlobalGuestAutoAllow] = useState(false);

    const [showDiagnostics, setShowDiagnostics] = useState(false);
    const [iceServers, setIceServers] = useState([] as any);

    const [gLoader, setGloader] = useState(guestLoader);
    const loaderIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
    const [redIcon, setRedIcon] = useState(false);
    const [open, setOpen] = useState(false);

    const [streamList, setStreamList] = useState<any[]>();
    const [activeStreams, setActiveStreams] = useState([] as any);

    const [stream, setStream] = useState({} as any);
    const [streamKey, setStreamKey] = useState(Math.random());
    const [subStreamKey, setSubStreamKey] = useState(Math.random());

    const [streamLabel, setStreamLabel] = useState('');
    const [updatedStreamId, setUpdatedStreamId] = useState('');
    const [updatedStreamLabel, setUpdatedStreamLabel] = useState('');
    const [streamLabelUpdateMsg, setStreamLabelUpdateMsg] = useState('');
    const [updateStreamLabelErrorModal, setUpdateStreamLabelErrorModal] = useState(false)
    const [updatedStreamLabelToAll, setUpdatedStreamLabelToAll] = useState(false);
    const [isStreamSelected, setIsStreamSelected] = useState(sessionStreamId !== '');


    const guestStreams: any = []

    const [endorLeaveConferenceLoading, setEndorLeaveConferenceLoading] = useState(false);
    const [fetchStreamListLoader, setFetchStreamListLoader] = useState(false);
    const [updateStreamLabelLoader, setUpdateStreamLabelLoader] = useState(false);

    const [guestInviteEnabledforStream, setGuestInviteEnabledforStream] = useState(false);

    const leftBarRef = useRef<HTMLDivElement>(null);

    const [showMultiStreamViewer, setShowMultiStreamViewer] = useState(false);

    // const [watermarkDetails, setWatermarkDetails] = useState({} as any);
    const waterMarkTempDetails = useRef('');
    const guestSessionDetailsDetails = useRef('');
    const [isPublisher, setIsPublisher] = useState(false);
    const [watermarkFeatureIncluded, setWatermarkFeatureIncluded] = useState(false);
    const waterMarkFeatureIncludedRef = useRef(false);
    const [chatLoaded, setChatLoaded] = useState(false)
    const [encoderFeatureIncluded, setEncoderFeatureIncluded] = useState(false);
    const [isTimeCodeFeatureIncluded, setTimeCodeFeatureIncluded] = useState(false);
    const [isGuestInviteIncluded, setGuestInviteIncluded] = useState(false);

    const [user_imageurl, setProfileImageUrl] = useState('');
    const [user_chime_preferance, setUserChimePreferance] = useState(false);

    const [statsModalOpen, setStatsModalOpen] = useState(false);
    const [activeStreamStatus, setActiveStreamStatus] = useState(false);
    const [activeStreamExists, setActiveStreamExists] = useState(false);
    const [millicastView, setMillicastView] = useState(null as any);
    const [loading, setLoading] = useState(false);
    const {setWatermarkDetails} = useWatermarkStore();
    const { rightActivePanel } = useViewerSlice();
    const [videoTileLoader, setVideoTileLoader] = useState(false);

    let showVcIdref = useRef("")

    //popup
    const [showPopup, setShowPopup] = useState(false);

    const alreadyJoinedGuests = useRef<number[]>([])

    const [isDND, _setMyDND] = useState(false);
    const myDNDRef = useRef(isDND);
    const setMyDND = (data: boolean) => {
        myDNDRef.current = data;
        _setMyDND(data);
    };

    const lockedList :never[] = useLockedGuestsList(mediaStream,isGuestInviteIncluded);
    const {updateGuestSession,getGuestSession} = useGuestSessions(show_id)
    
    const {openChatPanel,closeRightPanel } = useViewerSlice();
    const { setShowSpeakerViewer } = useShowspeakerStore()

    const [clearChatFlag, setclearChatFlag] =  useState(false);

    interface ComponentState {
        chatWindow: boolean;
        closeDisable: boolean;
        chatDisable: boolean;
        users: boolean;
        closeUsers: boolean;
        openUsers: boolean;
        annotationChatWindow: boolean;
        annotationCloseDisable: boolean;
        annotationChatDisable: boolean;
        showPublisherPanel: boolean;
        showPublisherPanelCloseDisable: boolean;
        showGuestInvite: boolean;
        showGuestInviteCloseDisable: boolean;
        watermarkPanel: boolean;
        watermarkDetails: any;
        hudStatsDisplay: boolean;
        encoder: boolean;
    }

    const [rpstate, setRPState] = useState<ComponentState>({
        chatWindow: false,
        closeDisable: false,
        chatDisable: true,
        users: false,
        closeUsers: false,
        openUsers: true,
        annotationChatWindow: false,
        annotationCloseDisable: false,
        annotationChatDisable: true,
        showPublisherPanel: false,
        showPublisherPanelCloseDisable: false,
        showGuestInvite: false,
        showGuestInviteCloseDisable: false,
        watermarkPanel: false,
        watermarkDetails: { /* Initial watermark details */ },
        hudStatsDisplay: false,
        encoder: false,
    });


    const [isVCHost, setIsVCHost] = useState(false);
    const [volumeMute, setVolumeMute] = useState(false);
    // const [joinedParticipants,setJoinedParticipants]=useState(zmClient?.getAllUser().map((participant:Participant) => participant?.userIdentity))
    const joinedParticipants = useRef(zmClient?.getAllUser().map((participant:Participant) => participant?.userIdentity))
    const {leaveConference} =useConferenceCommands(zmClient,showVcIdref.current,isGuestInviteIncluded,watermarkFeatureIncluded,encoderFeatureIncluded);
    const { shareGuestSessions } = useShareGuestSessions(zmClient);

    useEffect(() => {
        const alertUser  = (event:any) => {
          event.preventDefault()
          event.returnValue = ''
        }
        const handleEndConference = (event:any) => {
          leaveConference();
        };
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', handleEndConference)
        return () => {
          window.removeEventListener('beforeunload', alertUser)
          window.removeEventListener('unload', handleEndConference)
        }
      }, [])
    
    const getAutoAllowStatus = async () => {
      try {
        if (!stream_id) {
          return;
        }
        const data = {
          api: api.streams.getAutoAllowStatus,
          queryParam: { stream_id: stream_id },
        };
        const result = await backend.fetch(data, get(authValue, 'accessToken', {}));

        if (!get(result, 'autoAllowStatus', false)) {
          setVolumeMute(true)
          setOpen(true);
        } else {
          sessionStorage.setItem('auto_allow', get(result, 'autoAllowStatus', false));
        }
        setTimeout(() => {
          setGloader(false);
        }, 1500);
        //setStatus(result.autoAllowStatus);
      } catch (err) {
        console.log(err);
      }
    };
    
      useEffect(() => {
        // console.log("useEffect-isGuest", isGuest);
        const identity = `guest#${guestUid}_${guestDisplayName}`
        let guestSessions = sessionStorage.getItem('allowedGuest') || {};
        let allowedGuestSession =
          typeof guestSessions === 'string' ? JSON.parse(guestSessions) : guestSessions;
        if (isGuestViewer === true) {
          sessionStorage.setItem('stream_id', stream_id);
          sessionStorage.setItem('stream_label', streamLabel);
    
          if (!!allowedGuestSession && allowedGuestSession[identity.slice(0,47)]?.status === 'allowed') {
            setGloader(false);
            return;
          } else if (allowedGuestSession[identity.slice(0,47)]?.status === 'waiting') {
            setGloader(false);
            setOpen(true);
            setVolumeMute(true);
          } else {
            setVolumeMute(true);
            getAutoAllowStatus();
          }
        } else {
          return;
        }
      }, []);

    const fetchIceServers = async () => {
        try {
            // setLoading(true);
            const response = await fetch(turnApiUrl, {
                method: 'PUT',
            });

            if (response.status >= 300) {
                throw new Error('TURN API return unexpected status ' + response.status);
            }

            const result = await response.json();
            const { iceServers = [] } = result.v;

            setIceServers(iceServers);
        } catch (err: any) {
            const msg = 'Error while pulling ICE Servers !';
            console.error(msg);
        }
    };

    /*
    **************************
    * Header Actions
    **************************
    */

    const handleLogoClick = async () => {
        handleCloseWatermarkPanelWindow();
        await handelVideoConfEnd();
        const user = auth.getUser();
        const uid = get(user, 'uid', '');
        navigateToPage('/showselection', { state: { userId: uid } },true);
    };

    const handleUpdateStreamLabel = async () => {
        leftBarRef.current?.classList.add('left-bar-temp-width');

        if (!updatedStreamLabel) return;
        updatedStreamLabel.trim();
        if (updatedStreamLabel === '') return;

        try {
            const data = {
                api: api.streams.update,
                urlParam: updatedStreamId,
                payLoad: {
                    update_data: { stream_label: updatedStreamLabel },
                },
            };
            setUpdateStreamLabelLoader(true);
            await backend.save(data, get(authValue, 'accessToken', {}));

            setUpdatedStreamId("");
            setStreamLabelUpdateMsg("");
            setUpdatedStreamLabelToAll(!updatedStreamLabelToAll);
            const updateStreams = activeStreams.map((stream: any) => {
                if (stream.stream_id === updatedStreamId) {
                    stream.stream_label = updatedStreamLabel;
                }
                return stream;
            });
            if (isVCHost) sessionStorage.setItem('stream_label', updatedStreamLabel);
            setActiveStreams(updateStreams);
            leftBarRef.current?.classList.remove('left-bar-temp-width');
        } catch (err: any) {
            const msg = 'Error while updating stream  labe!';
            setUpdatedStreamLabel('')
            setUpdateStreamLabelErrorModal(true);
            console.log(err);
            if (err.errMessage) {
                setStreamLabelUpdateMsg('ERROR: ' + err.errMessage);
            } else {
                setStreamLabelUpdateMsg(msg);
            }
            leftBarRef.current?.classList.add('left-bar-temp-width');
        } finally {
            setUpdateStreamLabelLoader(false);
        }
    };

    const onStreamSubmit = (e: any) => {
        e.preventDefault();
        return false;
    };

    const isUserInvited = async (stream_vc_room_id: any) => {
        if (isGuestViewer) {
            return true;
        }

        try {
            const data = {
                api: api.vc.getVCRoomParticipant,
                queryParam: {
                    show_id,
                    show_vc_room_id: stream_vc_room_id,
                },
            };

            const participant: any = await backend.fetch(data, get(authValue, 'accessToken', {}));
            if (participant) {
                return true;
            } else {
                return false;
            }
        } catch (err: any) {
            throw err;
        }
    };

    const fullScreenVideo = async () => {
        const elem: any = document.getElementById('video-container');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    };



    const fetchVCRoomDetails = async (stream_id: string) => {
        try {
            let input: any = "";
            const isGuest = isGuestAuthenticated();
            if (isGuest) {
                input = get(guestStreams[0], '0.vcRoomId', '');
            } else {
                input = sessionStorage.getItem('vc_id') || '';
            }

            const data = {
                api: api.vc.getVCRoom,
                urlParam: input,
            };

            const stream_vc_room: any = await backend.fetch(data, get(authValue, 'accessToken', {}));
            //setLoading(false);
            if (stream_vc_room) {
                if (guestInviteEnabledforStream || isGuestViewer) {
                    if(globalGuestAutoAllow){
                        sessionStorage.setItem("auto_allow", 'true');
                    }else{
                        if(!isGuestViewer || (isGuestViewer && !Boolean(sessionStorage.getItem('auto_allow')))){
                            sessionStorage.setItem("auto_allow", `${!!stream_vc_room.auto_allow}`);
                        }
                    }
                    
                }
            }

            return stream_vc_room;
        } catch (err: any) {
            console.log(err);
        }
    };

    const updateTwilioRoom = async (
        stream_vc_room: any,
        twilio_room_name: string,
        stream_id: string,
      ) => {
        try {
          const data = {
            api: api.vc.updateStreamVCRoom,
            urlParam: stream_vc_room.show_vc_room_id,
            payLoad: {
              show_id,
              showStream_id: stream_id,
              twilio_room_name,
            },
          };
          await backend.save(data, get(authValue, 'accessToken', {}));
          
        } catch (err: any) {
          console.error('Not able to update twilio room in db');
        }
      };

    const fetchVCRoomAndStartOrJoin = async (stream_id: string, stream_label: string) => {
        try {
            if (stream_label === MULTI_STREAM_VIEWER) {
                setShowMultiStreamViewer(true);
            } else {
                setShowMultiStreamViewer(false);
            }

            
            let stream_vc_room: any = await fetchVCRoomDetails(stream_id);
            const owner_user_id: string = stream_vc_room?.owner_user_id;

            if (stream_vc_room) {
                if(waterMarkFeatureIncludedRef.current){
                    setWatermarkDetails(get(stream_vc_room, "watermark", {}));
                }
                // Switching Stream
                if (mediaStream && !isGuestViewer) {
                    // To do: leave chat and close all open tabs.
                    closeRightPanel();
                    const canvas: any = document.getElementById('canvas');
                    if (canvas) {
                        let context = canvas.getContext('2d');
                        const canvasWidth = get(canvas, 'width', 0);
                        const canvasHeight = get(canvas, 'height', 0);
                        context.clearRect(0, 0, canvasWidth, canvasHeight);
                    }
                    await zmClient.leave();
                    sessionStorage.removeItem('urlId');
                    sessionStorage.removeItem('streaming');
                    setMediaStream(null);
                }



                if (stream_vc_room.show_vc_room_id) {
                    showVcIdref.current = stream_vc_room.show_vc_room_id;
                  }
                //Get status of guests who have already joined the room from db.
                if(guestInviteEnabledforStream || isGuestViewer){
                    const sessions = await getGuestSession(stream_vc_room.show_vc_room_id);
                    if (!!sessions) {
                        sessionStorage.setItem(
                            'allowedGuest',
                            typeof sessions == 'string' ? sessions : JSON.stringify(sessions),
                        );
                    }
                }
                if (isGuestViewer) {
                    setIsVCHost(false);
                } else {
                    if (owner_user_id === auth.getUserId()) {
                        //admin (host)
                        setIsVCHost(true);
                        sessionStorage.setItem('publisher', 'true');
                    } else {
                        setIsVCHost(false);
                    }
                }
                if (stream_vc_room && stream_vc_room.twilio_room_name) {
                //join zoom
                joinZoom(stream_vc_room.show_vc_room_id)
                } else {
                    // Start VC (Join VC and update twilio room link in backend)
                    //join zoom
                    joinZoom(stream_vc_room.show_vc_room_id)
                    // Update room name in database so other viewer can auto join
                    updateTwilioRoom(stream_vc_room, stream_vc_room.show_vc_room_id, stream_id);
                  }

              
            } else {
                console.log("No room created at the time of stream creation");
            }
        } catch (err: any) {
            console.error(err);
        }
    };
  

    const showDominantSpeakerView = () => { };

    const removeGuestUser = async () => {
        const currentUser = zmClient.getCurrentUserInfo();
        const gidentity = get(currentUser,'userIdentity','').slice(0, 47)
        let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
        allowedGuestSession =
          typeof allowedGuestSession === 'string'
            ? JSON.parse(allowedGuestSession)
            : allowedGuestSession;
    
        if (!!allowedGuestSession) {
          // Remove the guest user from session
          //console.log("remove guest2",gidentity)
          delete allowedGuestSession[gidentity.slice(0, 47)];
          sessionStorage.setItem(
            'allowedGuest',
            typeof allowedGuestSession === 'string'
              ? allowedGuestSession
              : JSON.stringify(allowedGuestSession),
          );
          shareGuestSessions(allowedGuestSession);
        }
        signOutGl();
      };
    
    const signOutGl = async () => {
        let allowedGuestSession = sessionStorage.getItem('allowedGuest');
        await updateGuestSession(
          typeof allowedGuestSession === 'string'
            ? JSON.parse(allowedGuestSession)
            : allowedGuestSession,showVcIdref.current
        );
        sessionStorage.removeItem('allowedGuest');
        sessionStorage.removeItem('streaming');
        logOutGuestUser();
        leaveConference();
      };

    const signOut = async (watermarkFlag?: string) => { 
        try {
            setLoading(true);
            handleCloseWatermarkPanelWindow();
            // await handelVideoConfEnd(true);
      
            // if (pubnub && !isEmpty(pubnub)) {
            //   pubnub.unsubscribeAll();
            // }
            // if (!!watermarkFlag && !!watermarkFeatureIncluded) {
            //   await sendAlertEmail();
            // }
            await auth.doSignOut();
            // clearCancelNoise();
            setLoading(false);
          } catch (err: any) {
            console.log(err);
          } finally {
            navigateToPage('/logoutredirect', {replace : true},true);
            setLoading(false);
          }
    };

    const showStreamContent = () => { };

    const handleDND = () => { }

    const handleCloseWatermarkPanelWindow = () => { }
    const handelVideoConfEnd = () => { }
    const handelVideoConfEndAll = () => { }

    const updateUserChimePref = async () => {
        try {
            const data: any = { api: '', payLoad: '' };
            data.api = api.users.edit;

            if (user_chime_preferance) {
                //User Chime is ON, make it OFF
                data.payLoad = JSON.stringify({ user_chime_preferance: CHIME_VALUES.CHIME_OFF });
                setUserChimePreferance(false);
            } else {
                //User Chime is OFF, make it ON
                data.payLoad = JSON.stringify({ user_chime_preferance: CHIME_VALUES.CHIME_ON });
                setUserChimePreferance(true);
            }
            await backend.save(data, get(authValue, 'accessToken', {}));
        } catch (err: any) {
            console.error(err);
        }
    };

    const handleHudStatsDisplayPanel = async (event: React.MouseEvent<HTMLElement>) => {
        setRPState((prevState) => ({
            ...prevState,
            hudStatsDisplay: true,
        }));
        setStatsModalOpen(true);
        resizeCanvas();
    }

    const handleCloseHudStatsDisplayPanel = async () => {
        setRPState((prevState) => ({
          ...prevState,
          hudStatsDisplay: false,
        }));
        resizeCanvas();
      }

    const reloadPage = async () => {
        setStreamKey(Math.random());
    };

    const reloadSubStreams = async () => {
        setSubStreamKey(Math.random());
    };

    // Check access
    const checkAccess = async () => {
        if (isGuestViewer) {
            return;
        }
        console.log("isZoomViewer - api call")
        const isShowViewer = await backend.fetch({
            api: api.users.isShowViewer,
            queryParam: { show_id },
        }, get(authValue, 'accessToken', {}));

        if (!isShowViewer && !isGuestViewer) {
            navigateToPage('/unauthorised',{},true);
        }
    };

    // If profile image changed as the header is specific to viewer
    const refreshProfile = async () => {
        // Pull user data to show new profile image if it is changed
        if (isGuestViewer) {
            return;
        }
        const data = {
            api: api.users.get,
        };
        const profiledata = await backend.fetch(data, get(authValue, 'accessToken', {}));
        if (profiledata) {
            const { imageUrlPath, user_chime_preferance } = profiledata;
            if (imageUrlPath) {
                setProfileImageUrl(imageUrlPath);
            }
            if (user_chime_preferance) {
                setUserChimePreferance(true);
            }
        }
    };

    const featureAvailable = async (feature_name: string) => {
        if (featuresList) {
            //  feature as key present in feature_flags
            if (feature_name in featuresList) {
                return featuresList[feature_name];
            }
        } else {
            const list = await fetchFeatures(show_id);
            if (feature_name in list) {
                return list[feature_name];
            }
            setFeaturesList(list);
        }
    }

    const getipdata = async () => {
        try {
            const endPoint = 'https://ipapi.co/json/';

            await fetch(endPoint)
                .then((res) => res.json())
                .then((data) => {
                    setIpAddress(data.ip);
                });
        } catch (err: any) {
            console.error(err);
        }
    };

    const checkTimeCodeFeature = async () => {
        //console.log("timecode use effect")
        const timeCodeFeature = await featureAvailable(FEATURE_NAME.TIME_CODE)
        if (timeCodeFeature) {
            setTimeCodeFeatureIncluded(true);
        }
    };

    const watermarkStatus = async () => {
        const watermarkFeature = await featureAvailable(FEATURE_NAME.ALLOW_WATERMARK);
        setWatermarkFeatureIncluded(!!watermarkFeature);
        waterMarkFeatureIncludedRef.current = true;
        if (!!watermarkFeature) {
            await getipdata();
        }
    };

    const encoderStatus = async () => {
        const encoderFeature = await featureAvailable(FEATURE_NAME.ALLOW_ENCODER);
        setEncoderFeatureIncluded(!!encoderFeature);
    };

    const fetchGlobalAutoAllow = async () => {
        try {
            // setLoading(true);
            const data = {
                api: api.shows.fetchGlobalAutoAllow,
                queryParam: { show_id: show_id },
            };
            const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
            if (result) {
                setGlobalGuestAutoAllow(result);
            }
        } catch (err) {
            console.log(err);
        } finally {
        }
    };


    const getPublisherStatus = async () => {
        try {
            console.log("isZoomShowPublisher - api call")
            const isShowPublisher = await backend.fetch({
                api: api.users.isShowPublisher,
                queryParam: { show_id },
            }, get(authValue, 'accessToken', {}));
            setIsPublisher(isShowPublisher);
            const giFeatureIncluded = await featureAvailable(FEATURE_NAME.ALLOW_GUEST_INVITE);
            setGuestInviteEnabledforStream(giFeatureIncluded);
            if (giFeatureIncluded && isShowPublisher) {
                const giAllowedForPublisher = get(isShowPublisher, "guest_invite", false);
                setGuestInviteIncluded(!!giAllowedForPublisher);
                if (giAllowedForPublisher) {
                    fetchGlobalAutoAllow()
                }
            }

        } catch (err: any) {
            console.log(err);
        }
    };

    const fetch_Stream_Data = async (stream_id: string, stream_label: string) => {
        if (stream_id) {
            let stream = { stream_id, stream_label };
            setStreamLabel(stream_label);

            setStream(stream);

            setStreamKey(Math.random());

            if (stream_label !== MULTI_STREAM_VIEWER) {
                // Open chat window
                setRPState((prevState) => ({
                    ...prevState,
                    annotationChatWindow: false,
                    annotationCloseDisable: false,
                    annotationChatDisable: true,
                    showPublisherPanel: false,
                    showPublisherPanelCloseDisable: false,
                    showGuestInvite: false,
                    showGuestInviteCloseDisable: false,
                    watermarkPanel: false,
                    chatWindow: true,
                    closeDisable: true,
                    chatDisable: false,
                    hudStatsDisplay: false,
                    encoder: false
                }));
            }

            // Pull VC Room Details and start/join VC
            if (showMultiStreamViewer) {
                fetchVCRoomAndStartOrJoin(stream_id, MULTI_STREAM_VIEWER);
            } else {
                fetchVCRoomAndStartOrJoin(stream_id, stream_label);
            }
        }
    };

    const fetchStreamListData = async (show_id: string) => {
        const traceTrack = trace(performance, "SideMenuListLoader");
        try {
            traceTrack.start();
            // setSuccessMessage('');
            // setErrorMessage('');
            setFetchStreamListLoader(true);
            // 1. Pull active stream
            const data = {
                api: api.streams.listViewerStreams,
                queryParam: { show_id: show_id },
            };

            const streams: any[] = await backend.fetch(data, get(authValue, 'accessToken', {}));

            setStreamList(streams);

            if (streams && streams.length) {
                const cleanStream = streams.filter(
                    (arr: any, index: any, self: any) =>
                        index === self.findIndex((temp: any) => temp.stream_id === arr.stream_id),
                );
                cleanStream.sort(
                    firstBy(function (v: any) {
                        return v.stream_label.toLowerCase();
                    }),
                );

                const isGuest = isGuestAuthenticated();
                if (isGuest) {
                    guestStreams.push(cleanStream)
                };

                setActiveStreams(cleanStream);
            }

            // If navigated from Publisher invite screen
            if (stream_id && streams) {
                // Check if stream_id present in active streams
                const result: any[] = streams.filter((stream) => stream.stream_id === stream_id);
                if (result && result[0]) {
                    const pub_owned_stream = result[0] || null;
                    if (pub_owned_stream) {
                        const { stream_label } = pub_owned_stream;
                        fetch_Stream_Data(stream_id, stream_label);
                    }
                }
            }
        } catch (err: any) {
            const msg = 'Error while pulling data';
            if (err.errCode === 30314) {
                const user = auth.getUser();
                const uid: any = get(user, 'uid', '');
                Sentry.configureScope((scope) => scope.setUser({ id: uid }).setTag('show_id', show_id));
                Sentry.captureMessage(err.errMessage);
            }
            if (err.errMessage) {
                // setErrorMessage(err.errMessage);
            } else {
                // setErrorMessage(msg);
            }
        } finally {
            setFetchStreamListLoader(false);
            traceTrack.stop()
        }
    };

    const onConnectionChange = useCallback(
        (payload: any) => {
            if (payload.state === ConnectionState.Reconnecting) {
                const { reason, subsessionName } = payload;
                if (reason === ReconnectReason.Failover) {
                    console.log('Session Disconnected,Try to reconnect');
                } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
                    console.log(`Joining ${subsessionName}...`);
                } else if (reason === ReconnectReason.BackToMainSession) {
                    console.log('Returning to Main Session...');
                }
            } else if (payload.state === ConnectionState.Connected) {
                window.zmClient = zmClient;
                window.mediaStream = zmClient.getMediaStream();

                console.log('getSessionInfo', zmClient.getSessionInfo());
            } else if (payload.state === ConnectionState.Closed) {
                dispatch({ type: 'reset-media' });
                if (payload.reason === 'ended by host') {
                    leaveConference();
                    // Modal.warning({
                    //   title: 'Meeting ended',
                    //   content: 'This meeting has been ended by host'
                    // });
                }
            }
        },
        [zmClient]
    );
    const onMediaSDKChange = useCallback((payload: any) => {
        const { action, type, result } = payload;
        dispatch({ type: `${type}-${action}`, payload: result === 'success' });
    }, []);

    const unmuteStream = () => {
        const elem: any = document.getElementsByTagName('video')[0];
        if (elem) {
          elem.muted = false;
        }
      };

    const isEmpty = (object: {}) => !Object.keys(object).length;

    const onCommandChannelMessage = useCallback(async (payload: any) => {
        const canvas: any = document.getElementById("canvas");
        // console.log('payload-onCommandChannelMessage', payload)
        const {
            isPainting,
            color,
            mousePosition,
            newMousePosition,
            incoming_canvas,
            erase,
            clear,
            globalMute,
            nameTextId,
            className,
            clearAnnotationChat,
            clearGroupChat,
            isCircle,
            endConference,
            allowedGuestSession,
            watermark,
            auto_allow
        } = JSON.parse(payload.text);
        
        if(payload.text.includes('watermark')){
            waterMarkTempDetails.current = waterMarkTempDetails.current + Object.values(JSON.parse(payload.text))[0] 
            if(payload.text.includes('end') && waterMarkTempDetails.current){
               const watermark = JSON.parse(waterMarkTempDetails.current);
               setWatermarkDetails(watermark);
               waterMarkTempDetails.current = ''
            }
        }
        if (payload.text.includes('allowedGuestSession')) {
          const guestSessionRecieved: any = get(Object.values(JSON.parse(payload.text)), '0', {});
          sessionArray.push(guestSessionRecieved)
          let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
          allowedGuestSession =
            typeof allowedGuestSession === 'string'
              ? JSON.parse(allowedGuestSession)
              : allowedGuestSession;
          // Add the guest user into session
          if (isEmpty(guestSessionRecieved)) {
            sessionStorage.setItem(
              'allowedGuest',
              typeof guestSessionRecieved === 'string'
                ? guestSessionRecieved
                : JSON.stringify(guestSessionRecieved),
            );
          } else {
            const newSessions:any={};
            const activeParticipants:string[] = [];
            // update the sessions            
            sessionArray.forEach((value:any) => {
                Object.entries(value).forEach(([key, value]) => {
                    // console.log(`Key: ${key}`);
                    // console.log(`Value:`, value);
                    activeParticipants.push(key)
                    newSessions[key] = value;                   
                })            
            });

            sessionStorage.setItem(
                'allowedGuest',
                typeof allowedGuestSession === 'string'
                  ? allowedGuestSession
                  : JSON.stringify(newSessions),
                );
            }

          if (payload.text.includes('end')) {
            const newGuestSessions: any = JSON.parse(sessionStorage.getItem('allowedGuest') || '');
            sessionArray.splice(0, sessionArray.length);
            
            if (isGuestViewer) {
              await updateGuestSession(newGuestSessions, showVcIdref.current);
              const gid = `guest#${guestUid}_${guestDisplayName}`;
              if (!newGuestSessions[gid.slice(0, 47)]) {
                //Signout Guest User
                removeGuestUser();
              }
              const guestAllowed = String(newGuestSessions[gid.slice(0, 47)]?.status) === 'allowed';
              const isGuestAttached = Boolean(sessionStorage.getItem('guestAttached') || '')
              if (guestAllowed && !isGuestAttached) {
                // Guest is allowed.
                setOpen(false);
                setVolumeMute(false);
                unmuteStream();
                setMediaStream(zmClient?.getMediaStream());
                sessionStorage.setItem('guestAttached','true')
                zmClient?.getMediaStream()?.startAudio({ mute: true });
              }
            }
          }
        }
        
        if(auto_allow){
            sessionStorage.setItem('auto_allow',`${auto_allow}`)
        }

        if(clearGroupChat){
            setclearChatFlag(true)
        }
    }, []);

    const onUsersChanged = () => {
        const participants = zmClient.getAllUser()
        console.log('participants', participants)
    };

    const playChimp = () =>{
        if (user_chime_preferance) {
            const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0] as HTMLAudioElement
            audioEl.play()
          }
    }

    const getUpdatedParticipant = (newparticipants:any) =>{
        return newparticipants.filter((newParticipant:any)=> !joinedParticipants.current.includes(newParticipant?.userIdentity))
    }
    
    const onUsersJoin = (participants: any) => {
      // const participants = zmClient.getAllUser();
      // To do add check for guest invite ff
      //   if (isGuestInviteIncluded) {
      const participant = get(getUpdatedParticipant(participants), '0', '');
      playChimp();
      const isParticipantGuest =
        get(get(participant, 'userIdentity', '').split('#'), '0', '').toLowerCase() === 'guest';
      const checkAutoAllow = sessionStorage.getItem('auto_allow');
      if (isParticipantGuest) {
        if (checkAutoAllow === 'true') {
          let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
          allowedGuestSession =
            typeof allowedGuestSession === 'string'
              ? JSON.parse(allowedGuestSession)
              : allowedGuestSession;
          // Add the guest user into session
          allowedGuestSession[get(participant, 'userIdentity', '').slice(0, 47)] = {
            status: 'allowed',
          };
          sessionStorage.setItem(
            'allowedGuest',
            typeof allowedGuestSession === 'string'
              ? allowedGuestSession
              : JSON.stringify(allowedGuestSession),
          );
        } else if (checkAutoAllow === 'false') {
          let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
          allowedGuestSession =
            typeof allowedGuestSession === 'string'
              ? JSON.parse(allowedGuestSession)
              : allowedGuestSession;
          // Add the guest user into session
          if (
            !!allowedGuestSession[get(participant, 'userIdentity', '').slice(0, 47)] &&
            allowedGuestSession[get(participant, 'userIdentity', '').slice(0, 47)].status !==
              'allowed'
          ) {
            allowedGuestSession[get(participant, 'userIdentity', '').slice(0, 47)] = {
              status: 'waiting',
            };
          }

          sessionStorage.setItem(
            'allowedGuest',
            typeof allowedGuestSession === 'string'
              ? allowedGuestSession
              : JSON.stringify(allowedGuestSession),
          );
        }
      }
      // Share guest sessions to all participants in the vc.
      if (Boolean(sessionStorage.getItem('publisher') === 'true')) {
        let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
        allowedGuestSession =
          typeof allowedGuestSession === 'string'
            ? JSON.parse(allowedGuestSession)
            : allowedGuestSession;
        if (!!get(participant, 'userIdentity', '') && isParticipantGuest) {
          allowedGuestSession[get(participant, 'userIdentity', '')] = {
            status:
              checkAutoAllow?.toLowerCase() === 'true' ||
              (!!allowedGuestSession[get(participant, 'userIdentity', '').slice(0, 47)] &&
                !!allowedGuestSession[get(participant, 'userIdentity', '').slice(0, 47)].status &&
                allowedGuestSession[get(participant, 'userIdentity', '').slice(0, 47)].status ===
                  'allowed')
                ? 'allowed'
                : 'waiting',
          };
          sessionStorage.setItem('allowedGuest', JSON.stringify(allowedGuestSession));
        }
        if (!!get(participant, 'userIdentity', '') && isParticipantGuest) {
          setTimeout(() => {
            shareGuestSessions(allowedGuestSession)
          }, 2000);
        } else {
          setTimeout(() => {
            shareGuestSessions(allowedGuestSession)
          }, 2500);
        }
      }
      //   }

      joinedParticipants.current = participants.map((participant: any) => participant.userIdentity);
    };

    const onUsersExit = () => {
        const participants = zmClient.getAllUser()
        joinedParticipants.current = participants.map((participant:any)=> participant.userIdentity);
        playChimp()
    };

    

    const joinZoom = async (streamId: string) => {
        let webEndpoint: any = 'zoom.us';

        const galleryViewWithoutSAB = !window.crossOriginIsolated;
        setVideoTileLoader(true);

        await zmClient.init('en-US', 'Global', {
            webEndpoint,
            enforceMultipleVideos: true,
            enforceVirtualBackground: true,
            patchJsMedia: true,
            stayAwake: true,
            leaveOnPageUnload: false
        });
        try {
            let identity = '';
            let displayName = '';
            let isVcHost = sessionStorage.getItem('publisher') === 'true'
            const participant_type = isGuestViewer ? 'guest' : isVcHost ? 'admin' : 'member';
            if (isGuestViewer) {
                displayName = guestDisplayName
                identity = `${participant_type}#${guestUid}_${guestDisplayName}`;
            } else {
                const user = await auth.getUser();
                const userUid = get(user, 'uid', '');
                displayName = get(user, 'displayName', '');
                identity = `${participant_type}#${userUid}_${displayName}`;
            }

            //Create session for the local guest participant & update them in db
            if (isGuestViewer) {
                const sessionsCreated = createGuestSessions(identity);
                const allowedGuestSession = sessionStorage.getItem('allowedGuest');
                if (sessionsCreated) {
                    updateGuestSession(
                        typeof allowedGuestSession === 'string'
                            ? JSON.parse(allowedGuestSession)
                            : allowedGuestSession, showVcIdref.current
                    );
                }
            }

            

            const payload = {
                host: (Boolean(sessionStorage.getItem('publisher') === 'true')),
                userIdentity: identity,
                sessionName: streamId
            }
            const signature = await generateVideoSDKJWT(payload)
            console.log('signature', signature)

            await zmClient.join(streamId, signature, displayName, '').then(()=>{
                if(!(isGuestViewer && !isGuestAllowed(identity) && sessionStorage.getItem("auto_allow") === 'false')){
                    const stream: any = zmClient?.getMediaStream();
                    stream.startAudio({ mute: true })
                    setMediaStream(stream);
                 }
                openChatPanel();
            }).catch((e) => {
                console.log(e);
            });
           
            
        } catch (e: any) {
            console.log(e.reason)
        } finally {
            setVideoTileLoader(false);
        }
    };

    const generateVideoSDKJWT = async (payload: any) => {
      const { host, sessionName, userIdentity } = payload;
      try {
        const data = {
          api: api.vc.generateVideoSDKJWT,
          payLoad: {
            host: host,
            sessionName: sessionName,
            userIdentity: userIdentity,
          },
        };

        const token = get(authValue, 'accessToken', {});
        const response = await backend.save(data, token);
        return response;
      } catch (error: any) {
        console.log('generateVideoSDKJWT error:', error);
        return '';
      }
    };
    
    //Initial check - UE1
    useEffect(() => {

        setShowId(show_id);
        //Viewer check
        checkAccess(); //To-do: revisit later

        if (show_id) {
            refreshProfile();

            // Fetch Ice Servers
            fetchIceServers();

            // Featre inclusion
            checkTimeCodeFeature();
            watermarkStatus();

            //isEncoder enabled
            encoderStatus()

            //Publisher check && GuestInvite check
            if (!isGuestViewer) {
                getPublisherStatus();
            }

            // Fetch Streams List
            fetchStreamListData(show_id);
        } else {
            navigateToPage('/unauthorised',{},true);
        }
    }, [show_id]);

    useEffect(() => {
        zmClient.on('connection-change', onConnectionChange);
        zmClient.on('media-sdk-change', onMediaSDKChange);
        zmClient.on('command-channel-message', onCommandChannelMessage);
        // zmClient.on('device-change', onDeviceChange)
        zmClient.on('user-added', onUsersJoin);
        zmClient.on('user-removed', onUsersExit);
        zmClient.on('user-updated', onUsersChanged);        
        return () => {
            zmClient.off('connection-change', onConnectionChange);
            zmClient.off('media-sdk-change', onMediaSDKChange);
            zmClient.off('command-channel-message', onCommandChannelMessage);
            zmClient.off('user-added', onUsersJoin);
            zmClient.off('user-removed', onUsersExit);
            zmClient.off('user-updated', onUsersChanged);
            // zmClient.off('device-change', onDeviceChange)
            ZoomVideo.destroyClient()
        };
    }, [zmClient, onConnectionChange, onMediaSDKChange, onCommandChannelMessage]);

    useEffect(() => {
      const stream_id = sessionStorage.getItem('stream_id');
      const stream_label: any = sessionStorage.getItem('stream_label');

      if (stream_id && stream_label) {
        fetch_Stream_Data(stream_id, stream_label);
      }

      return () => {
        closeRightPanel();
        setShowSpeakerViewer(false);
        if(navigationType === 'PUSH'){
            zmClient.leave()
            sessionStorage.removeItem('stream_id');
            sessionStorage.removeItem('allowedGuest');
            sessionStorage.removeItem('stream_label');
            sessionStorage.removeItem('vc_id');
            sessionStorage.removeItem('auto_allow');
            sessionStorage.removeItem('urlId');
            sessionStorage.removeItem('publisher');
            sessionStorage.removeItem('localIdentity');
            sessionStorage.removeItem('pubname');
            sessionStorage.removeItem('previousStreamName');
            sessionStorage.removeItem('tabKey')
            sessionStorage.removeItem('streaming');
        } 
      };
    }, []);

    const FallbackComponent = () => {
        return <div className='not-found'>Sorry there was a problem !</div>;
    };
    const myFallback = <FallbackComponent />;

    const rightSideContainer = () => {
        
        return (
          <Sider
        //   width='25%'
            style={{ display: rightActivePanel ? "block" : "none" }}
            className="viewer-side-panel"
          >
             {rightActivePanel === "watermark" ? (
              <Watermark 
                zmClient={zmClient} 
                vc_id ={showVcIdref.current}
                isGuestInviteIncluded={isGuestInviteIncluded}
              />
            ) : null}
            {rightActivePanel === "guest" ? (
              <GuestInvite globalGuestAutoAllow={globalGuestAutoAllow} lockedList={lockedList}/>
            ) : null}
            {rightActivePanel === "chat" ? (
              <ShowChat user_imageurl={user_imageurl} 
              clearChatFlag={clearChatFlag} 
              setclearChatFlag={setclearChatFlag}
              vc_id = {showVcIdref.current}/>
            ) : null}
            {rightActivePanel === "publisherpanel" ? (
               <PublisherPanel zmClient={zmClient} /> 
            ) : null}
             {rightActivePanel === "encoder" ? (
              <Encoder />
            ) : null}
            
            
       
          </Sider>
        );
      };

   
   

    // Canvas
    const {
        canvasMT,
        canvasML,
        canvasWidth,
        renderCanvas,
        videoReady,
        resizeCanvas
    } = useCanvas()

    const sidemenuProps = {
        activeStreams,
        handleLogoClick,
        isGuestViewer,
        streamLabel,
        updatedStreamId,
        onStreamSubmit,
        updatedStreamLabel,
        setUpdatedStreamLabel,
        setStreamLabelUpdateMsg,
        setUpdatedStreamId,
        handleUpdateStreamLabel,
        streamLabelUpdateMsg,
        showId,
        show_name,
        show_code,
        setStreamLabel,
        setStream,
        setStreamKey,
        rpstate,
        setRPState,
        endorLeaveConferenceLoading,
        fetchStreamListLoader,
        isUserInvited,
        updateStreamLabelLoader,
        updateStreamLabelErrorModal,
        setUpdateStreamLabelErrorModal,
        fetchVCRoomAndStartOrJoin,
    };

    let headerProps = {
        showMultiStreamViewer,
        isVCHost,
        fullScreenVideo,
        removeGuestUser,
        signOut,
        showDominantSpeakerView,
        showStreamContent,
        handleDND,
        rpstate, setRPState,
        stream,
        showPopup,
        setShowPopup,
        handelVideoConfEndAll,
        handelVideoConfEnd,
        user_imageurl,
        handleHudStatsDisplayPanel,
        isDND,
        user_chime_preferance,
        updateUserChimePref,
        resizeCanvas,
        chatLoaded,
        isPublisher,
        watermarkFeatureIncluded,
        // setWatermarkDetails,
        // watermarkDetails,
        redIcon,
        encoderFeatureIncluded,
        isTimeCodeFeatureIncluded,
        isGuestInviteIncluded,
        reloadPage,
        reloadSubStreams,
        activeStreamStatus, 
        setActiveStreamStatus,
        showVcIdref,
        volumeMute,
        setVolumeMute
    };

    // This is show stream component
    const renderStreamContent = () => {
   
        return (
            <Stream
              show_id={showId}
            //   canvasRef={canvasRef}
              stream={stream}
              iceServers={iceServers}
              refreshKey={streamKey}
              setIsStreamSelected={setIsStreamSelected}
              isReady={videoReady}
              userId={get(auth.getUser(), 'uid', '')}
              isGuestUser={isGuestAuthenticated()}
              guestDisplayName={guestDisplayName}
              open={open}
              watermarkPanel={rpstate.watermarkPanel}
            //   watermarkDetails={watermarkDetails}
              signOut={signOut}
              programaticChange={false}
              isPublisher={isPublisher}
            //   canvasMT={canvasMT}
            //   canvasML={canvasML}
            //   canvasWidth={canvasWidth}
              leaveChat={false}
              leaveGuestInvite={false}
              leavePublisherPanel={false}
              watermarkFeatureIncluded={watermarkFeatureIncluded}
              watermarkChecks={false}
              preview={false}
              setPreview={() => {}}
              setRPState={setRPState}
              setMillicastView={setMillicastView}
              setActiveStreamExists={setActiveStreamExists}
              setActiveStreamStatus={setActiveStreamStatus}
              isMultiViewerEnabled={showMultiStreamViewer}
              ipAddress={ipAddress}
              volumeMute={volumeMute}
            />
          ); 
     
    };

    return (
        <ZoomContext.Provider value={zmClient}>
            <ZoomMediaContext.Provider value={mediaContext}>
                <Sentry.ErrorBoundary fallback={myFallback} showDialog>
                    {/* {!!dataTrack && !!showDiagnostics && ( */}
                    {!!showDiagnostics && (
                        <Diagnostics iceServers={iceServers} setShowDiagnostics={setShowDiagnostics} />
                    )}
                    <FullScreen handle={fullScreenHandler}>
                        <Spin spinning={!!gLoader} size={'large'} indicator={loaderIcon}>
                            <Layout className='layout zoom-viewer' onContextMenu={(event) => event.preventDefault()}>
                                <Sider width={90}>
                                    <Sidemenu {...sidemenuProps} />
                                </Sider>
                                <Content className='zoom-viewer-content'>
                                    <Header className='zoom-viewer-header'>
                                        <HeaderView {...headerProps} />
                                    </Header>
                                    <Layout className="viewer-column">
                                        <Content id='zoom-container-wrapper' className={`viewer-content`}>
                                            <div className='player-wrapper'>{renderStreamContent()}</div>
                                            {!zmClient.getSessionInfo().isInMeeting && !videoTileLoader ? (
                                                <div className='empty-video-tiles-placeholder'></div>
                                            ) : (
                                                <div className='zoom-video-tray'>
                                                    <VideoTray alreadyJoinedGuests={alreadyJoinedGuests} />
                                                </div>
                                             )} 
                                        </Content>
                                        {rightSideContainer()}
                                    </Layout> 
                                </Content>
                                {rpstate.hudStatsDisplay && ( <HudStats
                             handleCloseHudStatsDisplayPanel={handleCloseHudStatsDisplayPanel}
                             hudStatsDisplay={rpstate.hudStatsDisplay}
                             statsModalOpen={statsModalOpen}
                             setStatsModalOpen={setStatsModalOpen}
                             millicastView={millicastView}
                             activeStreamExists={activeStreamExists}
                            />)}
                            </Layout>
                            
                            <GuestWaitingModal
              open={open}
              onClose={() => setOpen(false)}
              streamName={streamLabel}
              publisherName={sessionStorage.getItem('pubname') || 'publisher'}
              onLeave={() => {
                // signOutGl();
              }}
            />
                            <div id='zoom-full-screen' style={{display:'flex', justifyContent:'center'}}><video-player-container id='fullscreen-player'></video-player-container></div>
                           
                        </Spin>
                    </FullScreen>
                </Sentry.ErrorBoundary>
            </ZoomMediaContext.Provider>
            <audio className='audio-element'>
                <source src={chimeAudioUrl}></source>
            </audio>
        </ZoomContext.Provider>
    )
}
export default ZoomShowViewerDashboard;