import React, { useEffect, useState, useContext } from 'react';
import { notification } from 'antd';
import { get } from 'lodash';
import useDbTrigger from '../../../hooks/useDbTrigger';
import { useTVOSNotificationStore } from '../../../store/index';
import appleImage from '../../../Images/appleImage.png';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { AuthContext } from '../../../Context/authContext';
import { isGuestAuthenticated } from '../../../Utils/guestHelpers';

interface Props {
  isPublisher: boolean;
  troom: any;
}

const TVOSHeaderIcon: React.FC<Props> = ({ isPublisher, troom }) => {
  const isVcHost = sessionStorage.getItem('publisher') === 'true';
  const tvOSNotifications = useDbTrigger('tv_os_participants', Object.keys(troom).length !== 0);
  const tvOSTriggerData = tvOSNotifications?.doc?.data();
  const sessionStreamId = sessionStorage.getItem('stream_id');
  const sessionVcId = sessionStorage.getItem('vc_id');
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const isGuest = isGuestAuthenticated();
  // const [activeUsers, setActiveUsers] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { notifiedParticipants, addNotifiedParticipant, removeNotifiedParticipant } =
    useTVOSNotificationStore();

  const fetchTVOSParticipants = async (showVcId: string) => {
    if (!showVcId) return;

    try {
      setLoading(true);
      setError(null);

      const data = {
        api: api.tvOs.getTVOSParticipants,
        queryParam: { show_vc_id: showVcId },
      };

      const response = await backend.fetch(data, get(authValue, 'accessToken', ''));
      const fetchedParticipants = get(response, 'participants', []);
      handleFetchedParticipants(fetchedParticipants, showVcId);
    } catch (err) {
      console.error('Error fetching TVOS participants:', err);
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchedParticipants = (participants: any[], showVcId: string) => {
    participants.forEach((participant) => {
      const participantId = participant?.user_id;
      const participantName = `${get(participant, 'user_name.user_name_first', '')} ${get(
        participant,
        'user_name.user_name_last',
        '',
      )}`;

      if (participant.show_vc_id === sessionVcId) {
        if (!notifiedParticipants.has(participantId)) {
          addNotifiedParticipant(participantId);
        }
        createParticipantDiv(participantName, participantId);

        // setActiveUsers((prev) => new Set(prev).add(participantId));
      }
    });
  };

  const handleNotification = (notificationData: any) => {
    const { type } = notificationData;

    const metadata = !!notificationData ? notificationData?.doc?.data() : '';
    const userId = get(metadata, 'user_id', '');
    const displayName = `${get(metadata, 'user_name.user_name_first', '')} ${get(
      metadata,
      'user_name.user_name_last',
      '',
    )}`;
    if (type === 'added') {
      if (!notifiedParticipants.has(userId)) {
        if (isVcHost) openNotification('bottomRight', displayName, 'joined from TV OS');
      }
      setTimeout(() => createParticipantDiv(displayName, userId), 2000);
      updateActiveUsers(userId, true);
    } else if (type === 'removed' && notifiedParticipants.has(userId)) {
      if (isVcHost) openNotification('bottomRight', displayName, 'left from TV OS');
      removeParticipantDiv(userId);
      updateActiveUsers(userId, false);
    }
  };

  const updateActiveUsers = (userId: string, isAdding: boolean) => {
    // setActiveUsers((prev) => {
    //   const updatedSet = new Set(prev);
    //   isAdding ? updatedSet.add(userId) : updatedSet.delete(userId);
    //   return updatedSet;
    // });
    isAdding ? addNotifiedParticipant(userId) : removeNotifiedParticipant(userId);
  };

  const openNotification = (
    placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
    name: string,
    action: string,
  ) => {
    notification.info({
      message: 'Pacpost',
      description: `${name} ${action}`,
      placement,
    });
  };

  const createParticipantDiv = (name: string, userId: string) => {
    const randomId = `tvos-participant-${userId}`;
    const vtray = document.getElementById('vtray');
    if (!vtray) return;

    removeParticipantDiv(userId);

    if (document.getElementById(randomId)) return;

    const div = document.createElement('div');
    div.id = randomId;
    div.className = 'videoparticipant tvos-participant';
    div.style.width = '200px';
    div.style.minWidth = '200px';

    const img = document.createElement('img');
    img.src = appleImage;
    img.alt = 'Apple Image';
    img.className = 'participant-image';
    div.appendChild(img);

    const overlayDiv = document.createElement('div');
    overlayDiv.id = `${randomId}_overlay`;
    overlayDiv.className = 'overlay-vtray';

    const nameText = document.createElement('p');
    nameText.id = `${randomId}_name`;
    nameText.className = 'overlay-text-red prevent-textcopy';
    nameText.textContent = name;
    overlayDiv.appendChild(nameText);

    div.appendChild(overlayDiv);
    vtray.appendChild(div);
  };

  const removeParticipantDiv = (userId: string) => {
    const div = document.getElementById(`tvos-participant-${userId}`);
    div?.remove();
  };

  //   const cleanupAllParticipants = () => {
  //     // console.log("::cleanupAllParticipants::::::",notifiedParticipants)
  //     // document.querySelectorAll('.videoparticipant.tvos-participant').forEach((div) => div.remove());
  //     // notifiedParticipants?.forEach(removeParticipantDiv);
  //   };

  useEffect(() => {
    if (sessionVcId && troom?.name === sessionVcId && Object.keys(troom).length !== 0 && !isGuest) {
      fetchTVOSParticipants(sessionVcId);
    }
    // return ()=>  cleanupAllParticipants();
  }, [sessionStreamId, troom]);

  useEffect(() => {
    if (tvOSTriggerData?.steam_id === sessionStreamId) {
      handleNotification(tvOSNotifications);
    } else {
      removeParticipantDiv(tvOSTriggerData?.user_id);
    }
  }, [tvOSNotifications]);

  //   if (!troom || Object.keys(troom).length === 0) {
  //     return null;
  //   }

  return <div className='tvos-header-icon'>TVOS</div>;
};

export default TVOSHeaderIcon;
