import React, { useEffect, useState, useRef } from 'react';
import { testAudioInputDevice, testAudioOutputDevice } from '@twilio/rtc-diagnostics';
import MicTest from './MicTest';

interface MediaDeviceInfo {
  deviceId: string;
  kind: string;
  label: string;
}

interface AudioInputTest {
  on: (event: string, listener: (data: any) => void) => void;
  stop: () => void;
}

const CheckAudio = (props: any) => {
  const { handleNext } = props;
  const [devices, setDevices] = useState<any[]>([]);
  const [selectedDevices, setSelectedDevices] = useState({ speaker: '', microphone: '' });
  const [isRecording, setIsRecording] = useState(false);
  const [audioInputTest, setAudioInputTest] = useState<AudioInputTest | null>(null);
  const [isTestingAudio, setIsTestingAudio] = useState(false);
  const audioOutputTestRef = useRef<any>(null);

  useEffect(() => {
    const getDevices = async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const speakers = mediaDevices.filter(
        (device: MediaDeviceInfo) => device.kind === 'audiooutput',
      );
      const microphones = mediaDevices.filter(
        (device: MediaDeviceInfo) => device.kind === 'audioinput',
      );
      setDevices([...speakers, ...microphones]);
      if (speakers.length > 0 && microphones.length > 0) {
        setSelectedDevices({ speaker: speakers[0].deviceId, microphone: microphones[0].deviceId });
      }
    };

    getDevices();
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    return () => navigator.mediaDevices.removeEventListener('devicechange', getDevices);
  }, []);

  const handleAudioInputTest = () => {
    if (isRecording) {
      audioInputTest?.stop();
      setIsRecording(false);
      setAudioInputTest(null);
      return;
    }

    const test: any = testAudioInputDevice({ deviceId: selectedDevices.microphone });

    test.on('volume', (volume: number) => {
      console.log('Microphone Volume Level:', volume);
    });

    test.on('error', (error: any) => {
      console.error('Audio Input Test Error:', error);
    });

    test.on('end', (report: any) => {
      console.log('Audio Input Test Report:', report);
    });

    setIsRecording(true);

    setTimeout(() => {
      test.stop();
      setIsRecording(false);
    }, 10000);
  };

  const handleAudioOutputTest = () => {
    if (isTestingAudio) {
      audioOutputTestRef.current.stop();
      setIsTestingAudio(false);
      return;
    }

    setIsTestingAudio(true);
    audioOutputTestRef.current = testAudioOutputDevice({
      deviceId: selectedDevices.speaker,
      testURI: 'ImperialMarch60.wav',
    });

    audioOutputTestRef.current.on('error', (error: any) => {
      console.error('Audio Output Test Error:', error);
    });

    audioOutputTestRef.current.on('end', (report: any) => {
      console.log('Audio Output Test Report:', report);
      setIsTestingAudio(false);
    });

    setTimeout(() => {
      audioOutputTestRef.current.stop();
    }, 5000);
  };

  return (
    <div className='check-audio-container'>
      <div className='details-container'>
        <h1>Test your Audio</h1>
        <p>
          Record an audio clip and play it back to check that your microphone and speaker are
          working. If they aren't, make sure your volume is turned up, try a different speaker or
          microphone, or check your Bluetooth settings.
        </p>
        <p>
          <b>Does your audio sound good?</b>
        </p>
        <button className='theme-button' onClick={() => handleNext(0)}>
          Yes
        </button>
        <span onClick={() => handleNext(0)}>Skip for now</span>
      </div>
      <div className='audio-preview-container'>
        <div className='audio-preview-wrapper'>
          <div className='audio-preview-header'>
            <h3>Audio</h3>
            <span>
              {/* <button onClick={handleAudioInputTest}>
                {isRecording ? 'Stop Recording' : 'Record'}
              </button> */}
              <button onClick={handleAudioOutputTest}>
                {isTestingAudio ? 'Stop' : 'Test Speaker'}
              </button>
            </span>
          </div>
          <div className='audio-dropdown'>
            <label>Speaker</label>
            <br />
            <select
              onChange={(e) => setSelectedDevices({ ...selectedDevices, speaker: e.target.value })}
              value={selectedDevices.speaker}
            >
              <option defaultChecked hidden>Select Speaker</option>
              {devices
                .filter((device: MediaDeviceInfo) => device.kind === 'audiooutput')
                .map((device: { deviceId: string; label?: string }) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || 'Unnamed Speaker'}
                  </option>
                ))}
            </select>
            <br />
            <br />
              {
                <MicTest devices={devices} selectedDevices={selectedDevices} setSelectedDevices={setSelectedDevices} />
              }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckAudio;
