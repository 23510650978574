import { testAudioInputDevice } from "@twilio/rtc-diagnostics";
import { useEffect, useState } from "react";

interface IMicTestProps{
    devices: any[],
    selectedDevices: {
        speaker: string;
        microphone: string;
    },
    setSelectedDevices: (set: {
        speaker: string;
        microphone: string;
    }) => void;
};

const MicSVG = <svg width="12" height="17" viewBox="0 0 12 17" fill="white" xmlns="http://www.w3.org/2000/svg" stroke="white"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.11111 2.83333C3.11111 1.26812 4.37982 0 5.94444 0C7.51014 0 8.77778 1.26764 8.77778 2.83333V7.16667C8.77778 8.73129 7.50966 10 5.94444 10C4.3803 10 3.11111 8.73081 3.11111 7.16667V2.83333ZM7.77778 2.83333C7.77778 1.81992 6.95786 1 5.94444 1C4.932 1 4.11111 1.82051 4.11111 2.83333V7.16667C4.11111 8.17852 4.93259 9 5.94444 9C6.95727 9 7.77778 8.17911 7.77778 7.16667V2.83333ZM11.3889 6.66667C11.665 6.66667 11.8889 6.89052 11.8889 7.16667C11.8889 10.2813 9.49349 12.8365 6.44444 13.0904V15.7222C6.44444 15.9984 6.22059 16.2222 5.94444 16.2222C5.69898 16.2222 5.49484 16.0453 5.4525 15.8121L5.44444 15.7222V13.0904C2.3954 12.8365 0 10.2813 0 7.16667C0 6.89052 0.223858 6.66667 0.5 6.66667C0.776142 6.66667 1 6.89052 1 7.16667C1 9.89741 3.2137 12.1111 5.94444 12.1111C8.67519 12.1111 10.8889 9.89741 10.8889 7.16667C10.8889 6.89052 11.1127 6.66667 11.3889 6.66667Z" fill="#4B5671"></path></svg>

const MicTest = ({
    devices,
    selectedDevices,
    setSelectedDevices
}: IMicTestProps) => {

    const [isRecording, setIsRecording] = useState(false);

    useEffect(()=>{
        setIsRecording(true)
        
        const test: any = testAudioInputDevice({ deviceId: selectedDevices.microphone });
    
        test.on('volume', (volume: number) => {
            console.log('Microphone Volume Level:', volume);
            if(document.getElementById("volume-child")){
                document.getElementById("volume-child")!.style.width = Math.floor(volume) > 100 ? "100%" : volume + "%"
            }
        });

        return () => { setIsRecording(false); test.stop(); };
    }, [selectedDevices.microphone])

    return (
        <>
            <label>Microphone</label>
            <select
                onChange={(e) =>
                    setSelectedDevices({ ...selectedDevices, microphone: e.target.value })
                }
                value={selectedDevices.microphone}
            >
                <option defaultChecked hidden>Select Microphone</option>
                {devices
                    .filter((device: MediaDeviceInfo) => device.kind === 'audioinput')
                    .map((device: { deviceId: string; label?: string }) => (
                        <option key={device.deviceId} value={device.deviceId}>
                            {device.label || 'Unnamed Microphone'}
                        </option>
                    ))}
            </select>
            <div className="microphone-volume-progress-container">
                {MicSVG}
                <div className="volume-parent">
                    <div id="volume-child"></div>
                </div>
            </div>
        </>
    )
}

export default MicTest;