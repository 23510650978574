/* API Base and EndPoints */

import { method } from "lodash";

let baseUrl = process.env.REACT_APP_BASEURL;

/* App Version API's */
const appVersions = {
  get: {
    method: "GET",
    endPoint: "/versions",
  },
};

/* App Config API's */
const config = {
  get: {
    method: "GET",
    endPoint: "/config/web",
  },
};

/* Service APIs */
const shows = {
  create: {
    method: "POST",
    endPoint: "/shows",
  },
  edit: {
    method: "PATCH",
    endPoint: "/shows",
  },
  get: {
    method: "GET",
    endPoint: "/shows",
  },
  delete: {
    method: "DELETE",
    endPoint: "/shows",
  },
  listAllShows: {
    method: "GET",
    endPoint: "/shows/all",
  },
  inactiveShow: {
    method: "PATCH",
    endPoint: "/shows/inactiveShow",
  },
  fetchGuestInviteCount: {
    method: "GET",
    endPoint: "/shows/guest_user/guestInviteCount",
  },
  fetchGlobalAutoAllow: {
    method: "GET",
    endPoint: "/shows/guest_user/globalAutoAllow",
  },
  getShowsByUserId: {
    method: "GET",
    endPoint: "/shows/user",
  },
};

const users = {
  create: {
    method: "POST",
    endPoint: "/users",
  },
  createpubviewer: {
    method: "POST",
    endPoint: "/users/pub_viewer",
  },
  get: {
    method: "GET",
    endPoint: "/users/get",
  },
  edit: {
    method: "PATCH",
    endPoint: "/users/update",
  },
  editTwoFactorAuth: {
    method: "PATCH",
    endPoint: "/users/twoFactorAuth",
  },
  delete: {
    method: "DELETE",
    endPoint: "/users/delete",
  },
  deleteShowUser: {
    method: "DELETE",
    endPoint: "/users/deleteShowUser",
  },
  adminuseredit: {
    method: "PATCH",
    endPoint: "/users/adminedit",
  },
  inactivateUser: {
    method: "POST",
    endPoint: "/users/inactivateUser",
  },
  activateUserBySuperAdmin: {
    method: "POST",
    endPoint: "/users/activateUserBySuperAdmin",
  },
  activateUserByShowAdmin: {
    method: "POST",
    endPoint: "/users/activateUserByShowAdmin",
  },
  inactivateShowadmin: {
    method: "POST",
    endPoint: "/users/inactivateUser/showadmin",
  },
  inactivateTeamadmin: {
    method: "POST",
    endPoint: "/users/inactivateUser/teamadmin",
  },
  sendpasscode: {
    method: "POST",
    endPoint: "/users/sendpasscode",
  },
  resetUserpasswordByAdmin: {
    method: "POST",
    endPoint: "/users/resetUserPwdByAdmin",
  },
  resetpassword: {
    method: "POST",
    endPoint: "/users/resetpassword",
  },
  passwordRetries: {
    method: "POST",
    endPoint: "/users/passwordRetries",
  },
  passwordRetriesReset: {
    method: "POST",
    endPoint: "/users/passwordRetriesReset/",
  },
  forgotpassword: {
    method: "POST",
    endPoint: "/users/forgot_pwd",
  },
  forgotPasswordRetries: {
    method: "POST",
    endPoint: "/users/forgotPasswordRetries",
  },
  forgotPasswordRetriesReset: {
    method: "POST",
    endPoint: "/users/forgotPasswordRetriesReset",
  },
  userActiveShows: {
    method: "GET",
    endPoint: "/users/listshows/active",
  },
  listUsersInShow: {
    method: "GET",
    endPoint: "/users/shows",
  },
  listActiveUsersInShow: {
    method: "GET",
    endPoint: "/users/active/shows",
  },
  listActiveShowAdminInShow: {
    method: "GET",
    endPoint: "/users/active/shows/showadmin",
  },
  listActiveShowPublisherInShow: {
    method: "GET",
    endPoint: "/users/active/shows/showpublisher",
  },
  listActiveChildShowPublisherInShow: {
    method: "GET",
    endPoint: "/users/active/parentchild/shows/showpublisher",
  },
  listAllUsers: {
    method: "GET",
    endPoint: "/users/shows/all",
  },
  listShowUsersToInvite: {
    method: "GET",
    endPoint: "/users/shows/list/streaminvitation",
  },
  isSuperAdmin: {
    method: "GET",
    endPoint: "/users/role/isSuperAdmin",
  },
  isShowAdmin: {
    method: "GET",
    endPoint: "/users/role/isShowAdmin",
  },
  isShowPublisher: {
    method: "GET",
    endPoint: "/users/role/isShowPublisher",
  },
  isShowViewer: {
    method: "GET",
    endPoint: "/users/role/isShowViewer",
  },
  getAllUserRole: {
    method: "GET",
    endPoint: "/users/role/getAllRoles",
  },
  getUserForcePass: {
    method: "GET",
    endPoint: "/users/getForcePass",
  },
  isGuestViewer: {
    method: "GET",
    endPoint: "/users/role/isGuestViewer",
  },
  isShowFinance: {
    method: "GET",
    endPoint: "/users/role/isShowFinance",
  },
  requestLicense: {
    method: "POST",
    endPoint: "/users/requestLicenseByShowAdmin",
  },
  approvedLicense: {
    method: "POST",
    endPoint: "/users/approvedLicenseBySuperAdmin",
  },
  removeLicense: {
    method: "POST",
    endPoint: "/users/removeLicenseByShowAdmin",
  },
  reassignLicense: {
    method: "POST",
    endPoint: "/users/reassignLicenseByShowAdmin",
  },
  assignLicense: {
    method: "POST",
    endPoint: "/users/assignLicenseByShowAdmin",
  },
  lock: {
    method: "POST",
    endPoint: "/users/lock",
  },
  fetchUserDetails:{
    method:"GET",
    endPoint:"/users/fetchUserDetails"
  },
};

/** License manager history */
const licenseHistory = {
  getHistory: {
    method: "GET",
    endPoint: "/users/licenseHistory/get",
  },
  requestLicense: {
    method: "POST",
    endPoint: "/users/licenseHistory/requestLicense",
  },
  removeLicense: {
    method: "POST",
    endPoint: "/users/licenseHistory/removeLicense",
  },
  addPublisherLicense: {
    method: "POST",
    endPoint: "/users/licenseHistory/addPublisherLicense",
  },
  reAssignPublisherLicense: {
    method: "POST",
    endPoint: "/users/licenseHistory/reAssignPublisherLicense",
  },
  removePublisherLicense: {
    method: "POST",
    endPoint: "/users/licenseHistory/removePublisherLicense",
  },
};

const streams = {
  create: {
    method: "POST",
    endPoint: "/streams",
  },
  update: {
    method: "PATCH",
    endPoint: "/streams",
  },
  listAll: {
    method: "GET",
    endPoint: "/streams",
  },
  listAllActive: {
    method: "GET",
    endPoint: "/streams/active",
  },
  listAllActiveChildStream: {
    method: "GET",
    endPoint: "/streams/child/active",
  },
  listViewerStreams: {
    method: "GET",
    endPoint: "/streams/viewer",
  },
  listPublisherStreams: {
    method: "GET",
    endPoint: "/streams/publisher",
  },
  pubActiveStream: {
    method: "GET",
    endPoint: "/streams/publisher/active",
  },
  pubActiveStreamCount: {
    method: "GET",
    endPoint: "/streams/publisher_stream_count/active",
  },
  inviteViewersToStream: {
    method: "POST",
    endPoint: "/streams/inviteusers",
  },
  inviteGroupsToStream: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/streams/invitegroups",
  },
  listInvitedViewersOfStream: {
    method: "GET",
    endPoint: "/streams/list/activeusers",
  },
  listAllActiveViewersOfStream: {
    method: "GET",
    endPoint: "/streams/list/allActiveViewers",
  },
  unInviteViewersToStream: {
    method: "POST",
    endPoint: "/streams/uninviteusers",
  },
  getStreamUser: {
    method: "GET",
    endPoint: "/streams/get/user",
  },
  getSubscriberToken: {
    method: "GET",
    endPoint: "/streams/subscriber_token",
  },
  subscribeStream: {
    method: "POST",
    endPoint: "/streams/subscribe_stream",
  },
  timeCode: {
    method: "POST",
    endPoint: "/streams/timecode",
  },
  getGuestUserStream: {
    method: "GET",
    endPoint: "/streams/guest_users/get_stream_by_token",
  },
  sendTokenToPublisher: {
    method: "POST",
    endPoint: "/streams/sendtoken_to_pub",
  },
  addAutoAllowGuests: {
    method: "POST",
    endPoint: "/streams/addAutoAllowGuests",
  },
  getGuestLink: {
    method: "GET",
    endPoint: "/streams/guest_users/get_Guest_Link",
  },
  deleteGuestLink: {
    method: "POST",
    endPoint: "/streams/guest_users/delete_Guest_Link",
  },
  getAutoAllowStatus: {
    method: "GET",
    endPoint: "/streams/guest_users/get_Auto_Allow_Status",
  },
  getStreamsByUserId: {
    method: "GET",
    endPoint: "/streams/user",
  }
  
};

const otp = {
  requestOtp: {
    method: "GET",
    endPoint: "/otp/requestOtp",
  },
  validateOtp: {
    method: "POST",
    endPoint: "/otp/validateOtp",
  },
  validateGoogleAuthOtp: {
    method: "POST",
    endPoint: "/otp/googleAuthValidate",
  },
  validateAuthy: {
    method: "GET",
    endPoint: "/otp/authyValidate",
  },
  validateAuthyOtp: {
    method: "POST",
    endPoint: "/otp/authyValidateOtp",
  },
  disableOTPAuthStatus: {
    method: "POST",
    endPoint: "/otp/disableOTPAuthStatus",
  },
};

const log = {
  create: {
    method: "POST",
    endPoint: "/log",
  },
  log: {
    method: "POST",
    endPoint: "/log/frontend_log",
  },
};

const vc = {
  getVCRoom: {
    method: "GET",
    endPoint: "/vc/stream/vc_room",
  },
  activeStreamVCRoom: {
    method: "GET",
    endPoint: "/vc/stream/active/room",
  },
  getTwilioVGT: {
    method: "GET",
    endPoint: "/vc/twilio/video/token",
  },
  getTwilioRoom: {
    method: "GET",
    endPoint: "/vc/twilio/room",
  },
  completeTwilioRoom: {
    method: "POST",
    endPoint: "/vc/twilio/room/complete",
  },
  getInprogressVCRoomsByShow: {
    method: "GET",
    endPoint: "/vc/stream/inprogress/rooms/show",
  },
  twilioRoomParticipantsList: {
    method: "GET",
    endPoint: "/vc/twilio/room/participants",
  },
  updateStreamVCRoom: {
    method: "PATCH",
    endPoint: "/vc/stream/room",
  },
  removeTwilioLinkFrmStreamVCRoom: {
    method: "PATCH",
    endPoint: "/vc/stream/room/removetwiliolink",
  },
  getVCRoomParticipant: {
    method: "GET",
    endPoint: "/vc/stream/room/participant",
  },
  getWatermark: {
    method: "GET",
    endPoint: "/vc/stream/room/watermark",
  },
  updateWatermark: {
    method: "PATCH",
    endPoint: "/vc/stream/room/watermark",
  },
  getGuestSession: {
    method: "GET",
    endPoint: "/vc/stream/room/guest/session",
  },
  updateGuestSession: {
    method: "PATCH",
    endPoint: "/vc/stream/room/guest/session",
  },
  leaveOrEndConference:{
    method: "POST",
    endPoint: "/vc/stream/room/leaveorendconference",
  },
  getVCRoomsByShowId:{
    method: "GET",
    endPoint: "/vc/stream/room/show",
  },
  generateVideoSDKJWT: {
    method: "POST",
    endPoint: "/vc/stream/room/generatezoomsdk",
  }
};

const countryCodes = {
  listAll: {
    method: "GET",
    endPoint: "/country_codes",
  },
};

const configs = {
  listAllConfigs: {
    method: "GET",
    endPoint: "/configs/app",
  },
  listConfigsByShowType: {
    method: "GET",
    endPoint: "/configs/app/showtype",
  },
  listAllShowConfigs: {
    method: "GET",
    endPoint: "/configs/show/all",
  },
  listShowConfigsByShowId: {
    method: "GET",
    endPoint: "/configs/show",
  },
  addShowConfigs: {
    method: "POST",
    endPoint: "/configs/show",
  },
  updateShowConfigs: {
    method: "PATCH",
    endPoint: "/configs/show",
  },
  listConfigsByStreamType: {
    method: "GET",
    endPoint: "/configs/app/streamtype",
  },
  listStreamConfigsByStreamId: {
    method: "GET",
    endPoint: "/configs/stream/get",
  },
  updateStreamConfigs: {
    method: "PATCH",
    endPoint: "/configs/stream",
  },
};

const featureFlags = {
  listAllFlags: {
    method: "GET",
    endPoint: "/feature_flags",
  },
  listAppFlags: {
    method: "GET",
    endPoint: "/feature_flags/app",
  },
  listShowFlagsName: {
    method: "GET",
    endPoint: "/feature_flags/show/flagname",
  },
  listShowFlagsNameByValue: {
    method: "POST",
    endPoint: "/feature_flags/show/flagname/value",
  },
  listFeatureFlags: {
    method: "GET",
    endPoint: "/feature_flags/featureflags/getAppFlags",
  }
};

const notification = {
  sendSignUpDetails: {
    method: "POST",
    endPoint: "/notification/signUpEmail",
  },
  streamLimitReachedEmail: {
    method: "POST",
    endPoint: "/notification/streamLimitReached",
  },
  sendSMS: {
    method: "POST",
    endPoint: "/notification/sms",
  },
  tamperEmail: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/notification/tamperEmail",
  },
};

const guestUsers = {
  validateGuestToken: {
    method: "GET",
    endPoint: "/guest/validate_token",
  },
  validateGuestAccess: {
    method: "GET",
    endPoint: "/guest/validate_access",
  },
  login: {
    method: "POST",
    endPoint: "/guest/login",
  },
  loglockevent:{
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/guest/log_lockevent",
  },
  getGuestUsersList: {
    method: "GET",
    endPoint: "/streams/guestusers/get_all",
  },
  inviteGuestUser: {
    method: "POST",
    endPoint: "/users/guest_users/invite",
  },
  unInviteGuestUser: {
    method: "DELETE",
    endPoint: "/users/guest_users/uninvite",
  },
  deleteGuestUsersOfPublisher: {
    method: "DELETE",
    endPoint: "/users/guest_users/delete_guests_of_pub",
  },
  createGuestData: {
    method: "POST",
    endPoint: "/users/guest_users/createLink",
  },
};

/* Stripe */
const stripe = {
  createCustomer: {
    method: "POST",
    endPoint: "/stripe/createCustomer",
  },
  updateCustomer: {
    method: "POST",
    endPoint: "/stripe/updateCustomer",
  },
  createSubscription: {
    method: "POST",
    endPoint: "/stripe/createSubscription",
  },
  cancelSubscription: {
    method: "POST",
    endPoint: "/stripe/cancelSubscription",
  },
  retrieveSubscription: {
    method: "POST",
    endPoint: "/stripe/retrieveSubscription",
  },
  listUserSubscriptions: {
    method: "GET",
    endPoint: "/stripe/listUserSubscriptions",
  },
  getCustomerCard: {
    method: "GET",
    endPoint: "/stripe/customerCard",
  },
  updateSubscriptionItemsQuanity: {
    method: "POST",
    endPoint: "/stripe/updateSubscription/items_quanity",
  },
  getProduct: {
    method: "GET",
    endPoint: "/stripe/products",
  },
  getPrice: {
    method: "GET",
    endPoint: "/stripe/price",
  },
  getInvoice: {
    method: "GET",
    endPoint: "/stripe/invoice",
  },
  getCustomer: {
    method: "GET",
    endPoint: "/stripe/customer",
  },
  createCheckoutSession: {
    method: "POST",
    endPoint: "/stripe/createCheckoutSession",
  },
};

const group = {
  createShowGroup: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/showgroups",
  },
  listShowGroup: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/showgroups/list",
  },
  showGroup: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/showgroups",
  },
  deleteShowGroup: {
    method: "DELETE",
    baseEndPoint: "/app",
    endPoint: "/showgroups",
  },
  updateShowGroup: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/showgroups",
  },
  createShowGroupUser: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/showgroupusers",
  },
  bulkAddShowGroupUser: {
    method: "POST",
    baseEndPoint:"/app",
    endPoint:"/showgroupusers/bulk"
  },
  listShowGroupUser: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/showgroupusers/list",
  },
  showGroupUser: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/showgroupusers",
  },
  deleteShowGroupUser: {
    method: "DELETE",
    baseEndPoint: "/app",
    endPoint: "/showgroupusers",
  },
  updateShowGroupUser: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/showgroupusers",
  },
  listGroupsByShowIdUserId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/showgroupusers/groups",
  },
  listUsersByShowId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/showgroupusers/list_show",
  }
}
const trustedDevice = {
  addNewDevice: {
    method: "POST",
    endPoint: "/device/activate",
  },
  deviceStatus: {
    method: "GET",
    endPoint: "/device/status",
  },
  deactivateDevices: {
    method: "DELETE",
    endPoint: "/device/deactivate",
  },
  deviceList: {
    method: "GET",
    endPoint: "/device/all",
  },
  renewDevice: {
    method: "PATCH",
    endPoint: "/device/renew",
  },
  deactivateAllDevices: {
    method: "DELETE",
    endPoint: "/device/deactivateAll",
  },
}
const encoder = {
  device: {
    method: "GET",
    endPoint: "/encoder/all",
  },
  showDevice: {
    method: "GET",
    endPoint: "/encoder/show_device",
  },
  streamDevice: {
    method: "GET",
    endPoint: "/encoder/stream_device",
  },
  provision: {
    method: "POST",
    endPoint: "/encoder/provision",
  },
  unProvision: {
    method: "POST",
    endPoint: "/encoder/unProvision",
  },
  config: {
    method: "POST",
    endPoint: "/encoder/config",
  },
  start: {
    method: "POST",
    endPoint: "/encoder/start",
  },
  stop: {
    method: "POST",
    endPoint: "/encoder/pause_end",
  },
  selectDevice: {
    method: "POST",
    endPoint: "/encoder/select_device",
  },
  reboot: {
    method: "POST",
    endPoint: "/encoder/reboot",
  },
  status: {
    method: "POST",
    endPoint: "/encoder/status",
  },
  add:{
    method:"POST",
    endPoint:"/encoder/add"
  },
  delete: {
    method: "DELETE",
    endPoint: "/encoder",
  },
  getDeviceSettings: {
    method: "GET",
    endPoint: "/encoder/get_device_settings",
  },
}

const massive = {
  upload:{
    method:"POST",
    endPoint:"/masv/upload"
  },
  finalize:{
    method:"POST",
    endPoint:"/masv/finalize"
  },
  transfer: {
    method: "GET",
    endPoint: "/masv/transfer",
  },
  failedTransactions: {
    method: "GET",
    endPoint: "/masv/failedTransactions",
  },
  failedDownloads: {
    method: "GET",
    endPoint: "/masv/failedDownloads",
  },
  recipients: {
    method: "GET",
    endPoint: "/masv/recipients",
  },
  massiveRecipients: {
    method: "GET",
    endPoint: "/masv/massive_recipients",
  },
  download: {
    method: "POST",
    endPoint: "/masv/download",
  },
  createapiKeys: {
    method: "POST",
    endPoint: "/masv/createapikeys",
  },
  listapikeys: {
    method: "GET",
    endPoint: "/masv/listapikeys",
  },
  generatePassword: {
    method: "GET",
    endPoint: "/masv/generate_password",
  },
  getPassword: {
    method: "POST",
    endPoint: "/masv/get_masv_password",
  },
  deleteApi: {
    method: "DELETE",
    endPoint: "/masv/deleteApi",
  },
  deleteUploadDoc: {
    method: "DELETE",
    endPoint: "/masv/deleteUploadDoc",
  },
  superAdminNotificationEmail: {
    method: "POST",
    endPoint: "/masv/superAdminNotificationEmail",
  },
  mfaLogin: {
    method: "POST",
    endPoint: "/masv/mfaLogin",
  },
}

const tvOs = {
  verifyAccessCode: {
    method: 'POST',
    endPoint: '/tvos/web/verifyAccessCode',
  },
  listOfAppleTV: {
    method: 'GET',
    endPoint: '/tvos/listOfAppleTV',
  },
  deleteDeviceID: {
    method: 'DELETE',
    endPoint: '/tvos/deleteDeviceID',
  },
  getTVOSParticipants: {
    method: 'GET',
    endPoint: '/tvos/fetchTVOSParticipants'
  }
};

const meetings = {
  create: {
    method: "POST",
    endPoint: "/meetings",
  },
  getById: {
    method: "GET",
    endPoint: "/meetings",
  },
  updateMeeting: {
    method: "POST",
    endPoint: "/meetings/updateMeeting",
  },
  changeParticipantRole: {
    method: "PATCH",
    endPoint: "/meetings/participant-role",
  },
  changeParticipantStatus: {
    method: "PATCH",
    endPoint: "/meetings/participant-status",
  },
  deleteMeeting: {
    method: "DELETE",
    endPoint: "/meetings/deleteMeeting",
  },
  getUserFutureMeetings: {
    method: "GET",
    endPoint: "/meetings/user/future",
  },
  getAllMeetings: {
    method: "GET",
    endPoint: "/meetings/all",
  },
  getAllUserMeetings: {
    method: "GET",
    endPoint: "/meetings/user/all",
  },
  login: {
    method: "POST",
    endPoint: "/meetings/login",
  },
  allowGuest:{
    method:"POST",
    endPoint: "/meetings/guest/allow"
  },
  removeGuest:{
    method:"DELETE",
    endPoint: "/meetings/guest/remove"
  },
  getMeetingGuests:{
    method: "GET",
    endPoint: "/meetings/guest/all",
  },
  getHostMeetingConfiguration:{
    method: "GET",
    endPoint: "/meetings/host/getHostMeetingConfiguration",
  },
  validateMeetingAccess: {
    method: "POST",
    endPoint: "/meetings/validateMeetingAccess",
  },
  waitingStatus: {
    method: "POST",
    endPoint: "/meetings/status/waiting",
  },
  updateDocumentTimestamp: {
    method: "POST",
    endPoint: "/meetings/status/updateDocumentTimestamp",
  },
  leaveMeeting: {
    method: "POST",
    endPoint: "/meetings/leaveMeeting",
  },
};

const api = {
  baseUrl,
  config,
  appVersions,
  users,
  shows,
  streams,
  otp,
  log,
  vc,
  countryCodes,
  configs,
  featureFlags,
  notification,
  guestUsers,
  stripe,
  licenseHistory,
  group,
  trustedDevice,
  encoder,
  massive,
  tvOs,
  meetings
};

export default api;
