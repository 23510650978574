import React from 'react';
import Chat from './Chat';
import './style.css';
import Participants from './Participants';

interface SidebarProps {
  showChat: string | null;
  showParticipants: string | null;
}

const Sidebar: React.FC<SidebarProps> = ({ showChat, showParticipants }) => {
  return (
    <div className='sidebar'>
      <span className='sidebar-title'>{showChat === 'chat' && <Chat />}</span>
      <span className='sidebar-title'>
        {showParticipants === 'participants' && <Participants />}
        {/* {activeComponent === '' && <></>} */}
      </span>
    </div>
  );
};

export default Sidebar;
