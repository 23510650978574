import React, { useContext, useEffect } from 'react';
import './style.css';
import zoomContext from '../../context/zoom-context';
import { useActiveVideo } from '../../Hooks/useActiveVideo';
import useParticipants from '../../Hooks/useParticipants';
import { Participant } from '@zoom/videosdk';
import { clearPreviousVideo, get, styleVideoTile, styleZoomAvatar } from '../../Helpers/utils';
import UserOptions from './UserOptions';

let currentSpeaker = 0;

const WebCam: React.FC = () => {
  const zmClient = useContext(zoomContext);

  const activeSpeaker = useActiveVideo(zmClient);
  const participants = useParticipants();
  const pinnedUserId = sessionStorage.getItem('pinned-user') || '';
  

  const getActiveSpeakerInfo = (activeSpeaker: number) => {
    const activeSpeakerId = Number(pinnedUserId) || activeSpeaker;
    const participant = participants.find((p: Participant) => p.userId === activeSpeakerId);
    return {
      identity: get(participant, 'userIdentity', zmClient.getCurrentUserInfo()?.userIdentity ?? ''),
      name: get(participant, 'displayName', zmClient.getCurrentUserInfo()?.displayName ?? ''),
      bVideoOn: get(participant, 'bVideoOn', zmClient.getCurrentUserInfo()?.bVideoOn ?? ''),
    };
  };

  const handleActiveSpeakerTile = (
    activeSpeakerTile: HTMLElement,
    activeSpeakerContainer: HTMLElement,
    name: string,
  ) => {
    clearPreviousVideo(activeSpeakerContainer);

    const activeSpeakerTileCopy = activeSpeakerTile.cloneNode(true) as HTMLElement;
    const videoTile = activeSpeakerTileCopy.querySelector('.video-tile');
    const videoControls = videoTile?.querySelector('.video-controls');

    if (videoControls) {
      videoTile?.removeChild(videoControls);
    }

    if (videoTile instanceof HTMLElement) {
      styleVideoTile(videoTile);
      const zoomAvatar = videoTile.querySelector('.zoom-avatar');
      if (zoomAvatar instanceof HTMLElement) {
        styleZoomAvatar(zoomAvatar);
      }
    }

    const showSpeakerViewer = get(get(activeSpeakerTileCopy, 'children.0', ''), 'children.0', '');
    if (showSpeakerViewer instanceof HTMLElement) {
      showSpeakerViewer.id = 'show-speaker-viewer';
      styleVideoTile(showSpeakerViewer);
    }

    return activeSpeakerTileCopy;
  };
  const confirmVideoChange = (activeSpeakerTileCopy: HTMLElement, userId: number): boolean => {
    const videoTile = activeSpeakerTileCopy.querySelector('.video-tile') as HTMLElement;
    const playerContent = videoTile.querySelector('.player-container') as HTMLElement;
    const { bVideoOn } = getActiveSpeakerInfo(userId);
    if (!bVideoOn) {
      return true;
    }
    return Boolean(playerContent?.children.length) && bVideoOn;
  };

  const updateSpeakerView = (activeSpeakerIdentity: string, name: string) => {
    const activeSpeakerContainer = document.getElementById('show-speaker-container-wrapper');
    const activeUserDisplayName = document.getElementById('active-user-display-name');

    const activeSpeakerExists = participants.some(
      (participant: Participant) => !participant.muted || !participant.audio,
    );

    const pinnedUserAbsentFromVC =
      !!pinnedUserId &&
      !participants.find((participant: any) => participant.userId === Number(pinnedUserId));

    if (!activeSpeakerExists || pinnedUserAbsentFromVC) {
      clearPreviousVideo(activeSpeakerContainer);
    }

    const showSpeakerViewer = document.getElementById('show-speaker-viewer');

    if (
      currentSpeaker !== activeSpeaker &&
      (!Boolean(pinnedUserId) || (Boolean(pinnedUserId) && !showSpeakerViewer))
    ) {
      handleNewActiveSpeaker(
        activeSpeakerIdentity,
        name,
        activeSpeakerContainer,
        activeUserDisplayName,
      );
    }
  };

  const handleNewActiveSpeaker = (
    activeSpeakerIdentity: string,
    name: string,
    activeSpeakerContainer: HTMLElement | null,
    activeUserDisplayName: HTMLElement | null,
  ) => {
    let checkVideoStatus = false;
    let activeSpeakerTile = document.getElementById(
      `video-player-container-div${activeSpeakerIdentity}`,
    );

    if (activeSpeakerTile) {
      let activeSpeakerTileCopy = handleActiveSpeakerTile(
        activeSpeakerTile,
        activeSpeakerContainer as HTMLElement,
        name,
      );
      checkVideoStatus = confirmVideoChange(activeSpeakerTile, activeSpeaker);

      if (!checkVideoStatus) {
        const intervalId = setInterval(() => {
          if (activeSpeakerTile) {
            activeSpeakerTileCopy = handleActiveSpeakerTile(
              activeSpeakerTile,
              activeSpeakerContainer as HTMLElement,
              name,
            );
            checkVideoStatus = confirmVideoChange(activeSpeakerTileCopy, activeSpeaker);
            activeSpeakerContainer?.appendChild(activeSpeakerTileCopy);
          }
          if (checkVideoStatus) {
            clearInterval(intervalId); // Stop the interval if checkVideoStatus is true
          }
        }, 1000); // Run every second
      }
      activeSpeakerContainer?.appendChild(activeSpeakerTileCopy);
      currentSpeaker = activeSpeaker;

      if (activeUserDisplayName) {
        activeUserDisplayName.textContent = name;
      }
    }
  };

  useEffect(() => {
    if (
      pinnedUserId &&
      !participants.find((participant: any) => participant.userId === Number(pinnedUserId))
    ) {
      sessionStorage.removeItem('pinned-user');
    }
    const { identity, name } = getActiveSpeakerInfo(activeSpeaker);
    updateSpeakerView(identity, name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSpeaker, participants, zmClient]);

  return (
    <div className='zoom-meeting-webcam-container'>
      <div className='zoom-meeting-webcam-inner'>
        <div className='zoom-meeting-video-wrapper'>
          <div className='zoom-meeting-video-container'>
            <div style={{ width: '100%', height: '100%' }}>
              <video-player-container>
                <div
                  id='show-speaker-container-wrapper'
                  style={{ width: '100%', height: '100%' }}
                ></div>
              </video-player-container>
            </div>
            <UserOptions activeSpeaker={activeSpeaker} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebCam;
